import React, { useState } from 'react';

const AddGameScheduleModal = ({ onClose, onAdd, leagues }) => {
  const [gameData, setGameData] = useState({
    date: new Date().toISOString().split('T')[0],
    time: '18:00',
    opponent: '',
    location: '',
    league: leagues[0],
    notes: '',
    isHome: true
  });
  
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setGameData({
      ...gameData,
      [name]: type === 'checkbox' ? checked : value
    });
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    onAdd(gameData);
  };
  
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-10">
      <div className="bg-white rounded-lg p-4 w-11/12 max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-bold">Add Game to Schedule</h3>
          <button onClick={onClose} className="text-gray-500">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Date</label>
            <input 
              type="date" 
              className="w-full p-2 border border-gray-300 rounded-md"
              name="date"
              value={gameData.date}
              onChange={handleChange}
              required
            />
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Time</label>
            <input 
              type="time" 
              className="w-full p-2 border border-gray-300 rounded-md"
              name="time"
              value={gameData.time}
              onChange={handleChange}
              required
            />
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Opponent</label>
            <input 
              type="text" 
              className="w-full p-2 border border-gray-300 rounded-md"
              placeholder="Team name"
              name="opponent"
              value={gameData.opponent}
              onChange={handleChange}
              required
            />
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Location</label>
            <input 
              type="text" 
              className="w-full p-2 border border-gray-300 rounded-md"
              placeholder="Field name or address"
              name="location"
              value={gameData.location}
              onChange={handleChange}
              required
            />
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">League</label>
            <select 
              className="w-full p-2 border border-gray-300 rounded-md"
              name="league"
              value={gameData.league}
              onChange={handleChange}
            >
              {leagues.map((league, index) => (
                <option key={index} value={league}>{league}</option>
              ))}
            </select>
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Game Type</label>
            <div className="flex space-x-4">
              <label className="inline-flex items-center">
                <input 
                  type="radio" 
                  name="isHome" 
                  checked={gameData.isHome}
                  onChange={() => setGameData({...gameData, isHome: true})}
                  className="form-radio" 
                />
                <span className="ml-2">Home</span>
              </label>
              <label className="inline-flex items-center">
                <input 
                  type="radio" 
                  name="isHome" 
                  checked={!gameData.isHome}
                  onChange={() => setGameData({...gameData, isHome: false})}
                  className="form-radio" 
                />
                <span className="ml-2">Away</span>
              </label>
            </div>
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Notes</label>
            <textarea 
              className="w-full p-2 border border-gray-300 rounded-md h-20"
              placeholder="Additional information about the game"
              name="notes"
              value={gameData.notes}
              onChange={handleChange}
            ></textarea>
          </div>
          
          <div className="flex justify-end pt-2">
            <button 
              type="button"
              onClick={onClose}
              className="bg-gray-200 text-gray-700 mr-2 py-2 px-4 rounded"
            >
              Cancel
            </button>
            <button 
              type="submit" 
              className="bg-blue-600 text-white py-2 px-4 rounded"
            >
              Add Game
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddGameScheduleModal;