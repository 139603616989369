import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { RefreshCw, AlertCircle, RotateCcw } from 'lucide-react';
import Navigation from './components/shared/Navigation';
import Dashboard from './components/dashboard/Dashboard';
import StatsPage from './components/stats/StatsPage';
import AddGameForm from './components/add-game/AddGameForm';
import ProfilePage from './components/profile/ProfilePage';
import SchedulePage from './components/schedule/SchedulePage';
import { PlayerProvider } from './context/PlayerContext';
import { ThemeProvider } from './context/ThemeContext';
import { useAuth } from './context/AuthContext';
import AuthPage from './components/auth/AuthPage';
import PlayerSetup from './components/onboarding/PlayerSetup';
import { getPlayers } from './firebase/firestore';

// Global error boundary component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Application error:", error, errorInfo);
    this.setState({ errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="h-screen flex flex-col items-center justify-center p-6 bg-red-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full text-center">
            <AlertCircle size={48} className="mx-auto mb-4 text-red-500" />
            <h1 className="text-xl font-bold mb-2">Something went wrong</h1>
            <p className="text-gray-600 mb-4">
              We've encountered an unexpected error. Please try refreshing the page.
            </p>
            <pre className="bg-gray-100 p-4 rounded text-xs text-left overflow-auto max-h-32 mb-4">
              {this.state.error?.toString()}
            </pre>
            <button
              onClick={() => window.location.reload()}
              className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 w-full"
            >
              Refresh the page
            </button>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

// Safe area CSS setup
if (typeof document !== 'undefined') {
  // Fix mobile viewport height issues (iOS Safari, etc.)
  const setVh = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };
  
  // Set on load
  setVh();
  
  // Update on resize
  window.addEventListener('resize', setVh);
}

function MainApp({ 
  activeTab, 
  setActiveTab, 
  isLoading, 
  setIsLoading, 
  isRefreshing, 
  fadeIn, 
  resetAppState, 
  children 
}) {
  return (
      <div className="min-h-screen flex flex-col bg-gray-50 dark:bg-dark-100 dark:text-gray-100" 
           style={{ minHeight: 'calc(var(--vh, 1vh) * 100)' }}>
        {/* Pull-to-refresh indicator */}
        {isRefreshing && (
          <div className="fixed top-0 left-0 right-0 z-50 flex justify-center p-2 bg-blue-500 text-white text-sm">
            <RefreshCw size={14} className="animate-spin mr-2" /> Refreshing...
          </div>
        )}
        
        <main 
          className={`flex-grow overflow-y-auto pb-16 pt-safe-top px-safe-right pb-safe-bottom px-safe-left 
                     ${fadeIn ? 'opacity-100' : 'opacity-0'} transition-opacity duration-150`}
        >
          {children}
        </main>
        
        <Navigation 
          activeTab={activeTab} 
          setActiveTab={setActiveTab} 
        />
      </div>
  );
}

function App() {
  const [activeTab, setActiveTab] = useState('dashboard'); // Initial tab is dashboard
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [fadeIn, setFadeIn] = useState(true);
  const [hasPlayers, setHasPlayers] = useState(null);
  const [initialCheckDone, setInitialCheckDone] = useState(false);

  // Get current user from auth context
  const { currentUser, logout } = useAuth();

  // Pull-to-refresh functionality
  const handlePullToRefresh = useCallback(() => {
    if (isRefreshing) return;
    
    setIsRefreshing(true);
    // Simulate a refresh - in a real app, this would call API endpoints or refresh data
    setTimeout(() => {
      setIsRefreshing(false);
    }, 1200);
  }, [isRefreshing]);
  
  // Setup pull-to-refresh
  useEffect(() => {
    let touchStart = 0;
    let touchEnd = 0;
    const threshold = 150; // Minimum distance to trigger refresh
    const maxScroll = 5; // Maximum scroll position to allow pull

    const handleTouchStart = (e) => {
      touchStart = e.targetTouches[0].clientY;
    };

    const handleTouchMove = (e) => {
      touchEnd = e.targetTouches[0].clientY;
    };

    const handleTouchEnd = () => {
      if (window.scrollY <= maxScroll && touchStart && (touchEnd - touchStart > threshold)) {
        handlePullToRefresh();
      }
      touchStart = 0;
      touchEnd = 0;
    };
    
    document.addEventListener('touchstart', handleTouchStart, false);
    document.addEventListener('touchmove', handleTouchMove, false);
    document.addEventListener('touchend', handleTouchEnd, false);
    
    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [handlePullToRefresh]);
  
  // Effect to check if user has any player profiles
  useEffect(() => {
    const checkForPlayers = async () => {
      if (currentUser) {
        try {
          setIsLoading(true);
          console.log('Checking for player profiles for user:', currentUser.uid);
          
          // Check if we have a newly created player flag
          const newPlayerCreated = localStorage.getItem('newPlayerCreated');
          if (newPlayerCreated === 'true') {
            console.log('New player was created, forcing fresh check');
            localStorage.removeItem('newPlayerCreated');
          }
          
          const players = await getPlayers(currentUser.uid);
          console.log('Player profiles found:', players.length, players);
          
          if (players.length > 0) {
            console.log('Player(s) found, setting hasPlayers to true');
            setHasPlayers(true);
          } else {
            console.log('No players found, setting hasPlayers to false');
            setHasPlayers(false);
          }
        } catch (error) {
          console.error('Error checking for players:', error);
          setHasPlayers(false);
        } finally {
          setIsLoading(false);
          setInitialCheckDone(true);
        }
      } else {
        console.log('No authenticated user, skipping player check');
        setInitialCheckDone(true);
      }
    };
    
    checkForPlayers();
    
    // Add location change listener to recheck for players when navigating
    const handleLocationChange = () => {
      console.log('Location changed, rechecking for players');
      checkForPlayers();
    };
    
    // Also check when window gains focus (user might have created a player in another tab)
    const handleFocus = () => {
      console.log('Window gained focus, rechecking for players');
      checkForPlayers();
    };
    
    window.addEventListener('popstate', handleLocationChange);
    window.addEventListener('focus', handleFocus);
    return () => {
      window.removeEventListener('popstate', handleLocationChange);
      window.removeEventListener('focus', handleFocus);
    };
  }, [currentUser]);
  
  // Debug utility function to reset the app state
  const resetAppState = async () => {
    console.log('Resetting app state...');
    
    try {
      // Clear local storage
      localStorage.clear();
      
      // Player context is handled by PlayerProvider now
      
      // Sign out the user
      if (logout) {
        await logout();
      }
      
      // Force page reload for a clean state
      window.location.href = '/auth';
    } catch (error) {
      console.error('Error resetting app state:', error);
    }
  };
  
  // Handle tab changes with animation
  const handleTabChange = (tab) => {
    if (tab === activeTab) return;
    
    console.log('Tab changed to:', tab); // Debug log to verify correct tab ID
    
    // Transition out
    setFadeIn(false);
    
    // Wait for animation to complete, then change tab
    setTimeout(() => {
      setActiveTab(tab);
      // Transition in
      setFadeIn(true);
    }, 150);
  };
  // If initial check is not done, show a loading spinner
  if (!initialCheckDone) {
    return (
      <ErrorBoundary>
        <div className="h-screen flex items-center justify-center bg-gray-50 dark:bg-dark-100 dark:text-gray-100">
          <div className="text-center">
            <RefreshCw size={32} className="mx-auto animate-spin text-blue-600 mb-2" />
            <p className="text-gray-600">Loading...</p>
          </div>
        </div>
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary>
      <ThemeProvider>
        <PlayerProvider>
          <Router>
            <Routes>
              {/* Auth route - accessible only when not logged in */}
              <Route
                path="/auth"
                element={!currentUser ? <AuthPage /> : <Navigate to="/" replace />}
              />
          
              {/* Onboarding route - accessible only when logged in but no player profile */}
              <Route
                path="/setup"
                element={
                  !currentUser ? (
                    <Navigate to="/auth" replace />
                  ) : hasPlayers === false ? (
                    <PlayerSetup />
                  ) : (
                    <Navigate to="/" replace />
                  )
                }
              />

              {/* Protected routes that require authentication and player profile */}
              {/* Wrap each route in the MainApp layout when user is authenticated and has players */}
              <Route
                path="/"
                element={
                  !currentUser ? (
                    <Navigate to="/auth" replace />
                  ) : hasPlayers === false ? (
                    <Navigate to="/setup" replace />
                  ) : (
                    <MainApp
                      activeTab="dashboard"
                      setActiveTab={handleTabChange}
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                      isRefreshing={isRefreshing}
                      fadeIn={fadeIn}
                      resetAppState={resetAppState}
                    >
                      <Dashboard isLoading={isLoading} setIsLoading={setIsLoading} isRefreshing={isRefreshing} />
                    </MainApp>
                  )
                }
              />
          
              <Route
                path="/stats"
                element={
                  !currentUser ? (
                    <Navigate to="/auth" replace />
                  ) : hasPlayers === false ? (
                    <Navigate to="/setup" replace />
                  ) : (
                    <MainApp
                      activeTab="stats"
                      setActiveTab={handleTabChange}
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                      isRefreshing={isRefreshing}
                      fadeIn={fadeIn}
                      resetAppState={resetAppState}
                    >
                      <StatsPage isLoading={isLoading} setIsLoading={setIsLoading} isRefreshing={isRefreshing} />
                    </MainApp>
                  )
                }
              />
          
              <Route
                path="/schedule"
                element={
                  !currentUser ? (
                    <Navigate to="/auth" replace />
                  ) : hasPlayers === false ? (
                    <Navigate to="/setup" replace />
                  ) : (
                    <MainApp
                      activeTab="schedule"
                      setActiveTab={handleTabChange}
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                      isRefreshing={isRefreshing}
                      fadeIn={fadeIn}
                      resetAppState={resetAppState}
                    >
                      <SchedulePage isLoading={isLoading} setIsLoading={setIsLoading} isRefreshing={isRefreshing} />
                    </MainApp>
                  )
                }
              />
          
              <Route
                path="/add-game"
                element={
                  !currentUser ? (
                    <Navigate to="/auth" replace />
                  ) : hasPlayers === false ? (
                    <Navigate to="/setup" replace />
                  ) : (
                    <MainApp
                      activeTab="add-game" 
                      setActiveTab={handleTabChange}
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                      isRefreshing={isRefreshing}
                      fadeIn={fadeIn}
                      resetAppState={resetAppState}
                    >
                      <AddGameForm isLoading={isLoading} setIsLoading={setIsLoading} isRefreshing={isRefreshing} />
                    </MainApp>
                  )
                }
              />
          
              <Route
                path="/profile"
                element={
                  !currentUser ? (
                    <Navigate to="/auth" replace />
                  ) : hasPlayers === false ? (
                    <Navigate to="/setup" replace />
                  ) : (
                    <MainApp
                      activeTab="profile"
                      setActiveTab={handleTabChange}
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                      isRefreshing={isRefreshing}
                      fadeIn={fadeIn}
                      resetAppState={resetAppState}
                    >
                      <ProfilePage isLoading={isLoading} setIsLoading={setIsLoading} isRefreshing={isRefreshing} />
                    </MainApp>
                  )
                }
              />
          
              {/* Catch all route - redirect to dashboard if authenticated and has players */}
              <Route
                path="*"
                element={
                  !currentUser ? (
                    <Navigate to="/auth" replace />
                  ) : hasPlayers === false ? (
                    <Navigate to="/setup" replace />
                  ) : (
                    <Navigate to="/" replace />
                  )
                }
              />
            </Routes>

            {/* Debug Reset Button - Visible on all pages */}
            <div className="fixed bottom-4 right-4 z-50">
              <button
                onClick={resetAppState}
                className="bg-red-600 text-white p-2 rounded-full shadow-lg hover:bg-red-700 focus:outline-none"
                title="Reset App State (Debug)"
              >
                <RotateCcw size={20} />
              </button>
            </div>
          </Router>
        </PlayerProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;