import React, { useState } from 'react';
import { usePlayer } from '../../context/PlayerContext';
import { formatInnings } from '../../utils/formatters';
import { calculateBattingAverage } from '../../utils/calculations';
import { Trash2 } from 'lucide-react';

const RecentGames = () => {
  const { playerData, removeGame } = usePlayer();
  const [gameToDelete, setGameToDelete] = useState(null);

  const handleDelete = async (gameId) => {
    await removeGame(gameId);
    setGameToDelete(null);
  };

  return (
    <div>
      <h2 className="text-xl font-bold mb-2 dark:text-darkText-primary">Recent Games</h2>
      <div className="space-y-3">
        {playerData.recentGames.map((game) => (
          <div key={game.id} className="bg-white dark:bg-dark-300 border border-gray-200 dark:border-dark-400 rounded-lg p-3 shadow-sm">
            <div className="flex justify-between mb-2">
              <p className="font-semibold dark:text-darkText-primary">vs {game.opponent}</p>
              <p className="text-gray-500 dark:text-darkText-secondary text-sm">{game.date}</p>
            </div>
            {!game.isPitching ? (
              <div>
                <p className="dark:text-darkText-primary">Batting: {game.battingStats?.hits || 0}/{game.battingStats?.atBats || 0}, {game.battingStats?.runs || 0} R, {game.battingStats?.rbi || 0} RBI</p>
                <p className="text-sm text-gray-500 dark:text-darkText-secondary">AVG: {calculateBattingAverage(game.battingStats?.hits || 0, game.battingStats?.atBats || 0)}</p>
              </div>
            ) : (
              <div>
                <p className="dark:text-darkText-primary">Pitching: {formatInnings(game.pitchingStats?.inningsPitched || 0)} IP, {game.pitchingStats?.strikeouts || 0} K</p>
                <p className="text-sm text-gray-500 dark:text-darkText-secondary">{game.pitchingStats?.hits || 0} H, {game.pitchingStats?.earnedRuns || 0} ER</p>
              </div>
            )}
            
            {/* Delete confirmation */}
            {gameToDelete === game.id ? (
              <div className="mt-2 p-2 bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-800 rounded">
                <p className="text-red-600 dark:text-red-400 text-xs font-medium mb-2">Delete this game?</p>
                <div className="flex justify-end space-x-2">
                  <button 
                    onClick={() => setGameToDelete(null)}
                    className="text-xs px-2 py-1 bg-gray-200 dark:bg-dark-400 rounded text-gray-700 dark:text-darkText-primary"
                  >
                    Cancel
                  </button>
                  <button 
                    onClick={() => handleDelete(game.id)}
                    className="text-xs px-2 py-1 bg-red-500 dark:bg-red-600 rounded text-white"
                  >
                    Delete
                  </button>
                </div>
              </div>
            ) : (
              <div className="flex justify-end mt-2 pt-2 border-t border-gray-100 dark:border-dark-400">
                <button 
                  onClick={() => setGameToDelete(game.id)}
                  className="text-red-500 dark:text-red-400 text-xs flex items-center"
                >
                  <Trash2 size={12} className="mr-1" />
                  Delete
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default RecentGames;