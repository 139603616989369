import React from 'react';
import { Trophy, Award, Star, Medal, Target } from 'lucide-react';

const AchievementBadges = ({ playerData }) => {
  // Example achievements (should be customized based on actual achievements)
  const achievements = [
    {
      id: 'first-game',
      title: 'First Game',
      description: 'Played your first game',
      icon: Star,
      earned: true,
      progress: 100
    },
    {
      id: 'doubles',
      title: 'Double Trouble',
      description: 'Hit 5 doubles in a season',
      icon: Trophy,
      earned: false,
      progress: playerData?.battingStats?.doubles ? (playerData.battingStats.doubles / 5) * 100 : 0
    },
    {
      id: 'triples',
      title: 'Triple Threat',
      description: 'Hit 3 triples in a season',
      icon: Award,
      earned: false,
      progress: playerData?.battingStats?.triples ? (playerData.battingStats.triples / 3) * 100 : 0
    },
    {
      id: 'rbi',
      title: 'Run Producer',
      description: 'Drive in 20 runs',
      icon: Medal,
      earned: false,
      progress: playerData?.battingStats?.rbi ? (playerData.battingStats.rbi / 20) * 100 : 0
    }
  ];

  return (
    <div className="bg-white dark:bg-dark-200 p-6 rounded-lg shadow-sm border border-gray-200 dark:border-dark-400">
      <h3 className="font-bold text-lg mb-4 dark:text-darkText-primary flex items-center gap-2">
        <Trophy size={20} className="text-yellow-500" />
        Achievements
      </h3>

      <div className="grid grid-cols-2 gap-4">
        {achievements.map((achievement) => {
          const Icon = achievement.icon;
          return (
            <div 
              key={achievement.id}
              className={`p-4 rounded-lg border ${
                achievement.earned 
                  ? 'bg-yellow-50 dark:bg-yellow-900/20 border-yellow-200 dark:border-yellow-800/30' 
                  : 'bg-gray-50 dark:bg-dark-300 border-gray-200 dark:border-dark-400'
              }`}
            >
              <div className="flex items-start gap-3">
                <div className={`p-2 rounded-full ${
                  achievement.earned 
                    ? 'bg-yellow-100 dark:bg-yellow-800/30 text-yellow-600 dark:text-yellow-400' 
                    : 'bg-gray-100 dark:bg-dark-400 text-gray-500 dark:text-darkText-tertiary'
                }`}>
                  <Icon size={20} />
                </div>
                <div className="flex-1">
                  <h4 className={`font-medium ${
                    achievement.earned 
                      ? 'text-yellow-800 dark:text-yellow-400' 
                      : 'text-gray-700 dark:text-darkText-secondary'
                  }`}>
                    {achievement.title}
                  </h4>
                  <p className="text-sm text-gray-600 dark:text-darkText-tertiary mb-2">
                    {achievement.description}
                  </p>
                  {!achievement.earned && (
                    <div className="mt-2">
                      <div className="flex justify-between text-xs mb-1">
                        <span className="text-gray-500 dark:text-darkText-tertiary">Progress</span>
                        <span className="font-medium dark:text-darkText-primary">{Math.min(achievement.progress, 100)}%</span>
                      </div>
                      <div className="w-full bg-gray-200 dark:bg-dark-400 rounded-full h-1.5">
                        <div 
                          className="bg-yellow-500 h-1.5 rounded-full" 
                          style={{ width: `${Math.min(achievement.progress, 100)}%` }}
                        ></div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {/* Upcoming Achievements */}
      <div className="mt-6">
        <h4 className="font-medium text-gray-700 dark:text-darkText-secondary mb-3 flex items-center gap-2">
          <Target size={16} />
          Next Achievements
        </h4>
        <div className="space-y-2">
          <div className="flex items-center justify-between p-2 bg-gray-50 dark:bg-dark-300 rounded">
            <span className="text-sm text-gray-600 dark:text-darkText-secondary">Hit 2 doubles in a game</span>
            <span className="text-xs text-gray-500 dark:text-darkText-tertiary">
              {playerData?.battingStats?.doubles || 0}/2
            </span>
          </div>
          <div className="flex items-center justify-between p-2 bg-gray-50 dark:bg-dark-300 rounded">
            <span className="text-sm text-gray-600 dark:text-darkText-secondary">Drive in 3 runs in a game</span>
            <span className="text-xs text-gray-500 dark:text-darkText-tertiary">
              {playerData?.battingStats?.rbi || 0}/3
            </span>
          </div>
          <div className="flex items-center justify-between p-2 bg-gray-50 dark:bg-dark-300 rounded">
            <span className="text-sm text-gray-600 dark:text-darkText-secondary">Maintain a .400 OBP for 10 games</span>
            <span className="text-xs text-gray-500 dark:text-darkText-tertiary">7/10</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AchievementBadges; 