import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { 
  LineChart, 
  Line, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend, 
  ResponsiveContainer,
  ReferenceLine
} from 'recharts';
import { Calendar, ChevronDown, BarChart3, TrendingUp, TrendingDown, AlertTriangle } from 'lucide-react';
import StatCard from '../shared/StatCard';
import { usePlayer } from '../../context/PlayerContext';

// Helper function to format dates
const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  return `${date.getMonth() + 1}/${date.getDate()}`;
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white dark:bg-dark-300 p-2 border border-gray-200 dark:border-dark-400 shadow-md rounded text-xs">
        <p className="font-semibold dark:text-darkText-primary">{payload[0].payload.fullDate}</p>
        <p className="text-blue-600 dark:text-blue-400">{`vs ${payload[0].payload.opponent}`}</p>
        <p className="dark:text-darkText-primary">{`${payload[0].name}: ${payload[0].value}`}</p>
        {payload.length > 1 && <p className="dark:text-darkText-primary">{`${payload[1].name}: ${payload[1].value}`}</p>}
        {payload[0].payload.notes && <p className="text-gray-600 dark:text-gray-400 mt-1">{payload[0].payload.notes}</p>}
      </div>
    );
  }
  return null;
};

const PerformanceTrends = ({ loading = false }) => {
  const { playerData } = usePlayer();
  const [selectedMetric, setSelectedMetric] = useState('battingAvg');
  const [dateRange, setDateRange] = useState('lastTen');
  const [chartData, setChartData] = useState([]);
  const [showCompare, setShowCompare] = useState(false);
  const [compareMetric, setCompareMetric] = useState('obp');
  // Generate mock trend data
  useEffect(() => {
    if (loading) return;
    
    // In a real app, this would come from your API or context
    const generateMockData = () => {
      const metrics = {
        battingAvg: { min: 0.150, max: 0.400, label: 'AVG' },
        obp: { min: 0.250, max: 0.450, label: 'OBP' },
        slg: { min: 0.300, max: 0.600, label: 'SLG' },
        ops: { min: 0.550, max: 1.050, label: 'OPS' },
        era: { min: 1.5, max: 6.0, label: 'ERA' },
        whip: { min: 0.9, max: 1.8, label: 'WHIP' }
      };
      
      const opponents = ['Tigers', 'Yankees', 'Red Sox', 'Blue Jays', 'Orioles', 
                         'Rays', 'White Sox', 'Guardians', 'Twins', 'Royals', 
                         'Astros', 'Rangers', 'Angels', 'Athletics', 'Mariners'];
      
      // Create 30 days of data
      const today = new Date();
      const data = [];
      
      for (let i = 29; i >= 0; i--) {
        const date = new Date(today);
        date.setDate(date.getDate() - i);
        
        const fullDate = date.toLocaleDateString('en-US', { 
          month: 'short', 
          day: 'numeric',
          year: 'numeric'
        });
        
        // Only include game data for some days (not every day has a game)
        const hasGame = Math.random() > 0.3;
        
        if (hasGame) {
          const gameData = {
            date: formatDate(date),
            fullDate,
            day: i,
            opponent: opponents[Math.floor(Math.random() * opponents.length)],
            result: Math.random() > 0.5 ? 'W' : 'L',
            notes: Math.random() > 0.8 ? 'Season high' : ''
          };
          
          // Add random values for each metric
          Object.keys(metrics).forEach(metric => {
            const { min, max } = metrics[metric];
            gameData[metric] = parseFloat((Math.random() * (max - min) + min).toFixed(3));
          });
          
          data.push(gameData);
        }
      }
      
      return data;
    };
    
    const mockData = generateMockData();
    setChartData(mockData);
  }, [loading]);
  
  // Filter data based on selected date range
  const filteredData = useMemo(() => {
    if (!chartData.length) return [];
    
    switch(dateRange) {
      case 'last7':
        return chartData.slice(-7);
      case 'last14':
        return chartData.slice(-14);
      case 'lastMonth':
        return chartData.slice(-30);
      case 'lastTen':
      default:
        return chartData.slice(-10);
    }
  }, [chartData, dateRange]);
  
  // Calculate average of last few games
  const calculateRecentAvg = (metric, games = 5) => {
    if (!chartData.length) return '0.000';
    
    const recentGames = chartData.slice(-games);
    const sum = recentGames.reduce((total, game) => total + (game[metric] || 0), 0);
    const avg = sum / recentGames.length;
    
    return metric === 'era' || metric === 'whip' 
      ? avg.toFixed(2) 
      : avg.toFixed(3);
  };
  
  // Calculate change trend (up or down)
  const calculateTrend = (metric, games = 5) => {
    if (chartData.length < games * 2) return 0;
    
    const recentGames = chartData.slice(-games);
    const previousGames = chartData.slice(-games * 2, -games);
    
    const recentAvg = recentGames.reduce((total, game) => total + (game[metric] || 0), 0) / recentGames.length;
    const previousAvg = previousGames.reduce((total, game) => total + (game[metric] || 0), 0) / previousGames.length;
    
    // For ERA and WHIP, lower is better, so we invert the trend
    const invertTrend = metric === 'era' || metric === 'whip';
    const percentChange = ((recentAvg - previousAvg) / previousAvg) * 100;
    
    return invertTrend ? -percentChange : percentChange;
  };
  
  // Get metric info
  const getMetricInfo = (metric) => {
    const metrics = {
      battingAvg: { label: 'Batting Average', color: '#3b82f6', id: 'battingAvg', icon: BarChart3 },
      obp: { label: 'On-Base %', color: '#10b981', id: 'obp', icon: BarChart3 },
      slg: { label: 'Slugging %', color: '#f59e0b', id: 'slg', icon: BarChart3 },
      ops: { label: 'OPS', color: '#8b5cf6', id: 'ops', icon: BarChart3 },
      era: { label: 'ERA', color: '#ef4444', id: 'era', icon: TrendingUp },
      whip: { label: 'WHIP', color: '#ec4899', id: 'whip', icon: TrendingUp }
    };
    
    return metrics[metric] || metrics.battingAvg;
  };
  
  const primaryMetric = getMetricInfo(selectedMetric);
  const secondaryMetric = getMetricInfo(compareMetric);
  
  // Get player career average for reference line
  const getCareerAverage = (metric) => {
    if (!playerData) return 0;
    
    switch(metric) {
      case 'battingAvg':
        return playerData?.careerStats?.battingAverage || 0.250;
      case 'obp':
        return playerData?.careerStats?.obp || 0.320;
      case 'slg':
        return playerData?.careerStats?.slg || 0.400;
      case 'ops':
        return playerData?.careerStats?.ops || 0.720;
      case 'era':
        return playerData?.careerStats?.era || 4.00;
      case 'whip':
        return playerData?.careerStats?.whip || 1.30;
      default:
        return 0;
    }
  };
  
  // Determine Y axis domain based on metric
  const getYAxisDomain = (metric) => {
    switch(metric) {
      case 'battingAvg':
        return [0, 0.5];
      case 'obp':
        return [0, 0.6];
      case 'slg':
        return [0, 0.8];
      case 'ops':
        return [0, 1.2];
      case 'era':
        return [0, 7];
      case 'whip':
        return [0, 2];
      default:
        return [0, 'auto'];
    }
  };
  
  const yAxisDomain = getYAxisDomain(selectedMetric);
  
  // Format tooltip value based on metric
  const formatValue = (value, metric) => {
    if (metric === 'era' || metric === 'whip') {
      return value.toFixed(2);
    }
    return value.toFixed(3);
  };

  return (
    <StatCard>
      {/* Chart Controls */}
      <div className="flex flex-wrap justify-between items-center mb-4 gap-2">
        <div className="flex items-center">
          <div className="relative">
            <select
              value={selectedMetric}
              onChange={(e) => setSelectedMetric(e.target.value)}
              className="pr-8 pl-3 py-1 bg-white dark:bg-dark-300 text-gray-800 dark:text-darkText-primary border border-gray-300 dark:border-dark-400 rounded-md text-sm appearance-none focus:outline-none focus:ring-2 focus:ring-blue-500"
              disabled={loading}
            >
              <option value="battingAvg">Batting Average</option>
              <option value="obp">On-Base %</option>
              <option value="slg">Slugging %</option>
              <option value="ops">OPS</option>
              <option value="era">ERA</option>
              <option value="whip">WHIP</option>
            </select>
            <ChevronDown size={14} className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 dark:text-darkText-secondary pointer-events-none" />
          </div>
          
          <div className="ml-2 flex items-center">
            <input 
              type="checkbox" 
              id="compareToggle" 
              checked={showCompare} 
              onChange={() => setShowCompare(!showCompare)}
              className="mr-1"
              disabled={loading}
            />
            <label htmlFor="compareToggle" className="text-sm dark:text-darkText-primary">Compare</label>
          </div>
          
          {showCompare && (
            <div className="relative ml-2">
              <select
                value={compareMetric}
                onChange={(e) => setCompareMetric(e.target.value)}
                className="pr-8 pl-3 py-1 bg-white dark:bg-dark-300 text-gray-800 dark:text-darkText-primary border border-gray-300 dark:border-dark-400 rounded-md text-sm appearance-none focus:outline-none focus:ring-2 focus:ring-blue-500"
                disabled={loading}
              >
                <option value="obp">On-Base %</option>
                <option value="slg">Slugging %</option>
                <option value="ops">OPS</option>
                <option value="battingAvg">Batting Average</option>
                <option value="era">ERA</option>
                <option value="whip">WHIP</option>
              </select>
              <ChevronDown size={14} className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 dark:text-darkText-secondary pointer-events-none" />
            </div>
          )}
        </div>
        
        <div className="flex items-center">
          <div className="relative flex items-center">
            <Calendar size={14} className="mr-1 text-gray-500" />
            <select
              value={dateRange}
              onChange={(e) => setDateRange(e.target.value)}
              className="pr-8 pl-3 py-1 bg-white dark:bg-dark-300 text-gray-800 dark:text-darkText-primary border border-gray-300 dark:border-dark-400 rounded-md text-sm appearance-none focus:outline-none focus:ring-2 focus:ring-blue-500"
              disabled={loading}
            >
              <option value="lastTen">Last 10 Games</option>
              <option value="last7">Last 7 Games</option>
              <option value="last14">Last 14 Games</option>
              <option value="lastMonth">Last Month</option>
            </select>
            <ChevronDown size={14} className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 dark:text-darkText-secondary pointer-events-none" />
          </div>
        </div>
      </div>
      
      {/* Loading State */}
      {loading ? (
        <div className="animate-pulse">
          <div className="h-64 bg-gray-200 rounded w-full mb-4"></div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <div className="h-4 bg-gray-200 rounded w-3/4 mb-2"></div>
              <div className="h-2 bg-gray-200 rounded w-full"></div>
            </div>
            <div>
              <div className="h-4 bg-gray-200 rounded w-3/4 mb-2"></div>
              <div className="h-2 bg-gray-200 rounded w-full"></div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {/* The Chart */}
          <div className="h-64 w-full mb-4">
            {filteredData.length > 0 ? (
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  data={filteredData}
                  margin={{ top: 5, right: 5, left: 0, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" opacity={0.2} />
                  <XAxis 
                    dataKey="date" 
                    tick={{ fontSize: 10 }} 
                    tickLine={false}
                    axisLine={{ stroke: '#e5e7eb', strokeWidth: 1 }}
                  />
                  <YAxis 
                    domain={yAxisDomain}
                    tick={{ fontSize: 10 }} 
                    tickFormatter={(value) => formatValue(value, selectedMetric)}
                    tickLine={false}
                    axisLine={{ stroke: '#e5e7eb', strokeWidth: 1 }}
                  />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend verticalAlign="top" height={36} />
                  
                  {/* Career average reference line */}
                  <ReferenceLine 
                    y={getCareerAverage(selectedMetric)} 
                    stroke="#9ca3af" 
                    strokeDasharray="3 3"
                    label={{ 
                      value: 'Career Avg', 
                      position: 'insideBottomRight',
                      fontSize: 10, 
                      fill: '#9ca3af' 
                    }}
                  />
                  
                  {/* Primary Metric Line */}
                  <Line
                    type="monotone"
                    name={primaryMetric.label}
                    dataKey={selectedMetric}
                    stroke={primaryMetric.color}
                    strokeWidth={2}
                    dot={{ r: 3, strokeWidth: 1 }}
                    activeDot={{ r: 6, strokeWidth: 0 }}
                    animationDuration={500}
                  />
                  
                  {/* Secondary Metric Line (if compare is enabled) */}
                  {showCompare && (
                    <Line
                      type="monotone"
                      name={secondaryMetric.label}
                      dataKey={compareMetric}
                      stroke={secondaryMetric.color}
                      strokeWidth={2}
                      strokeDasharray="5 5"
                      dot={{ r: 3, strokeWidth: 0 }}
                      activeDot={{ r: 6, strokeWidth: 0 }}
                      animationDuration={800}
                    />
                  )}
                </LineChart>
              </ResponsiveContainer>
            ) : (
              <div className="h-full flex items-center justify-center">
                <div className="flex flex-col items-center text-gray-500">
                  <AlertTriangle size={24} className="mb-2" />
                  <p>No data available for the selected period</p>
                </div>
              </div>
            )}
          </div>
          
          {/* Performance Metrics */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <div className="flex justify-between items-center">
                <p className="text-sm font-medium">Last 5 Games {primaryMetric.label}</p>
                <p className="text-sm font-bold">{calculateRecentAvg(selectedMetric, 5)}</p>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2 mt-1 overflow-hidden">
                <div 
                  className={`${
                    selectedMetric === 'era' || selectedMetric === 'whip' 
                      ? 'bg-red-500' 
                      : 'bg-green-500'
                  } h-2 rounded-full`} 
                  style={{ 
                    width: `${Math.min(Math.max(parseFloat(calculateRecentAvg(selectedMetric)) / 
                      (selectedMetric === 'era' 
                        ? 7 
                        : selectedMetric === 'whip' 
                          ? 2
                          : 0.5) * 100, 5), 100)}%` 
                  }}
                ></div>
              </div>
              <div className="flex justify-end mt-1">
                <div className="text-xs flex items-center">
                  {calculateTrend(selectedMetric) > 0 ? (
                    <span className="text-green-600 flex items-center">
                      <TrendingUp size={12} className="mr-1" />
                      {Math.abs(calculateTrend(selectedMetric)).toFixed(1)}%
                    </span>
                  ) : calculateTrend(selectedMetric) < 0 ? (
                    <span className="text-red-600 flex items-center">
                      <TrendingDown size={12} className="mr-1" />
                      {Math.abs(calculateTrend(selectedMetric)).toFixed(1)}%
                    </span>
                  ) : (
                    <span className="text-gray-500">No change</span>
                  )}
                </div>
              </div>
            </div>
            
            {showCompare && (
              <div>
                <div className="flex justify-between items-center">
                  <p className="text-sm font-medium">Last 5 Games {secondaryMetric.label}</p>
                  <p className="text-sm font-bold">{calculateRecentAvg(compareMetric, 5)}</p>
                </div>
                <div className="w-full bg-gray-200 rounded-full h-2 mt-1 overflow-hidden">
                  <div 
                    className={`${
                      compareMetric === 'era' || compareMetric === 'whip' 
                        ? 'bg-red-500' 
                        : 'bg-green-500'
                    } h-2 rounded-full`} 
                    style={{ 
                      width: `${Math.min(Math.max(parseFloat(calculateRecentAvg(compareMetric)) / 
                        (compareMetric === 'era' 
                          ? 7 
                          : compareMetric === 'whip' 
                            ? 2
                            : 0.5) * 100, 5), 100)}%` 
                    }}
                  ></div>
                </div>
                <div className="flex justify-end mt-1">
                  <div className="text-xs flex items-center">
                    {calculateTrend(compareMetric) > 0 ? (
                      <span className="text-green-600 flex items-center">
                        <TrendingUp size={12} className="mr-1" />
                        {Math.abs(calculateTrend(compareMetric)).toFixed(1)}%
                      </span>
                    ) : calculateTrend(compareMetric) < 0 ? (
                      <span className="text-red-600 flex items-center">
                        <TrendingDown size={12} className="mr-1" />
                        {Math.abs(calculateTrend(compareMetric)).toFixed(1)}%
                      </span>
                    ) : (
                      <span className="text-gray-500">No change</span>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </StatCard>
  );
};

// PropTypes definition
PerformanceTrends.propTypes = {
  loading: PropTypes.bool
};

export default PerformanceTrends;
