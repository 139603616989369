import { createContext, useContext, useState, useEffect } from 'react';
import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signOut, 
  onAuthStateChanged,
  sendPasswordResetEmail,
  updateProfile 
} from 'firebase/auth';
import { auth } from '../firebase/config';
import { initializeUser } from '../firebase/firestore';

// Create the authentication context
const AuthContext = createContext();

// Hook to use the auth context
export function useAuth() {
  return useContext(AuthContext);
}

// Provider component that wraps your app and makes auth object available
export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  // Sign up function
  async function signup(email, password, displayName) {
    setError('');
    try {
      // Create user in Firebase Auth
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      
      // Update the user's profile with display name
      if (displayName) {
        await updateProfile(userCredential.user, { displayName });
      }
      
      // Initialize user document in Firestore
      try {
        await initializeUser(userCredential.user.uid, {
          displayName: displayName || '',
          email: email || ''
        });
        console.log('User data initialized in Firestore');
      } catch (firestoreErr) {
        console.error('Error initializing user data in Firestore:', firestoreErr);
        // We don't throw this error to prevent blocking the sign-up process
        // The user document can be created later if needed
      }
      
      return userCredential;
    } catch (err) {
      setError(err.message);
      throw err;
    }
  }

  // Login function
  async function login(email, password) {
    setError('');
    try {
      return await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
      setError(err.message);
      throw err;
    }
  }

  // Logout function
  async function logout() {
    setError('');
    try {
      return await signOut(auth);
    } catch (err) {
      setError(err.message);
      throw err;
    }
  }

  // Password reset function
  async function resetPassword(email) {
    setError('');
    try {
      return await sendPasswordResetEmail(auth, email);
    } catch (err) {
      setError(err.message);
      throw err;
    }
  }

  // Effect for auth state observer
  useEffect(() => {
    // Listen for auth state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    // Clean up subscription on unmount
    return unsubscribe;
  }, []);

  // Context value to be provided
  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    error,
    setCurrentUser: (user) => setCurrentUser(user) // Expose for emergency reset
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

