import React, { useState, useEffect } from 'react';
import { usePlayer } from '../../context/PlayerContext';
import Header from '../shared/Header';
import { Save, Plus } from 'lucide-react';

const AddGameForm = () => {
  const { addGame, playerData, updateProfile } = usePlayer();
  const [showAddLeague, setShowAddLeague] = useState(false);
  const [newLeagueName, setNewLeagueName] = useState('');
  
  // Initialize gameData with the first league from player's leagues or empty string if none exist
  const [gameData, setGameData] = useState({
    date: new Date().toISOString().split('T')[0],
    opponent: '',
    league: playerData?.leagues?.length > 0 ? playerData.leagues[0] : '',
    // Batting stats
    atBats: 0,
    hits: 0,
    doubles: 0,
    triples: 0,
    homeRuns: 0,
    rbi: 0,
    walks: 0,
    battingStrikeouts: 0,  // Renamed from strikeouts
    hitByPitch: 0,
    // Pitching stats
    didPitch: false,
    inningsPitched: '',
    pitchingStrikeouts: 0,  // Renamed from strikeouts
    hitsAllowed: 0,
    walksAllowed: 0,
    earnedRuns: 0,
    pitchingResult: 'No Decision'
  });

  // Update league value if playerData changes
  useEffect(() => {
    if (playerData?.leagues?.length > 0 && !gameData.league) {
      setGameData(prevData => ({
        ...prevData,
        league: playerData.leagues[0]
      }));
    }
  }, [playerData, gameData.league]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    
    setGameData({
      ...gameData,
      [name]: newValue
    });
  };

  const handleAddLeague = async () => {
    if (!newLeagueName.trim()) return;
    
    // Create a new array with the new league added
    const updatedLeagues = [...(playerData.leagues || []), newLeagueName.trim()];
    
    try {
      // Use updateProfile from the context to update the player data
      if (updateProfile) {
        await updateProfile({
          ...playerData,
          leagues: updatedLeagues
        });
        
        // Update the current form to use the new league
        setGameData({
          ...gameData,
          league: newLeagueName.trim()
        });
        
        // Reset the form
        setNewLeagueName('');
        setShowAddLeague(false);
      }
    } catch (error) {
      console.error("Error adding league:", error);
      alert("Failed to add league. Please try again.");
    }
  };

  const handleSubmit = (e) => {
    if (e) e.preventDefault();
    
    const formattedGame = {
      date: new Date(gameData.date).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }),
      opponent: gameData.opponent,
      league: gameData.league,
      battingStats: {
        atBats: gameData.atBats,
        hits: gameData.hits,
        doubles: gameData.doubles,
        triples: gameData.triples,
        homeRuns: gameData.homeRuns,
        rbi: gameData.rbi,
        walks: gameData.walks,
        strikeouts: gameData.battingStrikeouts,
        hitByPitch: gameData.hitByPitch
      }
    };
    
    // Add pitching stats if applicable
    if (gameData.didPitch) {
      formattedGame.pitchingStats = {
        inningsPitched: parseFloat(gameData.inningsPitched),
        strikeouts: gameData.pitchingStrikeouts,
        hits: gameData.hitsAllowed,
        walks: gameData.walksAllowed,
        earnedRuns: gameData.earnedRuns,
        result: gameData.pitchingResult
      };
    }
    
    // Save the game
    addGame(formattedGame);
    
    // Reset form or redirect
    alert("Game added successfully!");
    
    // Reset the form
    setGameData({
      date: new Date().toISOString().split('T')[0],
      opponent: '',
      league: playerData?.leagues?.length > 0 ? playerData.leagues[0] : '',
      atBats: 0,
      hits: 0,
      doubles: 0,
      triples: 0,
      homeRuns: 0,
      rbi: 0,
      walks: 0,
      battingStrikeouts: 0,
      hitByPitch: 0,
      didPitch: false,
      inningsPitched: '',
      pitchingStrikeouts: 0,
      hitsAllowed: 0,
      walksAllowed: 0,
      earnedRuns: 0,
      pitchingResult: 'No Decision'
    });
  };

  return (
    <div className="p-4 dark:bg-dark-100">
      <Header 
        title="Add New Game"
        subtitle="Record your game statistics"
        actions={
          <button
            type="button"
            onClick={handleSubmit}
            title="Save Game"
            className="bg-blue-600 dark:bg-blue-700 text-white p-2 rounded-md hover:bg-blue-700 dark:hover:bg-blue-600 flex items-center justify-center"
          >
            <Save size={20} />
          </button>
        }
      />
      <form className="space-y-4" onSubmit={handleSubmit}>
        {/* Game Info Section */}
        <div className="grid grid-cols-2 gap-4">
          {/* Date Field */}
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-darkText-primary mb-1">
              Date
            </label>
            <input 
              type="date" 
              className="w-full p-2 border border-gray-300 dark:border-dark-400 dark:bg-dark-300 dark:text-darkText-primary rounded-md"
              name="date"
              value={gameData.date}
              onChange={handleChange}
              required
            />
          </div>
          
          {/* Opponent Field */}
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-darkText-primary mb-1">
              Opponent
            </label>
            <input 
              type="text" 
              className="w-full p-2 border border-gray-300 dark:border-dark-400 dark:bg-dark-300 dark:text-darkText-primary rounded-md"
              placeholder="Team name"
              name="opponent"
              value={gameData.opponent}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        
        {/* League Section */}
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-darkText-primary mb-1">
            League
          </label>
          <div className="flex gap-2">
            <select 
              className="w-full p-2 border border-gray-300 dark:border-dark-400 dark:bg-dark-300 dark:text-darkText-primary rounded-md"
              name="league"
              value={gameData.league}
              onChange={handleChange}
            >
              {/* Map through player's leagues or show a default if none exist */}
              {playerData?.leagues?.length > 0 ? (
                playerData.leagues.map((league, index) => (
                  <option key={index} value={league}>{league}</option>
                ))
              ) : (
                <option value="">No leagues available</option>
              )}
            </select>
            
            <button 
              type="button" 
              className="bg-gray-200 dark:bg-dark-400 text-gray-700 dark:text-darkText-primary p-2 rounded-md hover:bg-gray-300 dark:hover:bg-dark-300 flex items-center justify-center"
              onClick={() => setShowAddLeague(!showAddLeague)}
            >
              <Plus size={20} />
            </button>
          </div>
          
          {/* Add new league input */}
          {showAddLeague && (
            <div className="mt-2 flex gap-2 dark:bg-dark-300 rounded-md">
              <input
                type="text"
                className="w-full p-2 border border-gray-300 dark:border-dark-400 dark:bg-dark-300 dark:text-darkText-primary rounded-md"
                placeholder="Enter new league name"
                value={newLeagueName}
                onChange={(e) => setNewLeagueName(e.target.value)}
              />
              <button
                type="button"
                className="bg-blue-600 dark:bg-blue-700 text-white py-2 px-4 rounded-md hover:bg-blue-700 dark:hover:bg-blue-600 transition-colors"
                onClick={handleAddLeague}
              >
                Add
              </button>
            </div>
          )}
        </div>
        
        {/* Batting Stats Section */}
        <div className="border-b border-gray-200 dark:border-dark-400 pb-2">
          <h3 className="text-lg font-medium dark:text-darkText-primary mb-2">Batting Stats</h3>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-darkText-primary mb-1">At Bats</label>
              <input 
                type="number" 
                className="w-full p-2 border border-gray-300 dark:border-dark-400 dark:bg-dark-300 dark:text-darkText-primary rounded-md" 
                min="0"
                name="atBats"
                value={gameData.atBats}
                onChange={handleChange}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-darkText-primary mb-1">Hits</label>
              <input 
                type="number" 
                className="w-full p-2 border border-gray-300 dark:border-dark-400 dark:bg-dark-300 dark:text-darkText-primary rounded-md" 
                min="0"
                name="hits"
                value={gameData.hits}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="grid grid-cols-3 gap-4 mt-2">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-darkText-primary mb-1">2B</label>
              <input 
                type="number" 
                className="w-full p-2 border border-gray-300 dark:border-dark-400 dark:bg-dark-300 dark:text-darkText-primary rounded-md" 
                min="0"
                name="doubles"
                value={gameData.doubles}
                onChange={handleChange}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-darkText-primary mb-1">3B</label>
              <input 
                type="number" 
                className="w-full p-2 border border-gray-300 dark:border-dark-400 dark:bg-dark-300 dark:text-darkText-primary rounded-md" 
                min="0"
                name="triples"
                value={gameData.triples}
                onChange={handleChange}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-darkText-primary mb-1">HR</label>
              <input 
                type="number" 
                className="w-full p-2 border border-gray-300 dark:border-dark-400 dark:bg-dark-300 dark:text-darkText-primary rounded-md" 
                min="0"
                name="homeRuns"
                value={gameData.homeRuns}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 mt-2">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-darkText-primary mb-1">RBI</label>
              <input 
                type="number" 
                className="w-full p-2 border border-gray-300 dark:border-dark-400 dark:bg-dark-300 dark:text-darkText-primary rounded-md" 
                min="0"
                name="rbi"
                value={gameData.rbi}
                onChange={handleChange}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-darkText-primary mb-1">BB</label>
              <input 
                type="number" 
                className="w-full p-2 border border-gray-300 dark:border-dark-400 dark:bg-dark-300 dark:text-darkText-primary rounded-md" 
                min="0"
                name="walks"
                value={gameData.walks}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 mt-2">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-darkText-primary mb-1">K</label>
              <input 
                type="number" 
                className="w-full p-2 border border-gray-300 dark:border-dark-400 dark:bg-dark-300 dark:text-darkText-primary rounded-md" 
                min="0"
                name="battingStrikeouts"
                value={gameData.battingStrikeouts}
                onChange={handleChange}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-darkText-primary mb-1">HBP</label>
              <input 
                type="number" 
                className="w-full p-2 border border-gray-300 dark:border-dark-400 dark:bg-dark-300 dark:text-darkText-primary rounded-md" 
                min="0"
                name="hitByPitch"
                value={gameData.hitByPitch}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        
        {/* Pitching Stats Section */}
        <div>
          <div className="flex items-center mb-2">
            <h3 className="text-lg font-medium dark:text-darkText-primary">Pitching Stats</h3>
            <div className="ml-2">
              <input 
                type="checkbox" 
                id="didPitch" 
                className="mr-1 dark:bg-dark-300 dark:border-dark-400"
                name="didPitch"
                checked={gameData.didPitch}
                onChange={handleChange}
              />
              <label htmlFor="didPitch" className="text-sm dark:text-darkText-primary">I pitched in this game</label>
            </div>
          </div>
          
          {gameData.didPitch && (
            <>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-darkText-primary mb-1">Innings Pitched</label>
                  <input 
                    type="text" 
                    className="w-full p-2 border border-gray-300 dark:border-dark-400 dark:bg-dark-300 dark:text-darkText-primary rounded-md" 
                    placeholder="e.g. 6.1"
                    name="inningsPitched"
                    value={gameData.inningsPitched}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-darkText-primary mb-1">Strikeouts</label>
                  <input 
                    type="number" 
                    className="w-full p-2 border border-gray-300 dark:border-dark-400 dark:bg-dark-300 dark:text-darkText-primary rounded-md" 
                    min="0"
                    name="pitchingStrikeouts"
                    value={gameData.pitchingStrikeouts}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 mt-2">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-darkText-primary mb-1">Hits Allowed</label>
                  <input 
                    type="number" 
                    className="w-full p-2 border border-gray-300 dark:border-dark-400 dark:bg-dark-300 dark:text-darkText-primary rounded-md" 
                    min="0"
                    name="hitsAllowed"
                    value={gameData.hitsAllowed}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-darkText-primary mb-1">Walks</label>
                  <input 
                    type="number" 
                    className="w-full p-2 border border-gray-300 dark:border-dark-400 dark:bg-dark-300 dark:text-darkText-primary rounded-md" 
                    min="0"
                    name="walksAllowed"
                    value={gameData.walksAllowed}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 mt-2">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-darkText-primary mb-1">Earned Runs</label>
                  <input 
                    type="number" 
                    className="w-full p-2 border border-gray-300 dark:border-dark-400 dark:bg-dark-300 dark:text-darkText-primary rounded-md" 
                    min="0"
                    name="earnedRuns"
                    value={gameData.earnedRuns}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-darkText-primary mb-1">Result</label>
                  <select 
                    className="w-full p-2 border border-gray-300 dark:border-dark-400 dark:bg-dark-300 dark:text-darkText-primary rounded-md"
                    name="pitchingResult"
                    value={gameData.pitchingResult}
                    onChange={handleChange}
                  >
                    <option value="Win">Win</option>
                    <option value="Loss">Loss</option>
                    <option value="No Decision">No Decision</option>
                  </select>
                </div>
              </div>
            </>
          )}
        </div>
        
        {/* Submit Button */}
        <div className="pt-4">
          <button 
            type="submit" 
            className="w-full bg-blue-600 dark:bg-blue-700 text-white py-2 px-4 rounded-md hover:bg-blue-700 dark:hover:bg-blue-600 transition-colors"
          >
            Save Game Data
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddGameForm;