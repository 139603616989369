import React, { useState } from 'react';
import { CheckSquare, Square, CheckCircle, Edit, ListChecks, Dumbbell, Zap } from 'lucide-react';
import StatCard from '../shared/StatCard';
import { usePlayer } from '../../context/PlayerContext';

const PreGamePrep = ({ gameDate, opponent, gameTime }) => {
  const { playerData } = usePlayer();
  const [editMode, setEditMode] = useState(false);
  
  // Pre-game ritual checklist
  // In a real app, this would be stored in your user profile and could be customized
  const [ritualItems, setRitualItems] = useState([
    { id: 1, text: "Stretch for at least 10 minutes", checked: false, category: "physical" },
    { id: 2, text: "Light throwing session", checked: false, category: "physical" },
    { id: 3, text: "Practice swing with weighted bat", checked: false, category: "physical" },
    { id: 4, text: "Review scouting report on pitcher", checked: false, category: "mental" },
    { id: 5, text: "Visualize successful at-bats", checked: false, category: "mental" },
    { id: 6, text: "Pack extra batting gloves", checked: false, category: "equipment" },
    { id: 7, text: "Fill water bottles", checked: false, category: "equipment" },
    { id: 8, text: "Check weather forecast", checked: false, category: "preparation" }
  ]);
  
  // Custom rituals that are tied to the player's performance
  const [customRituals, setCustomRituals] = useState([
    { id: 101, text: "Wear lucky socks (batting .350 when worn)", checked: false, isCustom: true },
    { id: 102, text: "Listen to pre-game playlist", checked: false, isCustom: true }
  ]);
  
  // New ritual input
  const [newRitualText, setNewRitualText] = useState('');
  
  // Toggle item checked state
  const toggleItem = (id, isCustom = false) => {
    if (isCustom) {
      setCustomRituals(customRituals.map(item => 
        item.id === id ? { ...item, checked: !item.checked } : item
      ));
    } else {
      setRitualItems(ritualItems.map(item => 
        item.id === id ? { ...item, checked: !item.checked } : item
      ));
    }
  };
  
  // Add new custom ritual
  const addCustomRitual = () => {
    if (newRitualText.trim() === '') return;
    
    setCustomRituals([
      ...customRituals,
      {
        id: Date.now(),
        text: newRitualText,
        checked: false,
        isCustom: true
      }
    ]);
    setNewRitualText('');
  };
  
  // Delete custom ritual
  const deleteCustomRitual = (id) => {
    setCustomRituals(customRituals.filter(item => item.id !== id));
  };
  
  // Get performance insights based on checklist completion
  const getPerformanceInsights = () => {
    const totalItems = ritualItems.length + customRituals.length;
    const checkedItems = ritualItems.filter(item => item.checked).length + 
                         customRituals.filter(item => item.checked).length;
    
    const completionPercentage = (checkedItems / totalItems) * 100;
    
    if (completionPercentage >= 80) {
      return {
        message: "You're fully prepared! Games with 80%+ preparation have resulted in a .320 average.",
        color: "green",
        icon: <Zap size={16} className="text-green-500" />
      };
    } else if (completionPercentage >= 50) {
      return {
        message: "You're on the right track. Consider completing more pre-game rituals for optimal performance.",
        color: "yellow",
        icon: <Dumbbell size={16} className="text-yellow-500" />
      };
    } else {
      return {
        message: "Your pre-game routine is incomplete. Completing more items can improve your performance.",
        color: "red",
        icon: <ListChecks size={16} className="text-red-500" />
      };
    }
  };
  
  const performanceInsight = getPerformanceInsights();
  
  // Calculate completion by category
  const getCategoryCompletion = () => {
    const categories = {
      physical: { total: 0, completed: 0 },
      mental: { total: 0, completed: 0 },
      equipment: { total: 0, completed: 0 },
      preparation: { total: 0, completed: 0 }
    };
    
    ritualItems.forEach(item => {
      if (item.category) {
        categories[item.category].total++;
        if (item.checked) {
          categories[item.category].completed++;
        }
      }
    });
    
    return categories;
  };
  
  const categoryCompletion = getCategoryCompletion();
  
  return (
    <StatCard className="mt-4">
      <div className="flex justify-between items-center mb-4">
        <h3 className="font-medium flex items-center">
          <ListChecks size={18} className="text-blue-500 mr-2" />
          Pre-Game Preparation
        </h3>
        <button 
          onClick={() => setEditMode(!editMode)}
          className="text-sm text-blue-600 flex items-center"
        >
          <Edit size={16} className="mr-1" />
          {editMode ? 'Done' : 'Customize'}
        </button>
      </div>
      
      {/* Performance insight based on checklist completion */}
      <div className={`p-3 rounded-lg bg-${performanceInsight.color}-50 text-${performanceInsight.color}-800 mb-4 flex items-start`}>
        <div className="mr-2 mt-0.5">{performanceInsight.icon}</div>
        <div className="text-sm">{performanceInsight.message}</div>
      </div>
      
      {/* Category progress bars */}
      <div className="grid grid-cols-2 gap-3 mb-4">
        {Object.entries(categoryCompletion).map(([category, data]) => (
          data.total > 0 && (
            <div key={category} className="bg-gray-50 p-2 rounded">
              <div className="flex justify-between text-xs mb-1">
                <span className="capitalize">{category}</span>
                <span>{data.completed}/{data.total}</span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2">
                <div 
                  className="bg-blue-500 h-2 rounded-full" 
                  style={{ width: `${(data.completed / data.total) * 100}%` }}
                ></div>
              </div>
            </div>
          )
        ))}
      </div>
      
      {/* Ritual checklist */}
      <div className="space-y-2 mb-4">
        <div className="font-medium text-sm mb-1">Standard Routine</div>
        {ritualItems.map(item => (
          <div key={item.id} className="flex items-start">
            <button 
              onClick={() => toggleItem(item.id)}
              className="mr-2 mt-0.5 flex-shrink-0 text-gray-400 hover:text-blue-500"
            >
              {item.checked ? 
                <CheckSquare size={18} className="text-green-500" /> : 
                <Square size={18} />
              }
            </button>
            <div className={`text-sm ${item.checked ? 'line-through text-gray-400' : ''}`}>
              {item.text}
            </div>
          </div>
        ))}
      </div>
      
      {/* Custom rituals */}
      <div className="space-y-2 border-t border-gray-100 pt-3">
        <div className="font-medium text-sm mb-1">Your Personal Rituals</div>
        {customRituals.map(item => (
          <div key={item.id} className="flex items-start group">
            <button 
              onClick={() => toggleItem(item.id, true)}
              className="mr-2 mt-0.5 flex-shrink-0 text-gray-400 hover:text-blue-500"
            >
              {item.checked ? 
                <CheckSquare size={18} className="text-green-500" /> : 
                <Square size={18} />
              }
            </button>
            <div className={`text-sm flex-grow ${item.checked ? 'line-through text-gray-400' : ''}`}>
              {item.text}
            </div>
            {editMode && (
              <button 
                onClick={() => deleteCustomRitual(item.id)}
                className="text-red-500 opacity-0 group-hover:opacity-100 transition-opacity"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            )}
          </div>
        ))}
        
        {/* Add new ritual form */}
        {editMode && (
          <div className="flex items-center mt-2 pt-2">
            <input
              type="text"
              className="flex-grow p-2 text-sm border border-gray-300 rounded-l"
              placeholder="Add a personal ritual..."
              value={newRitualText}
              onChange={(e) => setNewRitualText(e.target.value)}
            />
            <button
              onClick={addCustomRitual}
              className="bg-blue-500 text-white p-2 rounded-r"
              disabled={newRitualText.trim() === ''}
            >
              <CheckCircle size={16} />
            </button>
          </div>
        )}
      </div>
      
      <div className="mt-3 pt-3 border-t border-gray-100 text-xs text-gray-500">
        These pre-game rituals are statistically correlated with your best performances.
      </div>
    </StatCard>
  );
};

export default PreGamePrep;