import { db } from './config';
import { 
  collection, 
  doc, 
  setDoc, 
  addDoc, 
  getDoc, 
  getDocs, 
  updateDoc, 
  deleteDoc, 
  query, 
  where, 
  orderBy, 
  limit, 
  serverTimestamp 
} from 'firebase/firestore';

/**
 * User Management Functions
 */

/**
 * Initialize a new user document in Firestore when they sign up
 * @param {string} uid - User ID from Firebase Auth
 * @param {object} userData - User data to store
 * @returns {Promise<void>}
 */
export const initializeUser = async (uid, userData) => {
  try {
    const userRef = doc(db, 'users', uid);
    const userSnapshot = await getDoc(userRef);
    
    // Only create a new user document if it doesn't already exist
    if (!userSnapshot.exists()) {
      await setDoc(userRef, {
        displayName: userData.displayName || '',
        email: userData.email || '',
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      });
      console.log('User document created');
    }
  } catch (error) {
    console.error('Error initializing user:', error);
    throw error;
  }
};

/**
 * Get user data from Firestore
 * @param {string} uid - User ID from Firebase Auth
 * @returns {Promise<object|null>} - User data or null if not found
 */
export const getUserData = async (uid) => {
  try {
    const userRef = doc(db, 'users', uid);
    const userSnapshot = await getDoc(userRef);
    
    if (userSnapshot.exists()) {
      return { id: userSnapshot.id, ...userSnapshot.data() };
    } else {
      console.log('No user document found!');
      return null;
    }
  } catch (error) {
    console.error('Error getting user data:', error);
    throw error;
  }
};

/**
 * Update user data in Firestore
 * @param {string} uid - User ID from Firebase Auth
 * @param {object} userData - User data to update
 * @returns {Promise<void>}
 */
export const updateUserData = async (uid, userData) => {
  try {
    const userRef = doc(db, 'users', uid);
    await updateDoc(userRef, {
      ...userData,
      updatedAt: serverTimestamp()
    });
  } catch (error) {
    console.error('Error updating user data:', error);
    throw error;
  }
};

/**
 * Player Management Functions
 */

/**
 * Create a new player profile
 * @param {string} uid - User ID from Firebase Auth
 * @param {object} playerData - Player data
 * @returns {Promise<string>} - ID of the created player
 */
export const createPlayer = async (uid, playerData) => {
  try {
    const playerCollectionRef = collection(db, 'users', uid, 'players');
    
    // Initialize the player with default stats
    const playerWithStats = {
      ...playerData,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      battingStats: {
        average: 0,
        obp: 0,
        slg: 0,
        ops: 0,
        atBats: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeRuns: 0,
        runs: 0,
        rbis: 0,
        walks: 0,
        strikeouts: 0,
        stolenBases: 0,
        hitByPitch: 0,
        sacrificeFlies: 0
      },
      pitchingStats: {
        era: 0,
        whip: 0,
        wins: 0,
        losses: 0,
        inningsPitched: 0,
        strikeouts: 0,
        walks: 0,
        hits: 0,
        earnedRuns: 0,
        saves: 0,
        games: 0,
        gamesStarted: 0,
        completeGames: 0
      }
    };
    
    const docRef = await addDoc(playerCollectionRef, playerWithStats);
    return docRef.id;
  } catch (error) {
    console.error('Error creating player:', error);
    throw error;
  }
};

/**
 * Get a player by ID
 * @param {string} uid - User ID from Firebase Auth
 * @param {string} playerId - Player ID
 * @returns {Promise<object|null>} - Player data or null if not found
 */
export const getPlayer = async (uid, playerId) => {
  try {
    const playerRef = doc(db, 'users', uid, 'players', playerId);
    const playerSnapshot = await getDoc(playerRef);
    
    if (playerSnapshot.exists()) {
      return { id: playerSnapshot.id, ...playerSnapshot.data() };
    } else {
      console.log('No player found!');
      return null;
    }
  } catch (error) {
    console.error('Error getting player:', error);
    throw error;
  }
};

/**
 * Get all players for a user
 * @param {string} uid - User ID from Firebase Auth
 * @returns {Promise<Array>} - Array of player objects
 */
export const getPlayers = async (uid) => {
  try {
    const playerCollectionRef = collection(db, 'users', uid, 'players');
    const querySnapshot = await getDocs(playerCollectionRef);
    
    const players = [];
    querySnapshot.forEach((doc) => {
      players.push({ id: doc.id, ...doc.data() });
    });
    
    return players;
  } catch (error) {
    console.error('Error getting players:', error);
    throw error;
  }
};

/**
 * Update a player profile
 * @param {string} uid - User ID from Firebase Auth
 * @param {string} playerId - Player ID
 * @param {object} playerData - Player data to update
 * @returns {Promise<void>}
 */
export const updatePlayer = async (uid, playerId, playerData) => {
  try {
    const playerRef = doc(db, 'users', uid, 'players', playerId);
    
    // Don't override stats when updating player profile
    const { battingStats, pitchingStats, ...updateData } = playerData;
    
    await updateDoc(playerRef, {
      ...updateData,
      updatedAt: serverTimestamp()
    });
  } catch (error) {
    console.error('Error updating player:', error);
    throw error;
  }
};

/**
 * Delete a player profile
 * @param {string} uid - User ID from Firebase Auth
 * @param {string} playerId - Player ID
 * @returns {Promise<void>}
 */
export const deletePlayer = async (uid, playerId) => {
  try {
    const playerRef = doc(db, 'users', uid, 'players', playerId);
    await deleteDoc(playerRef);
  } catch (error) {
    console.error('Error deleting player:', error);
    throw error;
  }
};

/**
 * Game Management Functions
 */

/**
 * Create a new game
 * @param {string} uid - User ID from Firebase Auth
 * @param {string} playerId - Player ID
 * @param {object} gameData - Game data
 * @returns {Promise<string>} - ID of the created game
 */
export const createGame = async (uid, playerId, gameData) => {
  try {
    const gameCollectionRef = collection(db, 'users', uid, 'players', playerId, 'games');
    
    const gameWithMetadata = {
      ...gameData,
      date: gameData.date || new Date().toISOString().split('T')[0],
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    };
    
    const docRef = await addDoc(gameCollectionRef, gameWithMetadata);
    
    // After adding a game, update the player's overall stats
    await updatePlayerStats(uid, playerId);
    
    return docRef.id;
  } catch (error) {
    console.error('Error creating game:', error);
    throw error;
  }
};

/**
 * Get a game by ID
 * @param {string} uid - User ID from Firebase Auth
 * @param {string} playerId - Player ID
 * @param {string} gameId - Game ID
 * @returns {Promise<object|null>} - Game data or null if not found
 */
export const getGame = async (uid, playerId, gameId) => {
  try {
    const gameRef = doc(db, 'users', uid, 'players', playerId, 'games', gameId);
    const gameSnapshot = await getDoc(gameRef);
    
    if (gameSnapshot.exists()) {
      return { id: gameSnapshot.id, ...gameSnapshot.data() };
    } else {
      console.log('No game found!');
      return null;
    }
  } catch (error) {
    console.error('Error getting game:', error);
    throw error;
  }
};

/**
 * Get all games for a player
 * @param {string} uid - User ID from Firebase Auth
 * @param {string} playerId - Player ID
 * @returns {Promise<Array>} - Array of game objects
 */
export const getGames = async (uid, playerId) => {
  try {
    const gameCollectionRef = collection(db, 'users', uid, 'players', playerId, 'games');
    const q = query(gameCollectionRef, orderBy('date', 'desc'));
    const querySnapshot = await getDocs(q);
    
    const games = [];
    querySnapshot.forEach((doc) => {
      games.push({ id: doc.id, ...doc.data() });
    });
    
    return games;
  } catch (error) {
    console.error('Error getting games:', error);
    throw error;
  }
};

/**
 * Get recent games for a player
 * @param {string} uid - User ID from Firebase Auth
 * @param {string} playerId - Player ID
 * @param {number} count - Number of games to retrieve
 * @returns {Promise<Array>} - Array of game objects
 */
export const getRecentGames = async (uid, playerId, count = 5) => {
  try {
    const gameCollectionRef = collection(db, 'users', uid, 'players', playerId, 'games');
    const q = query(gameCollectionRef, orderBy('date', 'desc'), limit(count));
    const querySnapshot = await getDocs(q);
    
    const games = [];
    querySnapshot.forEach((doc) => {
      games.push({ id: doc.id, ...doc.data() });
    });
    
    return games;
  } catch (error) {
    console.error('Error getting recent games:', error);
    throw error;
  }
};

/**
 * Update a game
 * @param {string} uid - User ID from Firebase Auth
 * @param {string} playerId - Player ID
 * @param {string} gameId - Game ID
 * @param {object} gameData - Game data to update
 * @returns {Promise<void>}
 */
export const updateGame = async (uid, playerId, gameId, gameData) => {
  try {
    const gameRef = doc(db, 'users', uid, 'players', playerId, 'games', gameId);
    
    await updateDoc(gameRef, {
      ...gameData,
      updatedAt: serverTimestamp()
    });
    
    // After updating a game, update the player's overall stats
    await updatePlayerStats(uid, playerId);
  } catch (error) {
    console.error('Error updating game:', error);
    throw error;
  }
};

/**
 * Delete a game
 * @param {string} uid - User ID from Firebase Auth
 * @param {string} playerId - Player ID
 * @param {string} gameId - Game ID
 * @returns {Promise<void>}
 */
export const deleteGame = async (uid, playerId, gameId) => {
  try {
    const gameRef = doc(db, 'users', uid, 'players', playerId, 'games', gameId);
    await deleteDoc(gameRef);
    
    // After deleting a game, update the player's overall stats
    await updatePlayerStats(uid, playerId);
  } catch (error) {
    console.error('Error deleting game:', error);
    throw error;
  }
};

/**
 * Stats Calculation Utilities
 */

/**
 * Calculate batting statistics from game data
 * @param {Array} games - Array of game objects
 * @returns {object} - Calculated batting stats
 */
export const calculateBattingStats = (games) => {
  const stats = {
    average: 0,
    obp: 0,
    slg: 0,
    ops: 0,
    atBats: 0,
    hits: 0,
    doubles: 0,
    triples: 0,
    homeRuns: 0,
    runs: 0,
    rbis: 0,
    walks: 0,
    strikeouts: 0,
    stolenBases: 0,
    hitByPitch: 0,
    sacrificeFlies: 0
  };
  
  if (!games || games.length === 0) return stats;
  
  // Sum up all stats from games
  games.forEach(game => {
    if (game.battingStats) {
      stats.atBats += game.battingStats.atBats || 0;
      stats.hits += game.battingStats.hits || 0;
      stats.doubles += game.battingStats.doubles || 0;
      stats.triples += game.battingStats.triples || 0;
      stats.homeRuns += game.battingStats.homeRuns || 0;
      stats.runs += game.battingStats.runs || 0;
      stats.rbis += game.battingStats.rbis || 0;
      stats.walks += game.battingStats.walks || 0;
      stats.strikeouts += game.battingStats.strikeouts || 0;
      stats.stolenBases += game.battingStats.stolenBases || 0;
      stats.hitByPitch += game.battingStats.hitByPitch || 0;
      stats.sacrificeFlies += game.battingStats.sacrificeFlies || 0;
    }
  });
  
  // Calculate derived stats
  if (stats.atBats > 0) {
    stats.average = stats.hits / stats.atBats;
    
    const singles = stats.hits - stats.doubles - stats.triples - stats.homeRuns;
    const totalBases = singles + (2 * stats.doubles) + (3 * stats.triples) + (4 * stats.homeRuns);
    
    stats.slg = totalBases / stats.atBats;
    
    const plateAppearances = stats.atBats + stats.walks + stats.hitByPitch + stats.sacrificeFlies;
    if (plateAppearances > 0) {
      stats.obp = (stats.hits + stats.walks + stats.hitByPitch) / plateAppearances;
    }
    
    stats.ops = stats.obp + stats.slg;
  }
  
  return stats;
};

/**
 * Calculate pitching statistics from game data
 * @param {Array} games - Array of game objects
 * @returns {object} - Calculated pitching stats
 */
export const calculatePitchingStats = (games) => {
  const stats = {
    era: 0,
    whip: 0,
    wins: 0,
    losses: 0,
    inningsPitched: 0,
    strikeouts: 0,
    walks: 0,
    hits: 0,
    earnedRuns: 0,
    saves: 0,
    games: 0,
    gamesStarted: 0,
    completeGames: 0
  };
  
  if (!games || games.length === 0) return stats;
  
  // Sum up all stats from games
  games.forEach(game => {
    if (game.pitchingStats) {
      stats.wins += game.pitchingStats.win ? 1 : 0;
      stats.losses += game.pitchingStats.loss ? 1 : 0;
      stats.inningsPitched += game.pitchingStats.inningsPitched || 0;
      stats.strikeouts += game.pitchingStats.strikeouts || 0;
      stats.walks += game.pitchingStats.walks || 0;
      stats.hits += game.pitchingStats.hits || 0;
      stats.earnedRuns += game.pitchingStats.earnedRuns || 0;
      stats.saves += game.pitchingStats.save ? 1 : 0;
      stats.games += 1;
      stats.gamesStarted += game.pitchingStats.started ? 1 : 0;
      stats.completeGames += game.pitchingStats.completeGame ? 1 : 0;
    }
  });
  
  // Calculate derived stats
  if (stats.inningsPitched > 0) {
    // ERA = (Earned Runs / Innings Pitched) * 9
    stats.era = (stats.earnedRuns / stats.inningsPitched) * 9;
    
    // WHIP = (Walks + Hits) / Innings Pitched
    stats.whip = (stats.walks + stats.hits) / stats.inningsPitched;
  }
  
  return stats;
};

/**
 * Update a player's stats based on their game data
 * @param {string} uid - User ID from Firebase Auth
 * @param {string} playerId - Player ID
 * @returns {Promise<void>}
 */
export const updatePlayerStats = async (uid, playerId) => {
  try {
    // Get all games for the player
    const games = await getGames(uid, playerId);
    
    // Calculate stats
    const battingStats = calculateBattingStats(games);
    const pitchingStats = calculatePitchingStats(games);
    
    // Update player document with calculated stats
    const playerRef = doc(db, 'users', uid, 'players', playerId);
    await updateDoc(playerRef, {
      battingStats,
      pitchingStats,
      updatedAt: serverTimestamp()
    });
    
    console.log('Player stats updated successfully');
  } catch (error) {
    console.error('Error updating player stats:', error);
    throw error;
  }
};

/**
 * Scheduled Games Management Functions
 */

/**
 * Create a new scheduled game
 * @param {string} uid - User ID from Firebase Auth
 * @param {string} playerId - Player ID
 * @param {object} gameData - Scheduled game data
 * @returns {Promise<string>} - ID of the created scheduled game
 */
export const createScheduledGame = async (uid, playerId, gameData) => {
  try {
    console.log('Creating scheduled game:', { uid, playerId, gameData });
    const scheduledGameCollectionRef = collection(db, 'users', uid, 'players', playerId, 'scheduledGames');
    
    const gameWithMetadata = {
      ...gameData,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      status: 'upcoming'
    };
    
    console.log('Saving game with metadata:', gameWithMetadata);
    const docRef = await addDoc(scheduledGameCollectionRef, gameWithMetadata);
    console.log('Game saved successfully with ID:', docRef.id);
    return docRef.id;
  } catch (error) {
    console.error('Error in createScheduledGame:', error);
    throw error;
  }
};

/**
 * @param {string} uid - User ID from Firebase Auth
 * @param {string} playerId - Player ID
 * @returns {Promise<Array>} - Array of scheduled game objects
 */
export const getScheduledGames = async (uid, playerId) => {
  try {
    console.log('Getting scheduled games for:', { uid, playerId });
    const scheduledGameCollectionRef = collection(db, 'users', uid, 'players', playerId, 'scheduledGames');
    const q = query(scheduledGameCollectionRef, orderBy('date', 'asc'));
    const querySnapshot = await getDocs(q);
    
    const games = [];
    querySnapshot.forEach((doc) => {
      games.push({ id: doc.id, ...doc.data() });
    });
    
    console.log('Retrieved scheduled games:', games);
    return games;
  } catch (error) {
    console.error('Error getting scheduled games:', error);
    throw error;
  }
};

/**
 * Update a scheduled game
 * @param {string} uid - User ID from Firebase Auth
 * @param {string} playerId - Player ID
 * @param {string} gameId - Scheduled game ID
 * @param {object} gameData - Game data to update
 * @returns {Promise<void>}
 */
export const updateScheduledGame = async (uid, playerId, gameId, gameData) => {
  try {
    const gameRef = doc(db, 'users', uid, 'players', playerId, 'scheduledGames', gameId);
    
    await updateDoc(gameRef, {
      ...gameData,
      updatedAt: serverTimestamp()
    });
  } catch (error) {
    console.error('Error updating scheduled game:', error);
    throw error;
  }
};

/**
 * Delete a scheduled game
 * @param {string} uid - User ID from Firebase Auth
 * @param {string} playerId - Player ID
 * @param {string} gameId - Scheduled game ID
 * @returns {Promise<void>}
 */
export const deleteScheduledGame = async (uid, playerId, gameId) => {
  try {
    console.log('Deleting scheduled game:', { uid, playerId, gameId });
    const gameRef = doc(db, 'users', uid, 'players', playerId, 'scheduledGames', gameId);
    await deleteDoc(gameRef);
    console.log('Successfully deleted scheduled game:', gameId);
  } catch (error) {
    console.error('Error deleting scheduled game:', error);
    throw error;
  }
};
