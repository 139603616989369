/**
 * Advanced Analytics Utilities for Baseball Statistics
 * Contains algorithms for spray charts, matchup analysis, slump detection, 
 * and advanced performance metrics
 */

/**
 * Generates data for a spray chart based on hit location data
 * @param {Array} hits - Array of hit objects with location data
 * @returns {Object} Processed data for spray chart visualization
 */
export const generateSprayChartData = (hits) => {
    // In a real implementation, hit objects would have x,y coordinates or zone information
    if (!hits || hits.length === 0) {
      return { points: [], zones: {} };
    }
    
    // Organize hits by field zones
    const zones = {
      leftField: 0,
      leftCenter: 0,
      center: 0,
      rightCenter: 0,
      rightField: 0,
      infield: 0
    };
    
    // Generate points for visualization
    const points = hits.map(hit => {
      // Determine zone based on hit direction
      const zone = hit.direction || 'center';
      if (zones.hasOwnProperty(zone)) {
        zones[zone]++;
      }
      
      // For visualization, we would return coordinates
      // In this mock implementation, we'll generate some based on the zone
      let x, y;
      switch(zone) {
        case 'leftField':
          x = Math.random() * 30 + 10; // 10-40
          y = Math.random() * 30 + 10; // 10-40
          break;
        case 'leftCenter':
          x = Math.random() * 30 + 30; // 30-60
          y = Math.random() * 30 + 10; // 10-40
          break;
        case 'center':
          x = Math.random() * 30 + 50; // 50-80
          y = Math.random() * 30 + 10; // 10-40
          break;
        case 'rightCenter':
          x = Math.random() * 30 + 70; // 70-100
          y = Math.random() * 30 + 10; // 10-40
          break;
        case 'rightField':
          x = Math.random() * 30 + 90; // 90-120
          y = Math.random() * 30 + 10; // 10-40
          break;
        case 'infield':
          x = Math.random() * 50 + 35; // 35-85
          y = Math.random() * 30 + 50; // 50-80
          break;
        default:
          x = Math.random() * 100;
          y = Math.random() * 100;
      }
      
      return {
        x,
        y,
        hitType: hit.type || 'single',
        result: hit.result || 'hit',
        gameId: hit.gameId || '',
        date: hit.date || new Date().toISOString(),
      };
    });
    
    // Calculate distribution percentages
    const totalHits = Object.values(zones).reduce((sum, count) => sum + count, 0);
    const distribution = {};
    
    Object.entries(zones).forEach(([zone, count]) => {
      distribution[zone] = totalHits > 0 ? (count / totalHits * 100).toFixed(1) : '0.0';
    });
    
    return {
      points,
      zones,
      distribution,
      pullPercentage: calculatePullPercentage(hits),
      oppositePowerIndex: calculateOppositePowerIndex(hits)
    };
  };
  
  /**
   * Calculate the percentage of pulled balls
   * @param {Array} hits - Array of hit objects
   * @returns {Number} Percentage of pulled balls
   */
  const calculatePullPercentage = (hits) => {
    if (!hits || hits.length === 0) return 0;
    
    // For demonstration - in a real implementation we'd use actual batted ball data
    // For a right-handed batter, pull = left field; for lefty, pull = right field
    const pullCount = hits.reduce((count, hit) => {
      // Detect if hit is pulled based on batter handedness and hit direction
      const isPulled = 
        (hit.batterHands === 'R' && ['leftField', 'leftCenter'].includes(hit.direction)) ||
        (hit.batterHands === 'L' && ['rightField', 'rightCenter'].includes(hit.direction));
      
      return isPulled ? count + 1 : count;
    }, 0);
    
    return (pullCount / hits.length * 100).toFixed(1);
  };
  
  /**
   * Calculate "Opposite Field Power Index"
   * Higher values indicate better power to opposite field
   * @param {Array} hits - Array of hit objects
   * @returns {Number} Opposite field power index (0-100)
   */
  const calculateOppositePowerIndex = (hits) => {
    if (!hits || hits.length === 0) return 0;
    
    // Filter to only extra-base hits to opposite field
    const oppositeXBH = hits.filter(hit => {
      const isOpposite = 
        (hit.batterHands === 'R' && ['rightField', 'rightCenter'].includes(hit.direction)) ||
        (hit.batterHands === 'L' && ['leftField', 'leftCenter'].includes(hit.direction));
      
      const isExtraBaseHit = ['double', 'triple', 'homeRun'].includes(hit.type);
      
      return isOpposite && isExtraBaseHit;
    });
    
    // Calculate index based on percentage of opposite field XBH
    const oppositeXBHPercentage = oppositeXBH.length / hits.length;
    
    // Scale to 0-100, where 30% or more opposite field XBH would be a perfect 100
    return Math.min(100, Math.round(oppositeXBHPercentage / 0.3 * 100));
  };
  
  /**
   * Detect slumps based on recent performance
   * @param {Array} gameStats - Array of game statistics in chronological order
   * @param {Object} options - Configuration options
   * @returns {Object} Slump analysis
   */
  export const detectSlump = (gameStats, options = {}) => {
    const {
      slumpThreshold = 0.150, // Batting average below this is considered a slump
      hotStreakThreshold = 0.350, // Batting average above this is considered a hot streak
      minGames = 5, // Minimum games to consider a streak
      recentGamesWindow = 10 // Number of recent games to analyze
    } = options;
    
    if (!gameStats || gameStats.length === 0) {
      return {
        currentStatus: 'neutral',
        recentAvg: 0,
        isSlumping: false,
        isHot: false,
        slumpDuration: 0,
        hotStreakDuration: 0,
        slumpDetails: null,
        recommendations: []
      };
    }
    
    // Take the most recent games for analysis
    const recentGames = gameStats.slice(-recentGamesWindow);
    
    // Calculate recent averages
    const recentStats = recentGames.reduce((stats, game) => {
      stats.atBats += game.atBats || 0;
      stats.hits += game.hits || 0;
      stats.strikeouts += game.strikeouts || 0;
      stats.walks += game.walks || 0;
      stats.hardHitBalls += game.hardHitBalls || 0;
      return stats;
    }, { atBats: 0, hits: 0, strikeouts: 0, walks: 0, hardHitBalls: 0 });
    
    const recentAvg = recentStats.atBats > 0 ? recentStats.hits / recentStats.atBats : 0;
    const strikeoutRate = recentStats.atBats > 0 ? recentStats.strikeouts / recentStats.atBats : 0;
    const walkRate = (recentStats.atBats + recentStats.walks) > 0 
      ? recentStats.walks / (recentStats.atBats + recentStats.walks) 
      : 0;
    const hardHitRate = recentStats.atBats > 0 ? recentStats.hardHitBalls / recentStats.atBats : 0;
  
    // Determine current status
    let currentStatus, isSlumping, isHot;
    
    if (recentAvg < slumpThreshold && recentStats.atBats >= minGames * 3) {
      currentStatus = 'slumping';
      isSlumping = true;
      isHot = false;
    } else if (recentAvg > hotStreakThreshold && recentStats.atBats >= minGames * 3) {
      currentStatus = 'hot';
      isSlumping = false;
      isHot = true;
    } else {
      currentStatus = 'neutral';
      isSlumping = false;
      isHot = false;
    }
    
    // Calculate streak duration
    let streakGames = 0;
    if (isSlumping || isHot) {
      const threshold = isSlumping ? slumpThreshold : hotStreakThreshold;
      const comparison = isSlumping ? 'below' : 'above';
      
      // Count consecutive games in streak
      for (let i = gameStats.length - 1; i >= 0; i--) {
        const game = gameStats[i];
        const gameAvg = game.atBats > 0 ? game.hits / game.atBats : 0;
        
        if ((comparison === 'below' && gameAvg < threshold) || 
            (comparison === 'above' && gameAvg > threshold)) {
          streakGames++;
        } else {
          break;
        }
      }
    }
    
    // Generate slump details
    let slumpDetails = null;
    let recommendations = [];
    
    if (isSlumping) {
      slumpDetails = {
        recentAvg: recentAvg.toFixed(3).replace(/^0+/, ''),
        duration: streakGames,
        strikeoutRate: (strikeoutRate * 100).toFixed(1) + '%',
        walkRate: (walkRate * 100).toFixed(1) + '%',
        hardHitRate: (hardHitRate * 100).toFixed(1) + '%',
        potentialCauses: []
      };
      
      // Identify potential causes
      if (strikeoutRate > 0.25) {
        slumpDetails.potentialCauses.push({
          issue: 'High strikeout rate',
          factor: 'contact',
          severity: 'high'
        });
        recommendations.push('Focus on making contact in batting practice');
      }
      
      if (walkRate < 0.05) {
        slumpDetails.potentialCauses.push({
          issue: 'Low walk rate',
          factor: 'plate discipline',
          severity: 'medium'
        });
        recommendations.push('Work on recognizing pitches and being more selective');
      }
      
      if (hardHitRate < 0.2) {
        slumpDetails.potentialCauses.push({
          issue: 'Not hitting the ball hard',
          factor: 'power/mechanics',
          severity: 'medium'
        });
        recommendations.push('Review swing mechanics with hitting coach');
      }
      
      // Add general recommendations
      recommendations.push('Take extra batting practice focusing on opposite field hitting');
      recommendations.push('Consider watching video of successful at-bats from earlier games');
    }
    
    return {
      currentStatus,
      recentAvg: recentAvg.toFixed(3).replace(/^0+/, ''),
      isSlumping,
      isHot,
      slumpDuration: isSlumping ? streakGames : 0,
      hotStreakDuration: isHot ? streakGames : 0,
      slumpDetails,
      recommendations
    };
  };
  
  /**
   * Analyzes matchups against specific pitchers
   * @param {Array} atBats - Array of at-bat results against specific pitchers
   * @returns {Object} Matchup analysis with recommendations
   */
  export const analyzePitcherMatchups = (atBats) => {
    if (!atBats || atBats.length === 0) {
      return { 
        pitchers: [],
        weaknesses: [],
        strengths: []
      };
    }
    
    // Group at-bats by pitcher
    const pitcherStats = {};
    
    atBats.forEach(ab => {
      const pitcherId = ab.pitcherId;
      if (!pitcherId) return;
      
      if (!pitcherStats[pitcherId]) {
        pitcherStats[pitcherId] = {
          pitcherId,
          pitcherName: ab.pitcherName || `Pitcher #${pitcherId}`,
          team: ab.pitcherTeam || 'Unknown Team',
          atBats: 0,
          hits: 0,
          walks: 0,
          strikeouts: 0,
          pitchResults: {}  // Track results by pitch type
        };
      }
      
      const stats = pitcherStats[pitcherId];
      stats.atBats++;
      
      if (ab.result === 'hit') stats.hits++;
      if (ab.result === 'walk') stats.walks++;
      if (ab.result === 'strikeout') stats.strikeouts++;
      
      // Track pitch type results
      if (ab.pitchType) {
        if (!stats.pitchResults[ab.pitchType]) {
          stats.pitchResults[ab.pitchType] = { 
            seen: 0, 
            hits: 0, 
            misses: 0,
            takes: 0 
          };
        }
        
        stats.pitchResults[ab.pitchType].seen++;
        
        if (ab.result === 'hit') {
          stats.pitchResults[ab.pitchType].hits++;
        } else if (ab.result === 'miss' || ab.result === 'strikeout') {
          stats.pitchResults[ab.pitchType].misses++;
        } else if (ab.result === 'take' || ab.result === 'ball') {
          stats.pitchResults[ab.pitchType].takes++;
        }
      }
    });
    
    // Calculate batting average against each pitcher
    const pitchers = Object.values(pitcherStats).map(stats => {
      const avg = stats.atBats > 0 ? (stats.hits / stats.atBats).toFixed(3).replace(/^0+/, '') : '.000';
      const obp = stats.atBats > 0 ? 
        ((stats.hits + stats.walks) / (stats.atBats + stats.walks)).toFixed(3).replace(/^0+/, '') : 
        '.000';
      
      // Analyze pitch type effectiveness
      const pitchTypeAnalysis = [];
      Object.entries(stats.pitchResults).forEach(([pitchType, results]) => {
        const successRate = results.seen > 0 ? results.hits / results.seen : 0;
        const whiffRate = results.seen > 0 ? results.misses / results.seen : 0;
        
        pitchTypeAnalysis.push({
          pitchType,
          seen: results.seen,
          successRate: (successRate * 100).toFixed(1) + '%',
          whiffRate: (whiffRate * 100).toFixed(1) + '%',
          isWeakness: whiffRate > 0.5,
          isStrength: successRate > 0.3
        });
      });
      
      return {
        ...stats,
        avg,
        obp,
        pitchTypeAnalysis,
        isStrongMatchup: parseFloat(avg.replace('.', '0.')) > 0.300,
        isWeakMatchup: parseFloat(avg.replace('.', '0.')) < 0.200
      };
    });
    
    // Sort by most faced pitchers
    pitchers.sort((a, b) => b.atBats - a.atBats);
    
    // Identify overall strengths and weaknesses
    const weaknesses = [];
    const strengths = [];
    
    // Analyze pitch type effectiveness across all pitchers
    const pitchTypeSummary = {};
    pitchers.forEach(pitcher => {
      pitcher.pitchTypeAnalysis.forEach(analysis => {
        if (!pitchTypeSummary[analysis.pitchType]) {
          pitchTypeSummary[analysis.pitchType] = {
            seen: 0,
            hits: 0,
            misses: 0,
            pitchers: 0
          };
        }
        
        pitchTypeSummary[analysis.pitchType].seen += analysis.seen;
        pitchTypeSummary[analysis.pitchType].hits += analysis.seen * parseFloat(analysis.successRate) / 100;
        pitchTypeSummary[analysis.pitchType].misses += analysis.seen * parseFloat(analysis.whiffRate) / 100;
        pitchTypeSummary[analysis.pitchType].pitchers += 1;
      });
    });
    
    // Identify weak pitch types
    Object.entries(pitchTypeSummary).forEach(([pitchType, data]) => {
      const overallSuccessRate = data.seen > 0 ? data.hits / data.seen : 0;
      const overallWhiffRate = data.seen > 0 ? data.misses / data.seen : 0;
      
      if (overallWhiffRate > 0.4 && data.seen >= 10) {
        weaknesses.push({
          type: 'pitch',
          description: `${pitchType} (${(overallWhiffRate * 100).toFixed(1)}% whiff rate)`,
          recommendation: `Practice recognizing ${pitchType} in batting practice`
        });
      }
      
      if (overallSuccessRate > 0.3 && data.seen >= 10) {
        strengths.push({
          type: 'pitch',
          description: `${pitchType} (${(overallSuccessRate * 100).toFixed(1)}% success rate)`,
          recommendation: `Look for ${pitchType} in key situations`
        });
      }
    });
    
    // Identify problem pitchers
    const problemPitchers = pitchers.filter(p => p.isWeakMatchup && p.atBats >= 5);
    if (problemPitchers.length > 0) {
      problemPitchers.forEach(pitcher => {
        weaknesses.push({
          type: 'pitcher',
          pitcherId: pitcher.pitcherId,
          description: `${pitcher.pitcherName} (${pitcher.avg} in ${pitcher.atBats} AB)`,
          recommendation: `Study video of at-bats against ${pitcher.pitcherName}`
        });
      });
    }
    
    // Identify favorable pitchers
    const favorablePitchers = pitchers.filter(p => p.isStrongMatchup && p.atBats >= 5);
    if (favorablePitchers.length > 0) {
      favorablePitchers.forEach(pitcher => {
        strengths.push({
          type: 'pitcher',
          pitcherId: pitcher.pitcherId,
          description: `${pitcher.pitcherName} (${pitcher.avg} in ${pitcher.atBats} AB)`,
          recommendation: `Maintain approach against ${pitcher.pitcherName}`
        });
      });
    }
    
    return {
      pitchers,
      weaknesses,
      strengths,
      totalPitchersFaced: pitchers.length,
      overallStats: calculateOverallMatchupStats(pitchers)
    };
  };
  
  /**
   * Calculate overall stats from pitcher matchups
   * @param {Array} pitchers - Array of pitcher statistics
   * @returns {Object} Overall stats
   */
  const calculateOverallMatchupStats = (pitchers) => {
    const totals = pitchers.reduce((sum, pitcher) => {
      sum.atBats += pitcher.atBats;
      sum.hits += pitcher.hits;
      sum.walks += pitcher.walks;
      sum.strikeouts += pitcher.strikeouts;
      return sum;
    }, { atBats: 0, hits: 0, walks: 0, strikeouts: 0 });
    
    return {
      avg: totals.atBats > 0 ? 
        (totals.hits / totals.atBats).toFixed(3).replace(/^0+/, '') : 
        '.000',
      obp: (totals.atBats + totals.walks) > 0 ? 
        ((totals.hits + totals.walks) / (totals.atBats + totals.walks)).toFixed(3).replace(/^0+/, '') : 
        '.000',
      strikeoutRate: totals.atBats > 0 ? 
        ((totals.strikeouts / totals.atBats) * 100).toFixed(1) + '%' : 
        '0.0%',
    };
  };
  
  /**
   * Calculate quality of contact metrics from batted ball data
   * @param {Array} battedBalls - Array of batted ball data
   * @returns {Object} Quality of contact metrics
   */
  export const calculateQualityOfContact = (battedBalls) => {
    if (!battedBalls || battedBalls.length === 0) {
      return {
        hardHitPercentage: '0.0%',
        averageExitVelocity: '0.0',
        barrelPercentage: '0.0%',
        contactQuality: 'No data'
      };
    }
    
    // For this implementation, we'll assume battedBalls has the following properties:
    // - exitVelocity: number (mph)
    // - launchAngle: number (degrees)
    // - result: string ('hit', 'out', etc.)
    // - distance: number (feet)
    
    // Calculate metrics
    const totalBalls = battedBalls.length;
    const hardHitBalls = battedBalls.filter(bb => bb.exitVelocity >= 95).length;
    const barrels = battedBalls.filter(bb => {
      // Simplified "barrel" definition - in reality this is more complex
      return bb.exitVelocity >= 98 && 
             bb.launchAngle >= 10 && 
             bb.launchAngle <= 30;
    }).length;
    
    const hardHitPercentage = (hardHitBalls / totalBalls * 100).toFixed(1);
    const barrelPercentage = (barrels / totalBalls * 100).toFixed(1);
    const averageExitVelocity = battedBalls.reduce((sum, bb) => sum + (bb.exitVelocity || 0), 0) / totalBalls;
    
    // Determine overall contact quality
    let contactQuality;
    if (hardHitPercentage >= 45 && barrelPercentage >= 10) {
      contactQuality = 'Elite';
    } else if (hardHitPercentage >= 40 && barrelPercentage >= 7) {
      contactQuality = 'Excellent';
    } else if (hardHitPercentage >= 35 && barrelPercentage >= 5) {
      contactQuality = 'Above Average';
    } else if (hardHitPercentage >= 30 && barrelPercentage >= 3) {
      contactQuality = 'Average';
    } else {
      contactQuality = 'Below Average';
    }
    
    return {
      hardHitPercentage: hardHitPercentage + '%',
      barrelPercentage: barrelPercentage + '%',
      averageExitVelocity: averageExitVelocity.toFixed(1),
      contactQuality,
      hardHitBalls,
      barrels,
      totalBalls
    };
  };
  
  /**
   * Calculate situational hitting metrics
   * @param {Array} atBats - Array of at-bat data with situation information
   * @returns {Object} Situational hitting metrics
   */
  export const calculateSituationalStats = (atBats) => {
    if (!atBats || atBats.length === 0) {
      return {
        risp: { avg: '.000', atBats: 0, hits: 0 },
        clutch: { avg: '.000', atBats: 0, hits: 0 },
        leadoff: { avg: '.000', atBats: 0, hits: 0 },
        twoOut: { avg: '.000', atBats: 0, hits: 0 },
        overall: { avg: '.000', atBats: 0, hits: 0 }
      };
    }
    
    // Initialize statistical categories
    const situations = {
      risp: { atBats: 0, hits: 0 },        // Runners in scoring position
      clutch: { atBats: 0, hits: 0 },      // Late & close (7th inning or later, tied or 1-run game)
      leadoff: { atBats: 0, hits: 0 },     // Leading off an inning
      twoOut: { atBats: 0, hits: 0 },      // Two outs
      overall: { atBats: 0, hits: 0 }      // All at-bats
    };
    
    // Categorize at-bats
    atBats.forEach(ab => {
      // Track all at-bats for overall stats
      situations.overall.atBats++;
      if (ab.result === 'hit') situations.overall.hits++;
      
      // Runners in scoring position
      if (ab.runnersInScoringPosition) {
        situations.risp.atBats++;
        if (ab.result === 'hit') situations.risp.hits++;
      }
      
      // Clutch situations (late & close)
      if (ab.inning >= 7 && ab.scoreDifference <= 1) {
        situations.clutch.atBats++;
        if (ab.result === 'hit') situations.clutch.hits++;
      }
      
      // Leading off an inning
      if (ab.isLeadoff) {
        situations.leadoff.atBats++;
        if (ab.result === 'hit') situations.leadoff.hits++;
      }
      
      // Two outs
      if (ab.outs === 2) {
        situations.twoOut.atBats++;
        if (ab.result === 'hit') situations.twoOut.hits++;
      }
    });
    
    // Calculate batting averages
    Object.keys(situations).forEach(key => {
      const { atBats, hits } = situations[key];
      situations[key].avg = atBats > 0 ? 
        (hits / atBats).toFixed(3).replace(/^0+/, '') : 
        '.000';
    });
    
    // Add performance indicators
    const situationalPerformance = {
      risp: compareToOverall(situations.risp.avg, situations.overall.avg),
      clutch: compareToOverall(situations.clutch.avg, situations.overall.avg),
      leadoff: compareToOverall(situations.leadoff.avg, situations.overall.avg),
      twoOut: compareToOverall(situations.twoOut.avg, situations.overall.avg)
    };
    
    return {
      ...situations,
      performance: situationalPerformance
    };
  };
  
  /**
   * Compare situational average to overall average
   * @param {string} situationalAvg - Situational batting average
   * @param {string} overallAvg - Overall batting average
   * @returns {string} Performance indicator
   */
  const compareToOverall = (situationalAvg, overallAvg) => {
    const situational = parseFloat(situationalAvg.replace('.', '0.'));
    const overall = parseFloat(overallAvg.replace('.', '0.'));
    
    if (situational >= overall + 0.050) {
      return 'excellent';
    } else if (situational >= overall + 0.020) {
      return 'good';
    } else if (situational >= overall - 0.020) {
      return 'average';
    } else if (situational >= overall - 0.050) {
      return 'below-average';
    } else {
      return 'poor';
    }
  };