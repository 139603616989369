import React, { useState, useEffect } from 'react';
import { User, LogOut, AlertCircle, Save, CheckCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { usePlayer } from '../../context/PlayerContext';
import { useAuth } from '../../context/AuthContext';
import LeagueManager from './LeagueManager';
import PlayerStatsSummary from './PlayerStatsSummary';
import AchievementBadges from './AchievementBadges';

// List of common baseball positions
const POSITIONS = [
  'Pitcher',
  'Catcher',
  'First Base',
  'Second Base',
  'Third Base',
  'Shortstop',
  'Left Field',
  'Center Field',
  'Right Field',
  'Designated Hitter',
  'Utility',
];

const ProfilePage = () => {
  const { playerData, updateProfile, resetPlayerState, isLoading: isPlayerLoading, error: playerError } = usePlayer();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  
  const [profileData, setProfileData] = useState({
    name: '',
    jerseyNumber: '',
    position: 'Utility',
    leagues: [],
    battingHand: 'right'
  });
  
  // Update form data when playerData changes
  useEffect(() => {
    if (playerData) {
      setProfileData({
        name: playerData.name || '',
        jerseyNumber: playerData.number || '',
        position: playerData.position || 'Utility',
        leagues: playerData.leagues || [],
        battingHand: playerData.battingHand || 'right'
      });
    }
  }, [playerData]);
  
  // Handle form field changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    // For checkbox inputs
    if (type === 'checkbox') {
      setProfileData({
        ...profileData,
        [name]: checked
      });
    } 
    // For radio inputs
    else if (type === 'radio') {
      setProfileData({
        ...profileData,
        [name]: value
      });
    } 
    // For all other inputs
    else {
      setProfileData({
        ...profileData,
        [name]: value
      });
    }
  };

  // Handle changes to leagues from LeagueManager
  const handleLeaguesChange = (updatedLeagues) => {
    setProfileData({
      ...profileData,
      leagues: updatedLeagues
    });
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');
    setIsSaving(true);
    
    try {
      // Validate form
      if (!profileData.name.trim()) {
        throw new Error('Name is required');
      }
      
      // Prepare data for update
      const dataToUpdate = {
        name: profileData.name,
        number: profileData.jerseyNumber,
        position: profileData.position,
        leagues: profileData.leagues
      };
      
      // Save profile data
      await updateProfile(dataToUpdate);
      setSuccessMessage('Profile updated successfully!');
    } catch (err) {
      console.error('Error saving profile:', err);
      setError(err.message || 'Failed to update profile. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };
  
  // Handle logging out
  const handleLogout = async () => {
    try {
      setIsLoggingOut(true);
      console.log("Logging out...");
      
      // Reset player context state
      if (resetPlayerState) {
        resetPlayerState();
      }
      
      // Clear any localStorage items
      localStorage.removeItem('newPlayerCreated');
      localStorage.removeItem('playerCreatedAt');
      
      // Sign out from Firebase Auth
      await logout();
      
      // Force full page navigation to auth
      window.location.href = "/auth";
    } catch (error) {
      console.error("Error during logout:", error);
      setIsLoggingOut(false);
      alert("Failed to log out. Please try again.");
    }
  };
  
  // Show loading state
  if (isPlayerLoading) {
    return (
      <div className="p-4 flex items-center justify-center h-full">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500 mx-auto mb-3"></div>
          <p className="text-gray-600">Loading profile...</p>
        </div>
      </div>
    );
  }
  
  return (
    <div className="p-4 bg-gray-50 dark:bg-dark-100 min-h-screen">
      {/* Header with player name */}
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-2xl font-bold dark:text-darkText-primary flex items-center gap-2">
          <User size={24} className="text-blue-600 dark:text-blue-500" />
          {playerData.name || 'Player'}'s Profile
        </h1>
      </div>
      
      {(error || playerError) && (
        <div className="mb-4 p-3 bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-800/30 rounded-md flex items-start">
          <AlertCircle size={18} className="text-red-500 dark:text-red-400 mr-2 mt-0.5 flex-shrink-0" />
          <p className="text-red-600 dark:text-red-400 text-sm">{error || playerError}</p>
        </div>
      )}
      
      {successMessage && (
        <div className="mb-4 p-3 bg-green-50 dark:bg-green-900/20 border border-green-200 dark:border-green-800/30 rounded-md flex items-start">
          <CheckCircle size={18} className="text-green-500 dark:text-green-400 mr-2 mt-0.5 flex-shrink-0" />
          <p className="text-green-600 dark:text-green-400 text-sm">{successMessage}</p>
        </div>
      )}

      {/* Profile sections */}
      <div className="grid md:grid-cols-2 gap-6">
        {/* Profile Info Card */}
        <div className="space-y-6">
          <div className="bg-white dark:bg-dark-200 p-6 rounded-lg shadow-sm border border-gray-200 dark:border-dark-400">
            <div className="flex items-center space-x-4 mb-6">
              <div className="h-20 w-20 bg-blue-100 dark:bg-blue-900/30 rounded-full flex items-center justify-center text-blue-600 dark:text-blue-400 relative group">
                <User size={36} />
                <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-30 rounded-full transition-all duration-200 flex items-center justify-center opacity-0 group-hover:opacity-100">
                  <span className="text-white text-xs font-medium">Change</span>
                </div>
              </div>
              <div>
                <h3 className="font-bold text-lg dark:text-darkText-primary">{profileData.name || 'Player Name'}</h3>
                <p className="text-gray-600 dark:text-darkText-secondary">
                  {profileData.position} 
                  {profileData.jerseyNumber && ` • #${profileData.jerseyNumber}`}
                  {profileData.battingHand && ` • ${profileData.battingHand.charAt(0).toUpperCase() + profileData.battingHand.slice(1)} Handed`}
                </p>
              </div>
            </div>
            
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-darkText-secondary mb-1">Name</label>
                <input
                  type="text"
                  name="name"
                  value={profileData.name}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 dark:border-dark-400 rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-dark-300 dark:text-darkText-primary"
                  placeholder="Your name"
                  required
                />
              </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-darkText-secondary mb-1">Jersey Number</label>
              <input
                type="text"
                name="jerseyNumber"
                value={profileData.jerseyNumber}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 dark:border-dark-400 rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-dark-300 dark:text-darkText-primary"
                placeholder="Jersey number"
              />
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-darkText-secondary mb-1">Primary Position</label>
              <select
                name="position"
                value={profileData.position}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 dark:border-dark-400 rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-dark-300 dark:text-darkText-primary"
              >
                {POSITIONS.map(pos => (
                  <option key={pos} value={pos}>{pos}</option>
                ))}
              </select>
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-darkText-secondary mb-1">Batting Hand</label>
              <div className="flex space-x-4">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    name="battingHand"
                    value="right"
                    checked={profileData.battingHand === 'right'}
                    onChange={handleChange}
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 dark:border-dark-400"
                  />
                  <span className="ml-2 text-sm text-gray-700 dark:text-darkText-secondary">Right</span>
                </label>
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    name="battingHand"
                    value="left"
                    checked={profileData.battingHand === 'left'}
                    onChange={handleChange}
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 dark:border-dark-400"
                  />
                  <span className="ml-2 text-sm text-gray-700 dark:text-darkText-secondary">Left</span>
                </label>
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    name="battingHand"
                    value="switch"
                    checked={profileData.battingHand === 'switch'}
                    onChange={handleChange}
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 dark:border-dark-400"
                  />
                  <span className="ml-2 text-sm text-gray-700 dark:text-darkText-secondary">Switch</span>
                </label>
              </div>
            </div>
            
            <div className="pt-2">
              <LeagueManager 
                leagues={profileData.leagues} 
                onLeaguesChange={handleLeaguesChange}
              />
            </div>
            
            <div className="pt-6 space-y-4">
              {/* Save Profile Button */}
              <button
                type="submit"
                disabled={isSaving}
                className="w-full flex items-center justify-center bg-blue-600 dark:bg-blue-700 text-white py-2 px-4 rounded-md hover:bg-blue-700 dark:hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-70 disabled:cursor-not-allowed"
              >
                {isSaving ? (
                  <>
                    <div className="animate-spin h-4 w-4 border-t-2 border-b-2 border-white rounded-full mr-2"></div>
                    Saving...
                  </>
                ) : (
                  <>
                    <Save size={18} className="mr-2" />
                    Save Profile
                  </>
                )}
              </button>
              
              {/* Sign Out Button */}
              <div className="pt-4 mt-4 border-t border-gray-200 dark:border-dark-400">
                <button
                  type="button"
                  onClick={handleLogout}
                  disabled={isLoggingOut}
                  className="w-full flex items-center justify-center bg-red-600 dark:bg-red-700 text-white py-2 px-4 rounded-md hover:bg-red-700 dark:hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 disabled:opacity-70 disabled:cursor-not-allowed"
                >
                  {isLoggingOut ? (
                    <>
                      <div className="animate-spin h-4 w-4 border-t-2 border-b-2 border-white rounded-full mr-2"></div>
                      Signing out...
                    </>
                  ) : (
                    <>
                      <LogOut size={18} className="mr-2" />
                      Sign Out
                    </>
                  )}
                </button>
              </div>
            </div>
            </form>
          </div>

          {/* Achievement Badges */}
          <AchievementBadges playerData={playerData} />
        </div>

        {/* Stats and Recent Activity */}
        <div className="space-y-6">
          {/* Player Stats Summary */}
          <PlayerStatsSummary playerData={playerData} />

          {/* Recent Games */}
          <div className="bg-white dark:bg-dark-200 p-6 rounded-lg shadow-sm border border-gray-200 dark:border-dark-400">
            <h3 className="font-bold text-lg mb-4 dark:text-darkText-primary">Recent Games</h3>
            {playerData?.recentGames && playerData.recentGames.length > 0 ? (
              <div className="space-y-2">
                {playerData.recentGames.slice(0, 5).map((game, index) => (
                  <div key={index} className="border border-gray-200 dark:border-dark-400 p-2 rounded text-sm">
                    <div className="flex justify-between">
                      <span className="font-medium dark:text-darkText-primary">{game.opponent || 'Opponent'}</span>
                      <span className="text-gray-500 dark:text-darkText-tertiary">{game.date}</span>
                    </div>
                    <div className="mt-1 text-sm text-gray-600 dark:text-darkText-secondary">
                      {game.battingStats && (
                        <span>Batting: {game.battingStats.hits}-{game.battingStats.atBats} ({game.battingStats.hits/game.battingStats.atBats || 0})</span>
                      )}
                      {game.pitchingStats && (
                        <span className="ml-2">Pitching: {game.pitchingStats.inningsPitched} IP, {game.pitchingStats.strikeouts} K</span>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-gray-500 dark:text-darkText-tertiary text-sm">No recent games</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
