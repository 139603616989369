/**
 * Calculate batting average
 * @param {number} hits - Number of hits
 * @param {number} atBats - Number of at-bats
 * @returns {string} - Formatted batting average
 */
export const calculateBattingAverage = (hits, atBats) => {
    return atBats > 0 ? (hits / atBats).toFixed(3).replace(/^0+/, '') : '.000';
  };
  
  /**
   * Calculate on-base percentage
   * @param {number} hits - Number of hits
   * @param {number} walks - Number of walks
   * @param {number} hitByPitch - Number of times hit by pitch
   * @param {number} atBats - Number of at-bats
   * @param {number} sacrificeFlies - Number of sacrifice flies
   * @returns {string} - Formatted on-base percentage
   */
  export const calculateOBP = (hits, walks, hitByPitch, atBats, sacrificeFlies) => {
    const numerator = hits + walks + hitByPitch;
    const denominator = atBats + walks + hitByPitch + sacrificeFlies;
    return denominator > 0 ? (numerator / denominator).toFixed(3).replace(/^0+/, '') : '.000';
  };
  
  /**
   * Calculate slugging percentage
   * @param {number} singles - Number of singles
   * @param {number} doubles - Number of doubles
   * @param {number} triples - Number of triples
   * @param {number} homeRuns - Number of home runs
   * @param {number} atBats - Number of at-bats
   * @returns {string} - Formatted slugging percentage
   */
  export const calculateSLG = (singles, doubles, triples, homeRuns, atBats) => {
    const totalBases = singles + (2 * doubles) + (3 * triples) + (4 * homeRuns);
    return atBats > 0 ? (totalBases / atBats).toFixed(3).replace(/^0+/, '') : '.000';
  };
  
  /**
   * Calculate OPS (On-base Plus Slugging)
   * @param {number} obp - On-base percentage
   * @param {number} slg - Slugging percentage
   * @returns {string} - Formatted OPS
   */
  export const calculateOPS = (obp, slg) => {
    return (obp + slg).toFixed(3).replace(/^0+/, '');
  };
  
  /**
   * Calculate ERA (Earned Run Average)
   * @param {number} earnedRuns - Number of earned runs
   * @param {number} inningsPitched - Number of innings pitched
   * @returns {string} - Formatted ERA
   */
  export const calculateERA = (earnedRuns, inningsPitched) => {
    return inningsPitched > 0 ? ((earnedRuns * 9) / inningsPitched).toFixed(2) : '0.00';
  };
  
  /**
   * Calculate WHIP (Walks plus Hits per Inning Pitched)
   * @param {number} walks - Number of walks
   * @param {number} hits - Number of hits
   * @param {number} inningsPitched - Number of innings pitched
   * @returns {string} - Formatted WHIP
   */
  export const calculateWHIP = (walks, hits, inningsPitched) => {
    return inningsPitched > 0 ? ((walks + hits) / inningsPitched).toFixed(2) : '0.00';
  };