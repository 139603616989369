import React, { useState, useEffect } from 'react';
import { Cloud, CloudRain, CloudSnow, Sun, Wind, Umbrella, Droplets } from 'lucide-react';
import StatCard from './StatCard';

// Props:
// - location: string (location name or coordinates)
// - date: string (ISO format date for forecast)
// - gameTime: string (game start time)
// - showDetails: boolean (whether to show full details or just summary)
// - className: string (additional CSS classes)
const WeatherCard = ({ location, date, gameTime, showDetails = false, className = '' }) => {
  const [weather, setWeather] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    // In a real app, this would be an API call to a weather service
    // For demo purposes, we'll create mock data
    const getMockWeather = () => {
      setLoading(true);
      
      // Simulate API call delay
      setTimeout(() => {
        const conditions = ['Sunny', 'Partly Cloudy', 'Cloudy', 'Light Rain', 'Thunderstorms', 'Windy'];
        const randomCondition = conditions[Math.floor(Math.random() * conditions.length)];
        const randomTemp = Math.floor(Math.random() * 30) + 55; // 55-85°F
        const randomWindSpeed = Math.floor(Math.random() * 15); // 0-15 mph
        const randomWindDirection = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'][Math.floor(Math.random() * 8)];
        const randomHumidity = Math.floor(Math.random() * 60) + 30; // 30-90%
        const randomPrecipChance = Math.floor(Math.random() * 100); // 0-100%
        
        const mockWeather = {
          condition: randomCondition,
          temperature: randomTemp,
          humidity: randomHumidity,
          windSpeed: randomWindSpeed,
          windDirection: randomWindDirection,
          precipChance: randomPrecipChance,
          forecasted: true,
          location: location || 'Local Field',
          updated: new Date().toISOString()
        };
        
        setWeather(mockWeather);
        setLoading(false);
      }, 500);
    };
    
    getMockWeather();
    
    // In a real app, you'd make an API call like this:
    // fetch(`https://api.weatherservice.com/forecast?location=${location}&date=${date}`)
    //   .then(response => response.json())
    //   .then(data => {
    //     setWeather(data);
    //     setLoading(false);
    //   })
    //   .catch(err => {
    //     setError('Unable to load weather data');
    //     setLoading(false);
    //   });
  }, [location, date]);
  
  const getWeatherIcon = (condition) => {
    switch(condition) {
      case 'Sunny':
        return <Sun size={showDetails ? 36 : 20} className="text-yellow-500 dark:text-yellow-400" />;
      case 'Partly Cloudy':
        return <Cloud size={showDetails ? 36 : 20} className="text-gray-400 dark:text-gray-300" />;
      case 'Cloudy':
        return <Cloud size={showDetails ? 36 : 20} className="text-gray-500 dark:text-gray-300" />;
      case 'Light Rain':
        return <CloudRain size={showDetails ? 36 : 20} className="text-blue-400 dark:text-blue-300" />;
      case 'Thunderstorms':
        return <CloudRain size={showDetails ? 36 : 20} className="text-purple-500 dark:text-purple-400" />;
      case 'Windy':
        return <Wind size={showDetails ? 36 : 20} className="text-blue-300 dark:text-blue-300" />;
      case 'Snow':
        return <CloudSnow size={showDetails ? 36 : 20} className="text-blue-200 dark:text-blue-300" />;
      default:
        return <Sun size={showDetails ? 36 : 20} className="text-yellow-500 dark:text-yellow-400" />;
    }
  };
  
  // Baseball-specific weather insights
  const getBaseballInsights = (weather) => {
    const insights = [];
    
    if (weather.temperature > 80) {
      insights.push({
        icon: <Sun size={16} className="text-red-500 dark:text-red-400" />,
        text: "Hot weather - stay hydrated"
      });
    }
    
    if (weather.windSpeed > 10) {
      insights.push({
        icon: <Wind size={16} className="text-blue-500 dark:text-blue-400" />,
        text: `Strong ${weather.windDirection} wind may affect fly balls`
      });
    }
    
    if (weather.precipChance > 40) {
      insights.push({
        icon: <Umbrella size={16} className="text-gray-500 dark:text-gray-400" />,
        text: "Rain gear recommended"
      });
    }
    
    if (weather.humidity > 70) {
      insights.push({
        icon: <Droplets size={16} className="text-blue-400 dark:text-blue-300" />,
        text: "High humidity - grip may be affected"
      });
    }
    
    return insights;
  };
  
  if (loading) {
    return (
      <div className={`bg-gray-100 dark:bg-dark-300 p-3 rounded animate-pulse ${className} transition-colors`}>
        <div className="h-6 bg-gray-200 dark:bg-dark-400 rounded mb-2"></div>
        <div className="h-10 bg-gray-200 dark:bg-dark-400 rounded"></div>
      </div>
    );
  }
  
  if (error) {
    return (
      <div className={`bg-red-50 dark:bg-red-900/20 p-3 rounded text-red-700 dark:text-red-300 text-sm border border-red-100 dark:border-red-800/30 ${className} transition-colors`}>
        {error}
      </div>
    );
  }
  
  if (!weather) {
    return null;
  }
  
  const weatherInsights = getBaseballInsights(weather);
  
  if (showDetails) {
    return (
      <StatCard className={`${className} transition-colors`}>
        <div className="flex justify-between items-center mb-3">
          <h3 className="font-medium dark:text-darkText-primary">Game Day Forecast</h3>
          <span className="text-xs text-gray-500 dark:text-darkText-tertiary transition-colors">
            {weather.forecasted ? 'Forecast' : 'Current'}
          </span>
        </div>
        
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center">
            {getWeatherIcon(weather.condition)}
            <div className="ml-3">
              <div className="text-2xl font-bold dark:text-darkText-primary transition-colors">{weather.temperature}°F</div>
              <div className="text-gray-600 dark:text-darkText-secondary transition-colors">{weather.condition}</div>
            </div>
          </div>
          <div className="text-right">
            <div className="text-sm dark:text-darkText-secondary transition-colors">{weather.precipChance}% chance of rain</div>
            <div className="text-sm text-gray-600 dark:text-darkText-tertiary transition-colors">{weather.windSpeed} mph {weather.windDirection}</div>
          </div>
        </div>
        
        {weatherInsights.length > 0 && (
          <div className="mt-3 border-t border-gray-100 dark:border-dark-300 pt-3 transition-colors">
            <h4 className="text-sm font-medium mb-2 dark:text-darkText-primary transition-colors">Baseball Impact</h4>
            <div className="space-y-2">
              {weatherInsights.map((insight, index) => (
                <div key={index} className="flex items-center text-sm">
                  <div className="mr-2">{insight.icon}</div>
                  <div className="dark:text-darkText-secondary transition-colors">{insight.text}</div>
                </div>
              ))}
            </div>
          </div>
        )}
        
        <div className="mt-3 text-xs text-gray-500 dark:text-darkText-tertiary text-right transition-colors">
          For {weather.location} • Updated {new Date(weather.updated).toLocaleTimeString()}
        </div>
      </StatCard>
    );
  }
  
  // Compact view
  return (
    <div className={`bg-gray-50 dark:bg-dark-300/50 p-2 rounded flex items-center justify-between border border-gray-100 dark:border-dark-400 ${className} transition-colors`}>
      <div className="flex items-center">
        {getWeatherIcon(weather.condition)}
        <div className="ml-2">
          <div className="text-lg font-medium dark:text-darkText-primary transition-colors">{weather.temperature}°F</div>
          <div className="text-xs text-gray-500 dark:text-darkText-secondary transition-colors">{weather.condition}, {weather.precipChance}% rain</div>
        </div>
      </div>
      
      {weatherInsights.length > 0 && (
        <div className="text-xs text-gray-600 dark:text-darkText-tertiary max-w-[50%] truncate transition-colors">
          {weatherInsights[0].text}
        </div>
      )}
    </div>
  );
};

export default WeatherCard;
