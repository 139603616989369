import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const LeagueManager = ({ leagues, onLeaguesChange }) => {
  const [leaguesList, setLeaguesList] = useState(leagues || []);
  const [newLeague, setNewLeague] = useState('');
  const [showAddForm, setShowAddForm] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editValue, setEditValue] = useState('');

  // Sync internal state with prop changes
  useEffect(() => {
    setLeaguesList(leagues || []);
  }, [leagues]);

  const handleAddLeague = () => {
    if (newLeague.trim() === '') return;
    
    const updatedLeagues = [...leaguesList, newLeague];
    setLeaguesList(updatedLeagues);
    
    // Notify parent component of the change
    if (onLeaguesChange) {
      onLeaguesChange(updatedLeagues);
    }
    
    setNewLeague('');
    setShowAddForm(false);
  };

  const handleRemoveLeague = (index) => {
    const updatedLeagues = leaguesList.filter((_, i) => i !== index);
    setLeaguesList(updatedLeagues);
    
    // Notify parent component of the change
    if (onLeaguesChange) {
      onLeaguesChange(updatedLeagues);
    }
  };

  const startEditing = (index) => {
    setEditingIndex(index);
    setEditValue(leaguesList[index]);
  };

  const saveEdit = () => {
    if (editValue.trim() === '') return;
    
    const updatedLeagues = [...leaguesList];
    updatedLeagues[editingIndex] = editValue;
    setLeaguesList(updatedLeagues);
    
    // Notify parent component of the change
    if (onLeaguesChange) {
      onLeaguesChange(updatedLeagues);
    }
    
    setEditingIndex(null);
  };

  const cancelEdit = () => {
    setEditingIndex(null);
  };

  return (
    <div>
      <h4 className="font-medium mb-2 dark:text-darkText-primary">Leagues</h4>
      <div className="space-y-2">
        {leaguesList.map((league, index) => (
          <div key={index} className="bg-gray-100 dark:bg-dark-300 p-2 rounded flex justify-between items-center">
            {editingIndex === index ? (
              <input
                type="text"
                className="border border-gray-300 dark:border-dark-400 p-1 rounded flex-grow mr-2 dark:bg-dark-400 dark:text-darkText-primary"
                value={editValue}
                onChange={(e) => setEditValue(e.target.value)}
                autoFocus
              />
            ) : (
              <span className="dark:text-darkText-primary">{league}</span>
            )}
            
            {editingIndex === index ? (
              <div>
                <button 
                  type="button"
                  className="text-green-600 dark:text-green-400 text-sm mr-2"
                  onClick={saveEdit}
                >
                  Save
                </button>
                <button 
                  type="button"
                  className="text-gray-600 dark:text-gray-400 text-sm"
                  onClick={cancelEdit}
                >
                  Cancel
                </button>
              </div>
            ) : (
              <div>
                <button 
                  type="button"
                  className="text-blue-600 dark:text-blue-400 text-sm mr-2"
                  onClick={() => startEditing(index)}
                >
                  Edit
                </button>
                <button 
                  type="button"
                  className="text-red-600 dark:text-red-400 text-sm"
                  onClick={() => handleRemoveLeague(index)}
                >
                  Remove
                </button>
              </div>
            )}
          </div>
        ))}
        
        {showAddForm ? (
          <div className="flex items-center mt-2">
            <input
              type="text"
              className="border border-gray-300 dark:border-dark-400 p-2 rounded flex-grow mr-2 dark:bg-dark-400 dark:text-darkText-primary"
              placeholder="Enter league name"
              value={newLeague}
              onChange={(e) => setNewLeague(e.target.value)}
            />
            <button 
              type="button"
              className="bg-blue-600 dark:bg-blue-700 text-white px-3 py-2 rounded hover:bg-blue-700 dark:hover:bg-blue-600"
              onClick={handleAddLeague}
            >
              Add
            </button>
            <button 
              type="button"
              className="text-gray-600 dark:text-gray-400 ml-2"
              onClick={() => setShowAddForm(false)}
            >
              Cancel
            </button>
          </div>
        ) : (
          <button 
            type="button"
            className="text-blue-600 dark:text-blue-400 text-sm font-medium mt-1"
            onClick={() => setShowAddForm(true)}
          >
            + Add league
          </button>
        )}
      </div>
    </div>
  );
};

// Define prop types
LeagueManager.propTypes = {
  leagues: PropTypes.array,
  onLeaguesChange: PropTypes.func
};

// Default props
LeagueManager.defaultProps = {
  leagues: [],
  onLeaguesChange: null
};

export default LeagueManager;
