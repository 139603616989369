import React from 'react';
import { TrendingUp, Target, BarChart2 } from 'lucide-react';

const PlayerStatsSummary = ({ playerData }) => {
  // Only show stats that exist in playerData
  const battingStats = {
    average: playerData?.battingStats?.average,
    homeRuns: playerData?.battingStats?.homeRuns,
    rbi: playerData?.battingStats?.rbi,
    hits: playerData?.battingStats?.hits,
    atBats: playerData?.battingStats?.atBats,
    walks: playerData?.battingStats?.walks,
    strikeouts: playerData?.battingStats?.strikeouts
  };

  const pitchingStats = {
    era: playerData?.pitchingStats?.era,
    strikeouts: playerData?.pitchingStats?.strikeouts,
    wins: playerData?.pitchingStats?.wins,
    inningsPitched: playerData?.pitchingStats?.inningsPitched,
    earnedRuns: playerData?.pitchingStats?.earnedRuns
  };

  // Filter out undefined/null values
  const availableBattingStats = Object.entries(battingStats)
    .filter(([_, value]) => value !== undefined && value !== null)
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

  const availablePitchingStats = Object.entries(pitchingStats)
    .filter(([_, value]) => value !== undefined && value !== null)
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

  const formatStatValue = (key, value) => {
    if (key === 'average' || key === 'era') {
      return value.toFixed(3);
    }
    if (key === 'inningsPitched') {
      return value.toFixed(1);
    }
    return value;
  };

  const getStatLabel = (key) => {
    const labels = {
      average: 'Batting Average',
      homeRuns: 'Home Runs',
      rbi: 'RBI',
      hits: 'Hits',
      atBats: 'At Bats',
      walks: 'Walks',
      strikeouts: 'Strikeouts',
      era: 'ERA',
      wins: 'Wins',
      inningsPitched: 'Innings Pitched',
      earnedRuns: 'Earned Runs'
    };
    return labels[key] || key;
  };

  return (
    <div className="bg-white dark:bg-dark-200 p-6 rounded-lg shadow-sm border border-gray-200 dark:border-dark-400">
      <h3 className="font-bold text-lg mb-4 dark:text-darkText-primary flex items-center gap-2">
        <BarChart2 size={20} className="text-blue-600 dark:text-blue-400" />
        Statistics Summary
      </h3>

      {/* Batting Stats */}
      {Object.keys(availableBattingStats).length > 0 && (
        <div className="mb-6">
          <h4 className="font-medium text-blue-800 dark:text-blue-400 mb-3 flex items-center gap-2">
            <Target size={16} />
            Batting Stats
          </h4>
          <div className="space-y-3">
            {Object.entries(availableBattingStats).map(([key, value]) => (
              <div key={key}>
                <div className="flex justify-between text-sm mb-1">
                  <span className="text-gray-600 dark:text-darkText-secondary">
                    {getStatLabel(key)}
                  </span>
                  <span className="font-medium dark:text-darkText-primary">
                    {formatStatValue(key, value)}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Pitching Stats */}
      {Object.keys(availablePitchingStats).length > 0 && (
        <div>
          <h4 className="font-medium text-purple-800 dark:text-purple-400 mb-3 flex items-center gap-2">
            <Target size={16} />
            Pitching Stats
          </h4>
          <div className="space-y-3">
            {Object.entries(availablePitchingStats).map(([key, value]) => (
              <div key={key}>
                <div className="flex justify-between text-sm mb-1">
                  <span className="text-gray-600 dark:text-darkText-secondary">
                    {getStatLabel(key)}
                  </span>
                  <span className="font-medium dark:text-darkText-primary">
                    {formatStatValue(key, value)}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Recent Performance Trends */}
      <div className="mt-6">
        <h4 className="font-medium text-gray-700 dark:text-darkText-secondary mb-3 flex items-center gap-2">
          <TrendingUp size={16} />
          Recent Performance
        </h4>
        <div className="space-y-2">
          {playerData?.recentGames && playerData.recentGames.length > 0 ? (
            playerData.recentGames.slice(0, 3).map((game, index) => (
              <div key={index} className="border border-gray-200 dark:border-dark-400 p-2 rounded text-sm">
                <div className="flex justify-between">
                  <span className="font-medium dark:text-darkText-primary">{game.opponent || 'Opponent'}</span>
                  <span className="text-gray-500 dark:text-darkText-tertiary">{game.date}</span>
                </div>
                <div className="mt-1 text-sm text-gray-600 dark:text-darkText-secondary">
                  {game.battingStats && (
                    <span>Batting: {game.battingStats.hits}-{game.battingStats.atBats} ({game.battingStats.hits/game.battingStats.atBats || 0})</span>
                  )}
                  {game.pitchingStats && (
                    <span className="ml-2">Pitching: {game.pitchingStats.inningsPitched} IP, {game.pitchingStats.strikeouts} K</span>
                  )}
                </div>
              </div>
            ))
          ) : (
            <p className="text-gray-500 dark:text-darkText-tertiary text-sm">No recent games</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlayerStatsSummary; 