import React, { useState } from 'react';
import { usePlayer } from '../../context/PlayerContext';

const QuickEntry = () => {
  const { setShowQuickEntry } = usePlayer();
  const [atBatResult, setAtBatResult] = useState(null);
  const [additionalStats, setAdditionalStats] = useState([]);
  
  const handleAtBatClick = (result) => {
    setAtBatResult(result);
  };
  
  const handleAdditionalStatClick = (stat) => {
    if (additionalStats.includes(stat)) {
      setAdditionalStats(additionalStats.filter(s => s !== stat));
    } else {
      setAdditionalStats([...additionalStats, stat]);
    }
  };
  
  const handleSave = () => {
    // Here we would save the data to our player context
    // This is a simplified implementation
    console.log("Saving at-bat result:", atBatResult);
    console.log("Additional stats:", additionalStats);
    
    // Close the modal
    setShowQuickEntry(false);
  };
  
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-10">
      <div className="bg-white rounded-lg p-4 w-11/12 max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-bold">Quick Entry: vs Eagles</h3>
          <button onClick={() => setShowQuickEntry(false)} className="text-gray-500">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        
        <div className="mb-4">
          <h4 className="font-medium mb-2">At Bat Result</h4>
          <div className="grid grid-cols-4 gap-2">
            <button 
              className={`py-2 px-1 rounded text-sm ${atBatResult === 'single' ? 'bg-green-300' : 'bg-green-100 hover:bg-green-200'}`}
              onClick={() => handleAtBatClick('single')}
            >
              Single
            </button>
            <button 
              className={`py-2 px-1 rounded text-sm ${atBatResult === 'double' ? 'bg-green-300' : 'bg-green-100 hover:bg-green-200'}`}
              onClick={() => handleAtBatClick('double')}
            >
              Double
            </button>
            <button 
              className={`py-2 px-1 rounded text-sm ${atBatResult === 'triple' ? 'bg-green-300' : 'bg-green-100 hover:bg-green-200'}`}
              onClick={() => handleAtBatClick('triple')}
            >
              Triple
            </button>
            <button 
              className={`py-2 px-1 rounded text-sm ${atBatResult === 'hr' ? 'bg-green-300' : 'bg-green-100 hover:bg-green-200'}`}
              onClick={() => handleAtBatClick('hr')}
            >
              HR
            </button>
            <button 
              className={`py-2 px-1 rounded text-sm ${atBatResult === 'walk' ? 'bg-gray-300' : 'bg-gray-100 hover:bg-gray-200'}`}
              onClick={() => handleAtBatClick('walk')}
            >
              Walk
            </button>
            <button 
              className={`py-2 px-1 rounded text-sm ${atBatResult === 'hbp' ? 'bg-gray-300' : 'bg-gray-100 hover:bg-gray-200'}`}
              onClick={() => handleAtBatClick('hbp')}
            >
              HBP
            </button>
            <button 
              className={`py-2 px-1 rounded text-sm ${atBatResult === 'strikeout' ? 'bg-red-300' : 'bg-red-100 hover:bg-red-200'}`}
              onClick={() => handleAtBatClick('strikeout')}
            >
              K
            </button>
            <button 
              className={`py-2 px-1 rounded text-sm ${atBatResult === 'out' ? 'bg-red-300' : 'bg-red-100 hover:bg-red-200'}`}
              onClick={() => handleAtBatClick('out')}
            >
              Out
            </button>
          </div>
        </div>
        
        <div className="mb-4">
          <h4 className="font-medium mb-2">Additional Stats</h4>
          <div className="grid grid-cols-3 gap-2">
            <button 
              className={`py-2 px-1 rounded text-sm ${additionalStats.includes('rbi') ? 'bg-blue-300' : 'bg-blue-100 hover:bg-blue-200'}`}
              onClick={() => handleAdditionalStatClick('rbi')}
            >
              RBI
            </button>
            <button 
              className={`py-2 px-1 rounded text-sm ${additionalStats.includes('run') ? 'bg-blue-300' : 'bg-blue-100 hover:bg-blue-200'}`}
              onClick={() => handleAdditionalStatClick('run')}
            >
              Run
            </button>
            <button 
              className={`py-2 px-1 rounded text-sm ${additionalStats.includes('sb') ? 'bg-blue-300' : 'bg-blue-100 hover:bg-blue-200'}`}
              onClick={() => handleAdditionalStatClick('sb')}
            >
              SB
            </button>
          </div>
        </div>
        
        <div className="flex justify-end">
          <button 
            className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 disabled:bg-blue-300"
            onClick={handleSave}
            disabled={!atBatResult}
          >
            Save & Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuickEntry;