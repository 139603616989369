import React, { useState } from 'react';
import { usePlayer } from '../../context/PlayerContext';
import StatCard from '../shared/StatCard';
import { Clipboard, BarChart3, TrendingUp, TrendingDown, Award, HelpCircle } from 'lucide-react';

const BattingStats = () => {
  const { playerData } = usePlayer();
  const [showTooltip, setShowTooltip] = useState(null);
  
  // Helper to format batting average-type stats
  const formatAvg = (value) => {
    if (value === 0 || isNaN(value)) return '.000';
    return value.toFixed(3).replace(/^0+/, '');
  };
  
  // Tooltip content for stats
  const tooltips = {
    obp: "On-base percentage: (Hits + Walks + HBP) / (At Bats + Walks + HBP)",
    slg: "Slugging percentage: Total bases / At Bats",
    ops: "On-base plus slugging: OBP + SLG",
    hbp: "Hit by pitch: Times hit by a pitched ball"
  };
  
  // Tooltip component
  const Tooltip = ({ id, content }) => (
    <div 
      className="absolute z-10 bg-gray-800 dark:bg-dark-400 text-white p-2 rounded shadow-lg text-xs max-w-xs"
      style={{ bottom: '100%', left: '50%', transform: 'translateX(-50%)', marginBottom: '8px' }}
    >
      {content}
      <div 
        className="absolute w-2 h-2 bg-gray-800 dark:bg-dark-400 transform rotate-45"
        style={{ bottom: '-4px', left: '50%', marginLeft: '-4px' }}
      ></div>
    </div>
  );

  // Utility to calculate mock trend data
  const calculateTrend = (stat) => {
    // In a real app, this would compare recent performance to past averages
    // For demo purposes, we'll generate a random trend between -25 and 25
    const trend = Math.floor(Math.random() * 51) - 25;
    return trend;
  };

  // Trend explanations
  const getTrendExplanation = (stat, trend) => {
    const direction = trend > 0 ? 'up' : 'down';
    const games = '5'; // In a real app, this would be dynamic
    
    switch(stat) {
      case 'avg':
        return `${Math.abs(trend)}% ${direction} from last ${games} games`;
      case 'obp':
        return `On-base percentage is ${direction} ${Math.abs(trend)}% compared to season average`;
      case 'slg':
        return `Slugging percentage is ${direction} ${Math.abs(trend)}% from previous month`;
      case 'ops':
        return `OPS is ${direction} ${Math.abs(trend)}% from your career average`;
      case 'k_rate':
        return `Strikeout rate is ${direction} ${Math.abs(trend)}% from last ${games} games`;
      default:
        return `${direction} ${Math.abs(trend)}% from average`;
    }
  };

  // Determine if a trend is good or bad
  const isTrendPositive = (stat, trend) => {
    // For most batting stats, up is good
    if (stat === 'k_rate') {
      // For strikeout rate, down is good
      return trend < 0;
    }
    // For all other batting stats, up is good
    return trend > 0;
  };

  // Stat item with tooltip support
  const StatItem = ({ label, value, tooltip }) => (
    <div className="relative flex justify-between items-center mb-2 pb-2 border-b border-gray-100 dark:border-dark-300">
      <div className="flex items-center">
        <span>{label}</span>
        {tooltip && (
          <div className="relative ml-1">
            <HelpCircle 
              size={14} 
              className="text-gray-400 dark:text-gray-500 cursor-help" 
              onMouseEnter={() => setShowTooltip(label)} 
              onMouseLeave={() => setShowTooltip(null)} 
            />
            {showTooltip === label && <Tooltip id={label} content={tooltip} />}
          </div>
        )}
      </div>
      <span className="font-semibold">{value}</span>
    </div>
  );

  // Stat item with trend indicator
  const StatItemWithTrend = ({ label, value, tooltip, statKey }) => {
    const trend = calculateTrend(statKey);
    const isPositive = isTrendPositive(statKey, trend);
    const trendExplanation = getTrendExplanation(statKey, trend);

    return (
      <div className="relative flex justify-between items-center mb-2 pb-2 border-b border-gray-100 dark:border-dark-300">
        <div className="flex items-center">
          <span>{label}</span>
          {tooltip && (
            <div className="relative ml-1">
              <HelpCircle 
                size={14} 
                className="text-gray-400 dark:text-gray-500 cursor-help" 
                onMouseEnter={() => setShowTooltip(label)} 
                onMouseLeave={() => setShowTooltip(null)} 
              />
              {showTooltip === label && <Tooltip id={label} content={tooltip} />}
            </div>
          )}
        </div>
        <div className="flex items-center">
          <div 
            className="relative mr-2 cursor-help"
            onMouseEnter={() => setShowTooltip(`trend-${statKey}`)} 
            onMouseLeave={() => setShowTooltip(null)}
          >
            {isPositive ? (
              <TrendingUp size={16} className="text-green-500 dark:text-green-400" />
            ) : (
              <TrendingDown size={16} className="text-red-500 dark:text-red-400" />
            )}
            {showTooltip === `trend-${statKey}` && (
              <Tooltip id={`trend-${statKey}`} content={trendExplanation} />
            )}
          </div>
          <span className="font-semibold">{value}</span>
        </div>
      </div>
    );
  };


  return (
    <div className="mb-6">
      <h2 className="text-xl font-bold mb-4 dark:text-gray-100">Batting Statistics</h2>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        {/* Overview Card */}
        <StatCard
          title="Overview"
          icon={Clipboard}
          className="h-full"
        >
          <div className="space-y-1">
            <StatItem 
              label="Games" 
              value={playerData.battingStats.games} 
            />
            <StatItem 
              label="At Bats (AB)" 
              value={playerData.battingStats.atBats} 
            />
            <StatItem 
              label="Hits (H)" 
              value={playerData.battingStats.hits} 
            />
            <StatItemWithTrend 
              label="Batting Average (AVG)" 
              value={formatAvg(playerData.battingStats.average)} 
              statKey="avg"
            />
          </div>
        </StatCard>
        
        {/* Power Numbers Card */}
        <StatCard
          title="Power Numbers"
          icon={Award}
          className="h-full"
        >
          <div className="space-y-1">
            <StatItem 
              label="Doubles (2B)" 
              value={playerData.battingStats.doubles} 
            />
            <StatItem 
              label="Triples (3B)" 
              value={playerData.battingStats.triples} 
            />
            <StatItem 
              label="Home Runs (HR)" 
              value={playerData.battingStats.homeRuns} 
            />
            <StatItem 
              label="Runs Batted In (RBI)" 
              value={playerData.battingStats.rbi} 
            />
          </div>
        </StatCard>
        
        {/* Advanced Stats Card */}
        <StatCard
          title="Advanced Stats"
          icon={TrendingUp}
          className="h-full"
        >
          <div className="space-y-1">
            <StatItemWithTrend 
              label="On-Base Percentage (OBP)" 
              value={formatAvg(playerData.battingStats.obp)} 
              tooltip={tooltips.obp}
              statKey="obp"
            />
            <StatItemWithTrend 
              label="Slugging (SLG)" 
              value={formatAvg(playerData.battingStats.slg)} 
              tooltip={tooltips.slg}
              statKey="slg"
            />
            <StatItemWithTrend 
              label="OPS" 
              value={formatAvg(playerData.battingStats.ops)} 
              tooltip={tooltips.ops}
              statKey="ops"
            />
          </div>
        </StatCard>
        
        {/* Additional Stats Card */}
        <StatCard
          title="Additional Stats"
          icon={BarChart3}
          className="h-full"
        >
          <div className="space-y-1">
            <StatItemWithTrend 
              label="Strikeouts (K)" 
              value={playerData.battingStats.strikeouts}
              statKey="k_rate" 
            />
            <StatItem 
              label="Hit By Pitch (HBP)" 
              value={playerData.battingStats.hitByPitch} 
              tooltip={tooltips.hbp}
            />
            <StatItem 
              label="Walks (BB)" 
              value={playerData.battingStats.walks} 
            />
          </div>
        </StatCard>
      </div>
    </div>
  );
};

export default BattingStats;