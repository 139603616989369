import React from 'react';
import { TrendingUp, TrendingDown, Minus, Flag, Crosshair } from 'lucide-react';
import StatCard from '../shared/StatCard';
import { usePlayer } from '../../context/PlayerContext';

const MatchupAnalysis = ({ opponent, gameDate }) => {
  const { playerData } = usePlayer();
  
  // In a real app, this would be fetched from your API or context
  // Find games against this opponent from player history
  const getPastMatchups = () => {
    if (!playerData || !playerData.recentGames || !opponent) {
      return [];
    }
    
    // Filter games to find ones against this opponent
    return playerData.recentGames.filter(game => 
      game.opponent && game.opponent.toLowerCase() === opponent.toLowerCase()
    );
  };
  
  const pastMatchups = getPastMatchups();
  // Calculate total stats against this opponent
  const calculateTotals = () => {
    let totals = {
      batting: {
        atBats: 0,
        hits: 0,
        rbi: 0,
        doubles: 0,
        hrs: 0
      },
      pitching: {
        inningsPitched: 0,
        earnedRuns: 0,
        strikeouts: 0,
        wins: 0,
        losses: 0
      }
    };
    
    pastMatchups.forEach(game => {
      // Add batting stats
      if (!game.isPitching && game.battingStats) {
        totals.batting.atBats += game.battingStats.atBats || 0;
        totals.batting.hits += game.battingStats.hits || 0;
        totals.batting.rbi += game.battingStats.rbi || 0;
        totals.batting.doubles += game.battingStats.doubles || 0;
        totals.batting.hrs += game.battingStats.homeRuns || 0;
      }
      
      // Add pitching stats
      if (game.isPitching && game.pitchingStats) {
        totals.pitching.inningsPitched += game.pitchingStats.inningsPitched || 0;
        totals.pitching.earnedRuns += game.pitchingStats.earnedRuns || 0;
        totals.pitching.strikeouts += game.pitchingStats.strikeouts || 0;
        
        if (game.pitchingStats.result === 'W') totals.pitching.wins += 1;
        if (game.pitchingStats.result === 'L') totals.pitching.losses += 1;
      }
    });
    
    // Calculate derived stats
    totals.batting.avg = totals.batting.atBats > 0 
      ? (totals.batting.hits / totals.batting.atBats).toFixed(3).replace(/^0+/, '') 
      : '.000';
      
    totals.pitching.era = totals.pitching.inningsPitched > 0 
      ? ((totals.pitching.earnedRuns * 9) / totals.pitching.inningsPitched).toFixed(2) 
      : '0.00';
      
    return totals;
  };
  
  const totals = calculateTotals();
  
  // Compare against overall season stats to identify strengths/weaknesses
  const identifyInsights = () => {
    const insights = [];
    
    // Check batting average
    if (totals.batting.atBats >= 5) {
      const matchupAvg = parseFloat(totals.batting.avg);
      const seasonAvg = playerData.battingStats.average;
      
      if (matchupAvg > seasonAvg + 0.050) {
        insights.push({
          type: 'strength',
          text: `You're batting ${totals.batting.avg} against ${opponent}, significantly higher than your season average.`,
          icon: <TrendingUp size={16} className="text-green-500" />
        });
      } else if (matchupAvg < seasonAvg - 0.050) {
        insights.push({
          type: 'weakness',
          text: `You're batting ${totals.batting.avg} against ${opponent}, lower than your season average.`,
          icon: <TrendingDown size={16} className="text-red-500" />
        });
      }
    }
    
    // Check home runs
    if (totals.batting.hrs > 0) {
      insights.push({
        type: 'strength',
        text: `You've hit ${totals.batting.hrs} home run(s) against ${opponent} in ${pastMatchups.length} games.`,
        icon: <TrendingUp size={16} className="text-green-500" />
      });
    }
    
    // Check pitching
    if (totals.pitching.inningsPitched > 0) {
      const matchupERA = parseFloat(totals.pitching.era);
      const seasonERA = playerData.pitchingStats.era;
      
      if (matchupERA < seasonERA - 1.00) {
        insights.push({
          type: 'strength',
          text: `Your ERA against ${opponent} is ${totals.pitching.era}, better than your season average.`,
          icon: <TrendingUp size={16} className="text-green-500" />
        });
      } else if (matchupERA > seasonERA + 1.00) {
        insights.push({
          type: 'weakness',
          text: `Your ERA against ${opponent} is ${totals.pitching.era}, higher than your season average.`,
          icon: <TrendingDown size={16} className="text-red-500" />
        });
      }
    }
    
    // If no specific insights, provide a general one
    if (insights.length === 0) {
      if (pastMatchups.length > 0) {
        insights.push({
          type: 'neutral',
          text: `You've played ${pastMatchups.length} games against ${opponent} with mixed results.`,
          icon: <Minus size={16} className="text-gray-500" />
        });
      } else {
        insights.push({
          type: 'neutral',
          text: `This will be your first game against ${opponent}. Good luck!`,
          icon: <Flag size={16} className="text-blue-500" />
        });
      }
    }
    
    return insights;
  };
  
  const matchupInsights = identifyInsights();
  
  // Specific focus areas for the coming game
  const getFocusAreas = () => {
    // This could be more sophisticated with real data
    if (pastMatchups.length === 0) return [];
    
    const focusAreas = [];
    
    // If batting average is low, suggest focus
    if (totals.batting.avg < '.250' && totals.batting.atBats > 5) {
      focusAreas.push({
        title: "Hitting Approach",
        text: `Based on past matchups, try to be more patient at the plate against ${opponent}.`
      });
    }
    
    // If they've pitched against this team
    if (totals.pitching.inningsPitched > 0) {
      focusAreas.push({
        title: "Pitching Strategy",
        text: `You've had success with strikeouts (${totals.pitching.strikeouts} Ks in ${totals.pitching.inningsPitched} IP). Focus on your strikeout pitches.`
      });
    }
    // Add a default focus area if none
    if (focusAreas.length === 0) {
      focusAreas.push({
        title: "General Approach",
        text: `Study ${opponent}'s pitchers before the game.`
      });
    }
    
    return focusAreas;
  };
  
  const focusAreas = getFocusAreas();
  
  return (
    <StatCard className="mt-4">
      <div className="flex justify-between items-center mb-3">
        <h3 className="font-medium flex items-center">
          <Crosshair size={18} className="text-blue-500 mr-2" />
          Matchup History vs {opponent}
        </h3>
      </div>
      
      {pastMatchups.length > 0 ? (
        <>
          <div className="grid grid-cols-2 gap-4 mb-4">
            {totals.batting.atBats > 0 && (
              <div className="bg-gray-50 p-3 rounded">
                <div className="text-sm font-medium mb-1">Batting</div>
                <div className="flex justify-between text-sm">
                  <span>AVG:</span>
                  <span className="font-bold">{totals.batting.avg}</span>
                </div>
                <div className="flex justify-between text-sm">
                  <span>Hits:</span>
                  <span>{totals.batting.hits}/{totals.batting.atBats}</span>
                </div>
                <div className="flex justify-between text-sm">
                  <span>RBI:</span>
                  <span>{totals.batting.rbi}</span>
                </div>
                <div className="flex justify-between text-sm">
                  <span>XBH:</span>
                  <span>{totals.batting.doubles + totals.batting.hrs}</span>
                </div>
              </div>
            )}
            
            {totals.pitching.inningsPitched > 0 && (
              <div className="bg-gray-50 p-3 rounded">
                <div className="text-sm font-medium mb-1">Pitching</div>
                <div className="flex justify-between text-sm">
                  <span>ERA:</span>
                  <span className="font-bold">{totals.pitching.era}</span>
                </div>
                <div className="flex justify-between text-sm">
                  <span>Record:</span>
                  <span>{totals.pitching.wins}-{totals.pitching.losses}</span>
                </div>
                <div className="flex justify-between text-sm">
                  <span>IP:</span>
                  <span>{totals.pitching.inningsPitched}</span>
                </div>
                <div className="flex justify-between text-sm">
                  <span>K:</span>
                  <span>{totals.pitching.strikeouts}</span>
                </div>
              </div>
            )}
          </div>
          
          <div className="mb-4">
            <div className="text-sm font-medium mb-2">Past Games vs {opponent}</div>
            <div className="space-y-2">
              {pastMatchups.map((game, idx) => (
                <div key={idx} className="bg-white border border-gray-200 p-2 rounded text-sm">
                  <div className="flex justify-between mb-1">
                    <span className="text-gray-600">{game.date}</span>
                    {game.isPitching && game.pitchingStats?.result && (
                      <span className={`px-1.5 rounded text-xs ${game.pitchingStats.result === 'W' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                        {game.pitchingStats.result}
                      </span>
                    )}
                  </div>
                  {!game.isPitching && game.battingStats && (
                    <div className="text-xs">
                      Batting: {game.battingStats.hits}-for-{game.battingStats.atBats}
                      {game.battingStats.rbi > 0 && `, ${game.battingStats.rbi} RBI`}
                      {game.battingStats.homeRuns > 0 && `, ${game.battingStats.homeRuns} HR`}
                    </div>
                  )}
                  {game.isPitching && game.pitchingStats && (
                    <div className="text-xs">
                      Pitching: {game.pitchingStats.inningsPitched} IP, {game.pitchingStats.earnedRuns} ER, {game.pitchingStats.strikeouts} K
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <div className="text-center py-4 mb-4 bg-gray-50 rounded text-gray-500">
          No previous games against {opponent}
        </div>
      )}
      
      <div className="space-y-3">
        <div className="text-sm font-medium">Key Insights</div>
        {matchupInsights.map((insight, idx) => (
          <div key={idx} className={`p-2 rounded-lg text-sm flex items-start ${
            insight.type === 'strength' ? 'bg-green-50 text-green-800' :
            insight.type === 'weakness' ? 'bg-red-50 text-red-800' :
            'bg-blue-50 text-blue-800'
          }`}>
            <div className="mr-2 mt-0.5">{insight.icon}</div>
            <div>{insight.text}</div>
          </div>
        ))}
        
        {focusAreas.length > 0 && (
          <>
            <div className="text-sm font-medium mt-2">Focus Areas for This Game</div>
            {focusAreas.map((area, idx) => (
              <div key={idx} className="bg-yellow-50 p-2 rounded-lg text-sm">
                <div className="font-medium text-yellow-800">{area.title}</div>
                <div className="text-yellow-700">{area.text}</div>
              </div>
            ))}
          </>
        )}
      </div>
    </StatCard>
  );
};

export default MatchupAnalysis;