import React, { useState, useEffect } from 'react';
import { TrendingDown, TrendingUp, AlertTriangle, CheckCircle, HelpCircle } from 'lucide-react';
import StatCard from '../shared/StatCard';
import { detectSlump } from '../../utils/advancedAnalytics';
import { usePlayer } from '../../context/PlayerContext';

const SlumpDetection = ({ className }) => {
  const { playerData } = usePlayer();
  const [slumpAnalysis, setSlumpAnalysis] = useState(null);
  const [expanded, setExpanded] = useState(false);
  
  useEffect(() => {
    // In a real implementation, we would use actual game-by-game data
    // For demonstration, we'll generate mock game data
    const generateMockGameStats = () => {
      // Create 20 games of mock data
      const games = [];
      let currentSlump = false;
      let slumpStartIndex = Math.floor(Math.random() * 10) + 5; // Start slump somewhere in the middle
      
      for (let i = 0; i < 20; i++) {
        // If in slump period (5 games), reduce batting average
        const inSlump = i >= slumpStartIndex && i < slumpStartIndex + 5;
        
        const atBats = Math.floor(Math.random() * 3) + 2; // 2-4 at bats
        
        // Determine hits based on whether in slump or not
        let hits;
        if (inSlump) {
          // During slump, lower hit probability
          hits = Math.random() < 0.2 ? 1 : 0;
        } else {
          // Normal performance
          const hitProbability = Math.random();
          hits = hitProbability > 0.7 ? 2 : hitProbability > 0.4 ? 1 : 0;
        }
        
        games.push({
          gameId: i + 1,
          date: new Date(2025, 3, i + 1).toISOString(), // April 2025
          opponent: `Team ${i % 10 + 1}`,
          atBats,
          hits,
          walks: Math.floor(Math.random() * 2),
          strikeouts: inSlump ? Math.floor(Math.random() * 2) + 1 : Math.floor(Math.random() * 2),
          hardHitBalls: inSlump ? 0 : Math.floor(Math.random() * 2)
        });
      }
      
      return games;
    };
    
    // Generate mock game stats and analyze for slumps
    const mockGameStats = generateMockGameStats();
    const analysis = detectSlump(mockGameStats, {
      slumpThreshold: 0.150,
      hotStreakThreshold: 0.350,
      minGames: 3,
      recentGamesWindow: 10
    });
    
    setSlumpAnalysis(analysis);
  }, []);
  
  // If analysis is still loading
  if (!slumpAnalysis) {
    return (
      <StatCard className={className}>
        <div className="flex justify-between items-center mb-3">
          <h3 className="font-medium">Performance Analysis</h3>
        </div>
        <div className="h-24 flex items-center justify-center bg-gray-50 animate-pulse">
          <div className="text-gray-400">Analyzing performance trends...</div>
        </div>
      </StatCard>
    );
  }
  
  // Determine status icon and color based on analysis
  const getStatusDisplay = () => {
    if (slumpAnalysis.isSlumping) {
      return {
        icon: <TrendingDown size={20} className="text-red-500" />,
        color: 'red',
        text: 'Slumping',
        bgClass: 'bg-red-50'
      };
    } else if (slumpAnalysis.isHot) {
      return {
        icon: <TrendingUp size={20} className="text-green-500" />,
        color: 'green',
        text: 'Hot Streak',
        bgClass: 'bg-green-50'
      };
    } else {
      return {
        icon: <HelpCircle size={20} className="text-blue-500" />,
        color: 'blue',
        text: 'Performing as Expected',
        bgClass: 'bg-blue-50'
      };
    }
  };
  
  const statusDisplay = getStatusDisplay();

  return (
    <StatCard className={className}>
      <div className="flex justify-between items-center mb-3">
        <h3 className="font-medium">Performance Analysis</h3>
        <button 
          className="text-blue-600 text-sm"
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? 'Show Less' : 'Show More'}
        </button>
      </div>
      
      <div className={`p-3 rounded-lg ${statusDisplay.bgClass} flex items-center mb-3`}>
        <div className="mr-3">
          {statusDisplay.icon}
        </div>
        <div>
          <div className={`font-bold text-${statusDisplay.color}-700`}>
            {statusDisplay.text}
          </div>
          <div className="text-sm text-gray-600">
            Recent AVG: {slumpAnalysis.recentAvg}
            {slumpAnalysis.isSlumping && ` (${slumpAnalysis.slumpDuration} games)`}
            {slumpAnalysis.isHot && ` (${slumpAnalysis.hotStreakDuration} games)`}
          </div>
        </div>
      </div>
      
      {slumpAnalysis.isSlumping && (
        <div className="space-y-3">
          {slumpAnalysis.slumpDetails && (
            <div className="border-l-4 border-red-500 pl-3 py-1">
              <div className="font-medium">Slump Analysis</div>
              <div className="flex flex-wrap gap-2 mt-2">
                <div className="bg-gray-100 p-1 rounded text-xs">
                  <span className="font-semibold">K Rate:</span> {slumpAnalysis.slumpDetails.strikeoutRate}
                </div>
                <div className="bg-gray-100 p-1 rounded text-xs">
                  <span className="font-semibold">BB Rate:</span> {slumpAnalysis.slumpDetails.walkRate}
                </div>
                <div className="bg-gray-100 p-1 rounded text-xs">
                  <span className="font-semibold">Hard Hit:</span> {slumpAnalysis.slumpDetails.hardHitRate}
                </div>
              </div>
              
              {expanded && slumpAnalysis.slumpDetails.potentialCauses.length > 0 && (
                <div className="mt-2">
                  <div className="text-sm font-medium">Potential Causes:</div>
                  <ul className="mt-1 space-y-1">
                    {slumpAnalysis.slumpDetails.potentialCauses.map((cause, index) => (
                      <li key={index} className="flex items-start text-xs">
                        <AlertTriangle size={12} className="text-red-500 mr-1 mt-0.5" />
                        <span>{cause.issue} ({cause.factor})</span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          )}
          
          {expanded && slumpAnalysis.recommendations && slumpAnalysis.recommendations.length > 0 && (
            <div>
              <div className="text-sm font-medium mb-2">Recommendations:</div>
              <ul className="space-y-2">
                {slumpAnalysis.recommendations.map((recommendation, index) => (
                  <li key={index} className="flex items-start text-sm">
                    <CheckCircle size={16} className="text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>{recommendation}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
      
      {/* Hot streak content */}
      {slumpAnalysis.isHot && (
        <div className="border-l-4 border-green-500 pl-3 py-1">
          <div className="font-medium">Hot Streak!</div>
          <div className="text-sm mt-1">
            You're on a {slumpAnalysis.hotStreakDuration}-game hot streak with a {slumpAnalysis.recentAvg} average.
          </div>
          
          {expanded && (
            <div className="mt-2 text-sm">
              <div className="font-medium">Key to Success:</div>
              <ul className="mt-1 space-y-1">
                <li className="flex items-start">
                  <CheckCircle size={12} className="text-green-500 mr-1 mt-0.5" />
                  <span>Maintaining consistent swing mechanics</span>
                </li>
                <li className="flex items-start">
                  <CheckCircle size={12} className="text-green-500 mr-1 mt-0.5" />
                  <span>Good pitch selection and patience</span>
                </li>
                <li className="flex items-start">
                  <CheckCircle size={12} className="text-green-500 mr-1 mt-0.5" />
                  <span>Solid preparation and game planning</span>
                </li>
              </ul>
            </div>
          )}
        </div>
      )}
      
      {/* Normal performance content */}
      {!slumpAnalysis.isSlumping && !slumpAnalysis.isHot && (
        <div className="text-sm">
          <p>Your recent performance is consistent with your overall stats.</p>
          {expanded && (
            <div className="mt-2">
              <div className="font-medium">Focus Areas:</div>
              <ul className="mt-1 space-y-1">
                <li className="flex items-start">
                  <CheckCircle size={12} className="text-blue-500 mr-1 mt-0.5" />
                  <span>Continue working on consistent hitting approach</span>
                </li>
                <li className="flex items-start">
                  <CheckCircle size={12} className="text-blue-500 mr-1 mt-0.5" />
                  <span>Maintain regular batting practice routine</span>
                </li>
              </ul>
            </div>
          )}
        </div>
      )}
    </StatCard>
  );
};

export default SlumpDetection;