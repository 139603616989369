import React from 'react';
import { TrendingUp, Clipboard, Calendar, User, CalendarDays } from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';

const Navigation = ({ activeTab, setActiveTab }) => {
  const navigate = useNavigate();
  const location = useLocation();
  
  // Determine active tab based on current path
  const currentPath = location.pathname;
  
  // Navigation items data for cleaner rendering with route paths
  const navItems = [
    { id: 'dashboard', label: 'Dashboard', icon: TrendingUp, path: '/' },
    { id: 'stats', label: 'Stats', icon: Clipboard, path: '/stats' },
    { id: 'schedule', label: 'Schedule', icon: CalendarDays, path: '/schedule' },
    { id: 'add-game', label: 'Add Game', icon: Calendar, path: '/add-game' },
    { id: 'profile', label: 'Profile', icon: User, path: '/profile' },
  ];

  return (
    <nav 
      className="fixed bottom-0 w-full bg-white dark:bg-dark-200 border-t border-gray-200 dark:border-dark-300 dark:shadow-dark-100/20 shadow-lg pb-safe-bottom" 
      style={{ paddingBottom: 'env(safe-area-inset-bottom, 0px)' }}
      role="navigation"
      aria-label="Main Navigation"
    >
      <div className="grid grid-cols-5 h-16">
        {navItems.map((item) => {
          // Check if this tab is active based on current path
          const isActive = item.path === '/' 
            ? currentPath === '/' 
            : currentPath.startsWith(item.path);
            
          const Icon = item.icon;
          
          return (
            <button 
              key={item.id}
              className={`
                relative flex flex-col items-center justify-center 
                transition-colors duration-200 ease-in-out
                ${isActive 
                  ? 'text-blue-600 dark:text-primary-light' 
                  : 'text-gray-500 dark:text-darkText-tertiary hover:text-blue-500 dark:hover:text-darkText-secondary'
                }
                active:bg-gray-100 hover:bg-gray-50 dark:hover:bg-dark-300 dark:active:bg-dark-400
              `}
              onClick={() => {
                // Update the active tab state for animation purposes
                setActiveTab(item.id);
                // Navigate to the route
                navigate(item.path);
              }}
              aria-current={isActive ? 'page' : undefined}
              role="tab"
              aria-selected={isActive}
            >
              <Icon size={20} aria-hidden="true" />
              
              {/* Active indicator dot */}
              {isActive && (
                <span className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1.5 h-1.5 bg-blue-600 dark:bg-primary-light rounded-full" />
              )}
              
              <span className={`text-xs mt-1 ${isActive ? 'font-semibold dark:text-darkText-primary' : 'font-medium dark:text-darkText-secondary'}`}>
                {item.label}
              </span>
            </button>
          );
        })}
      </div>
    </nav>
  );
};

// Add additional CSS for smooth transitions and effects
// You might want to add this to your global CSS file
const navStyles = `
  /* Add safe area support for iOS devices */
  .pb-safe-bottom {
    padding-bottom: env(safe-area-inset-bottom, 0px);
  }
  
  /* Smooth transition for all interactive elements */
  nav button {
    -webkit-tap-highlight-color: transparent;
    touch-action: manipulation;
  }
`;

// Create a style element for the custom CSS if needed
// This is optional if you prefer to add these styles to your global CSS
if (typeof document !== 'undefined') {
  const style = document.createElement('style');
  style.textContent = navStyles;
  document.head.appendChild(style);
}

export default Navigation;
