/**
 * Format innings pitched in baseball notation (where .1 = ⅓ and .2 = ⅔)
 * @param {number} innings - Number of innings pitched
 * @returns {string} - Formatted innings value
 */
export const formatInnings = (innings) => {
    if (typeof innings === 'number') {
      const wholePart = Math.floor(innings);
      const decimalPart = innings - wholePart;
      
      // Convert baseball's .1 and .2 innings notation (where .1 = ⅓ and .2 = ⅔)
      if (decimalPart === 0) return wholePart.toString();
      const fraction = decimalPart === 0.1 ? '⅓' : decimalPart === 0.2 ? '⅔' : '';
      return `${wholePart}${fraction}`;
    }
    return innings;
  };
  
  /**
   * Format a numerical value as a baseball statistic
   * @param {number} value - The value to format
   * @param {number} precision - Number of decimal places
   * @param {boolean} isPercentage - Whether this is a percentage value
   * @returns {string} - Formatted statistic
   */
  export const formatStat = (value, precision = 3, isPercentage = false) => {
    if (value === undefined || value === null) return '-';
    
    const formatted = value.toFixed(precision).replace(/^0+/, '');
    return isPercentage ? `${formatted}%` : formatted;
  };
  
  /**
   * Format a date for display
   * @param {Date|string} date - Date to format
   * @returns {string} - Formatted date
   */
  export const formatDate = (date) => {
    if (!date) return '';
    
    const dateObj = typeof date === 'string' ? new Date(date) : date;
    return dateObj.toLocaleDateString('en-US', { 
      month: 'short', 
      day: 'numeric', 
      year: 'numeric' 
    });
  };