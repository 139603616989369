import React from 'react';
import { ChevronLeft, Menu } from 'lucide-react';
import ThemeToggle from './ThemeToggle';

/**
 * Enhanced Header component with mobile-friendly features
 * 
 * @param {Object} props - Component props
 * @param {string} props.title - Main title to display
 * @param {string} [props.subtitle] - Optional subtitle or section description
 * @param {boolean} [props.showBack=false] - Whether to show back button
 * @param {Function} [props.onBack] - Callback when back button is pressed
 * @param {Array} [props.actions] - Array of action button configs
 * @param {string} [props.bgColor='bg-blue-600'] - Background color class
 * @param {boolean} [props.elevated=true] - Whether to add shadow for elevation
 */
const Header = ({ 
  title = 'Baseball Stats Tracker',
  subtitle,
  showBack = false,
  onBack,
  actions = [],
  bgColor = 'bg-blue-600',
  elevated = true
}) => {
  // Handle back button click
  const handleBackClick = () => {
    if (onBack) {
      onBack();
    } else {
      // Default behavior if no onBack handler provided
      window.history.back();
    }
  };

  return (
    <header 
      className={`${bgColor} text-white pt-safe-top ${elevated ? 'shadow-md' : ''} dark:bg-dark-200 dark:text-darkText-primary dark:shadow-dark-100/50 transition-colors duration-200`}
      style={{ 
        paddingTop: 'env(safe-area-inset-top, 0px)',
      }}
    >
      <div className="px-4 py-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            {showBack && (
              <button 
                onClick={handleBackClick}
                className="mr-2 p-1 rounded-full hover:bg-white/10 active:bg-white/20 dark:hover:bg-dark-400/70 dark:active:bg-dark-400 transition-colors"
                aria-label="Go back"
              >
                <ChevronLeft size={24} />
              </button>
            )}
            <h1 className="text-lg sm:text-xl font-bold truncate">{title}</h1>
          </div>

          {/* Action buttons */}
          {actions.length > 0 && (
            <div className="flex items-center space-x-1">
              {actions.map((action, index) => {
                // If a custom component is provided, render it directly
                if (action.component) {
                  const CustomComponent = action.component;
                  return (
                    <CustomComponent 
                      key={index} 
                      className="hover:bg-white/10 active:bg-white/20 dark:hover:bg-dark-400/70 dark:active:bg-dark-400" 
                      aria-label={action.label || 'Action'} 
                    />
                  );
                }
                
                // Otherwise render a standard icon button
                const Icon = action.icon || Menu;
                return (
                  <button
                    key={index}
                    onClick={action.onClick}
                    className="p-2 rounded-full hover:bg-white/10 active:bg-white/20 dark:hover:bg-dark-400/70 dark:active:bg-dark-400 transition-colors"
                    aria-label={action.label || `${action.icon ? action.icon.name : 'Action'} button`}
                  >
                    <Icon size={20} />
                  </button>
                );
              })}
            </div>
          )}
        </div>

        {/* Optional subtitle */}
        {subtitle && (
          <p className="text-sm text-white/80 dark:text-darkText-secondary mt-1 mb-1">{subtitle}</p>
        )}
      </div>
    </header>
  );
};

// Example usage:
// <Header 
//   title="Dashboard" 
//   subtitle="View your recent stats"
//   showBack={true}
//   actions={[
//     { icon: Menu, onClick: () => console.log('Menu'), label: 'Menu' },
//     { component: ThemeToggle, label: 'Toggle Theme' } // Custom component
//   ]}
// />

export default Header;

// Add to your global CSS or tailwind.config.js
// pt-safe-top can be configured in your tailwind config
// utilities: {
//   '.pt-safe-top': {
//     paddingTop: 'env(safe-area-inset-top, 0px)'
//   }
// }
