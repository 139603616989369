import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { User, Hash, ChevronRight, AlertCircle, CheckCircle } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { createPlayer } from '../../firebase/firestore';

// List of baseball positions
const POSITIONS = [
  'Pitcher',
  'Catcher',
  'First Base',
  'Second Base',
  'Third Base',
  'Shortstop',
  'Left Field',
  'Center Field',
  'Right Field',
  'Designated Hitter',
  'Utility',
];

// Common baseball leagues
const LEAGUES = [
  'Little League',
  'High School',
  'College',
  'Adult League',
  'Senior League',
  'Travel Ball',
  'Recreation League',
  'Professional'
];

const PlayerSetup = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  
  // Log component mounting
  React.useEffect(() => {
    console.log('PlayerSetup component mounted');
    console.log('Current user:', currentUser);
    
    // Cleanup on unmount
    return () => {
      console.log('PlayerSetup component unmounting');
    };
  }, [currentUser]);
  
  // Form state
  const [name, setName] = useState(currentUser?.displayName || '');
  const [number, setNumber] = useState('');
  const [position, setPosition] = useState('');
  const [selectedLeagues, setSelectedLeagues] = useState([]);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  
  // Handle league selection toggle
  const toggleLeague = (league) => {
    if (selectedLeagues.includes(league)) {
      setSelectedLeagues(selectedLeagues.filter(l => l !== league));
    } else {
      setSelectedLeagues([...selectedLeagues, league]);
    }
  };
  
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    
    try {
      if (!currentUser) {
        throw new Error('You must be logged in to create a player profile');
      }
      
      if (!name.trim()) {
        throw new Error('Please enter your name');
      }
      
      // Create player profile in Firestore
      const playerData = {
        name,
        number: number || '0',
        position: position || 'Utility',
        leagues: selectedLeagues.length > 0 ? selectedLeagues : ['Recreation League'],
      };
      
      console.log('Creating player profile with data:', playerData);
      
      try {
        // Create the player document
        const playerId = await createPlayer(currentUser.uid, playerData);
        console.log('Player created successfully with ID:', playerId);
        
        // Set success state to show success message
        setSuccess(true);
        
        // Store a flag in localStorage to force refresh player state
        localStorage.setItem('newPlayerCreated', 'true');
        localStorage.setItem('playerCreatedAt', new Date().toISOString());
        
        // Use a more aggressive approach to navigate - full page reload
        setTimeout(() => {
          console.log('Forcing full page reload to refresh app state...');
          
          // Option 1: Simple redirect to root
          window.location.href = '/';
          
          // Fallback - if navigation doesn't happen after 500ms, force reload
          setTimeout(() => {
            console.log('Fallback: forcing page reload...');
            window.location.reload();
          }, 500);
        }, 1500);
      } catch (firestoreError) {
        console.error('Error creating player in Firestore:', firestoreError);
        throw new Error(`Failed to create player: ${firestoreError.message}`);
      }
    } catch (err) {
      console.error('Player setup error:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  
  // Handle continuing to next step
  const handleNextStep = (e) => {
    e.preventDefault();
    
    if (step === 1 && !name.trim()) {
      setError('Please enter your name');
      return;
    }
    
    setError('');
    setStep(step + 1);
  };
  
  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      {/* Header */}
      <header className="bg-blue-600 text-white py-6 px-4 text-center">
        <h1 className="text-2xl font-bold">Set Up Your Player Profile</h1>
        <p className="text-sm mt-2 opacity-80">Tell us about yourself to get started</p>
      </header>
      
      <main className="flex-grow flex flex-col items-center justify-center p-6">
        <div className="bg-white rounded-lg shadow-md p-6 w-full max-w-md">
          {/* Step indicator */}
          <div className="flex justify-between items-center mb-6">
            <div className="w-1/3 text-center">
              <div className={`rounded-full h-8 w-8 flex items-center justify-center mx-auto mb-1 
                ${step >= 1 ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-500'}`}>
                1
              </div>
              <span className="text-xs text-gray-500">Basic Info</span>
            </div>
            <div className="w-1/3 text-center">
              <div className={`rounded-full h-8 w-8 flex items-center justify-center mx-auto mb-1 
                ${step >= 2 ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-500'}`}>
                2
              </div>
              <span className="text-xs text-gray-500">Position</span>
            </div>
            <div className="w-1/3 text-center">
              <div className={`rounded-full h-8 w-8 flex items-center justify-center mx-auto mb-1 
                ${step >= 3 ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-500'}`}>
                3
              </div>
              <span className="text-xs text-gray-500">Leagues</span>
            </div>
          </div>
          
          {/* Success message */}
          {success && (
            <div className="mb-4 p-4 bg-green-50 border border-green-200 rounded-md flex items-center">
              <CheckCircle size={20} className="text-green-500 mr-2 flex-shrink-0" />
              <div>
                <p className="text-green-700 font-medium">Profile created!</p>
                <p className="text-green-600 text-sm">Redirecting to dashboard...</p>
              </div>
            </div>
          )}
          
          {/* Error message */}
          {error && (
            <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded-md flex items-start">
              <AlertCircle size={18} className="text-red-500 mr-2 mt-0.5 flex-shrink-0" />
              <p className="text-red-600 text-sm">{error}</p>
            </div>
          )}
          
          <form onSubmit={handleSubmit}>
            {/* Step 1: Basic Info */}
            {step === 1 && (
              <div className="space-y-4">
                <h2 className="text-lg font-medium text-gray-800 mb-4">Basic Information</h2>
                
                {/* Name Field */}
                <div>
                  <label htmlFor="playerName" className="block text-sm font-medium text-gray-700 mb-1">Name</label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <User size={18} className="text-gray-400" />
                    </div>
                    <input
                      id="playerName"
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="pl-10 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                      placeholder="Your name"
                      required
                    />
                  </div>
                </div>
                
                {/* Jersey Number */}
                <div>
                  <label htmlFor="jerseyNumber" className="block text-sm font-medium text-gray-700 mb-1">Jersey Number</label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <Hash size={18} className="text-gray-400" />
                    </div>
                    <input
                      id="jerseyNumber"
                      type="text"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      value={number}
                      onChange={(e) => setNumber(e.target.value.replace(/\D/g, ''))}
                      className="pl-10 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                      placeholder="Jersey number (optional)"
                    />
                  </div>
                </div>
                
                <button
                  type="button"
                  onClick={handleNextStep}
                  className="w-full flex items-center justify-center bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Continue <ChevronRight size={18} className="ml-1" />
                </button>
              </div>
            )}
            
            {/* Step 2: Position */}
            {step === 2 && (
              <div className="space-y-4">
                <h2 className="text-lg font-medium text-gray-800 mb-4">Primary Position</h2>
                
                <div className="grid grid-cols-2 gap-2">
                  {POSITIONS.map((pos) => (
                    <div key={pos} className="flex items-center">
                      <input
                        id={`position-${pos}`}
                        type="radio"
                        name="position"
                        value={pos}
                        checked={position === pos}
                        onChange={() => setPosition(pos)}
                        className="h-4 w-4 text-blue-600 focus:ring-blue-500"
                      />
                      <label 
                        htmlFor={`position-${pos}`}
                        className="ml-2 block text-sm text-gray-700 cursor-pointer"
                      >
                        {pos}
                      </label>
                    </div>
                  ))}
                </div>
                
                <div className="flex justify-between pt-4">
                  <button
                    type="button"
                    onClick={() => setStep(1)}
                    className="text-blue-600 hover:text-blue-800"
                  >
                    Back
                  </button>
                  
                  <button
                    type="button"
                    onClick={handleNextStep}
                    className="flex items-center justify-center bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  >
                    Continue <ChevronRight size={18} className="ml-1" />
                  </button>
                </div>
              </div>
            )}
            
            {/* Step 3: Leagues */}
            {step === 3 && (
              <div className="space-y-4">
                <h2 className="text-lg font-medium text-gray-800 mb-4">Leagues</h2>
                <p className="text-sm text-gray-600 mb-3">Select the leagues you play in (optional)</p>
                
                <div className="grid grid-cols-2 gap-2">
                  {LEAGUES.map((league) => (
                    <div key={league} className="flex items-center">
                      <input
                        id={`league-${league}`}
                        type="checkbox"
                        checked={selectedLeagues.includes(league)}
                        onChange={() => toggleLeague(league)}
                        className="h-4 w-4 text-blue-600 focus:ring-blue-500"
                      />
                      <label 
                        htmlFor={`league-${league}`}
                        className="ml-2 block text-sm text-gray-700 cursor-pointer"
                      >
                        {league}
                      </label>
                    </div>
                  ))}
                </div>
                
                <div className="flex justify-between pt-4">
                  <button
                    type="button"
                    onClick={() => setStep(2)}
                    className="text-blue-600 hover:text-blue-800"
                  >
                    Back
                  </button>
                  
                  <button
                    type="submit"
                    disabled={loading || success}
                    className={`w-32 flex items-center justify-center bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2
                      ${(loading || success) ? 'opacity-70 cursor-not-allowed' : ''}`}
                  >
                    {loading ? 'Creating...' : success ? 'Created!' : 'Create Profile'}
                  </button>
                </div>
              </div>
            )}
          </form>
        </div>
      </main>
      
      {/* Footer */}
      <footer className="bg-white py-4 px-6 border-t text-center text-gray-500 text-sm">
        &copy; {new Date().getFullYear()} Baseball Stat Tracker. All rights reserved.
      </footer>
    </div>
  );
};

export default PlayerSetup;

