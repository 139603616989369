import React from 'react';
import { ArrowUp, ArrowDown, TrendingUp, TrendingDown } from 'lucide-react';

/**
 * Enhanced StatCard component for displaying statistics with various options
 *
 * @param {Object} props
 * @param {string} [props.title] - Card title
 * @param {React.ReactNode} [props.children] - Card content
 * @param {string} [props.className] - Additional CSS classes
 * @param {string} [props.size='normal'] - Card size (compact, normal, large)
 * @param {boolean} [props.loading=false] - Show loading skeleton
 * @param {React.ReactNode} [props.icon] - Optional icon component
 * @param {boolean} [props.interactive=false] - Whether card has interactive behavior
 * @param {Function} [props.onClick] - Click handler for interactive cards
 * @param {number} [props.trend] - Trend value (positive, negative or neutral)
 * @param {string} [props.trendLabel] - Label describing the trend
 * @param {string} [props.bgColor] - Background color class
 * @param {string} [props.gradient] - CSS gradient class
 * @param {React.ReactNode} [props.footer] - Optional footer content
 * @param {string} [props.value] - Main statistic value
 * @param {string} [props.unit] - Unit for the value (%, avg, etc)
 */
const StatCard = ({ 
  title,
  children,
  className = '',
  size = 'normal',
  loading = false,
  icon: Icon,
  interactive = false,
  onClick,
  trend,
  trendLabel,
  bgColor = 'bg-white',
  gradient = '',
  footer,
  value,
  unit
}) => {
  // Determine size-related classes
  const sizeClasses = {
    compact: 'p-2 text-sm',
    normal: 'p-4',
    large: 'p-5 text-lg'
  }[size] || 'p-4';

  // Determine title size based on card size
  const titleSizeClasses = {
    compact: 'text-base',
    normal: 'text-lg',
    large: 'text-xl'
  }[size] || 'text-lg';

  // Calculate trend display
  const trendDisplay = trend !== undefined && (
    <div className={`flex items-center ${trend > 0 ? 'text-green-500 dark:text-green-400' : trend < 0 ? 'text-red-500 dark:text-red-400' : 'text-gray-500 dark:text-gray-400'}`}>
      {trend > 0 ? (
        <ArrowUp size={size === 'compact' ? 14 : 16} strokeWidth={2.5} />
      ) : trend < 0 ? (
        <ArrowDown size={size === 'compact' ? 14 : 16} strokeWidth={2.5} />
      ) : null}
      <span className="ml-1">{Math.abs(trend)}%</span>
      {trendLabel && <span className="ml-1 text-xs text-gray-500 dark:text-gray-400">{trendLabel}</span>}
    </div>
  );

  // Interactive card props
  const interactiveProps = interactive ? {
    onClick,
    role: 'button',
    tabIndex: 0,
    'aria-pressed': false,
    onKeyDown: (e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault();
        onClick && onClick(e);
      }
    }
  } : {};

  // Accessibility attributes
  const ariaProps = {
    role: title ? 'region' : undefined,
    'aria-label': title || 'Statistics card'
  };

  // Combined classNames
  const cardClasses = `
    ${sizeClasses} 
    ${bgColor} 
    ${gradient}
    rounded-lg border border-gray-200 dark:border-dark-300 shadow-sm dark:shadow-dark-400/10
    dark:bg-dark-200 dark:text-gray-100
    ${interactive ? 'cursor-pointer transition-transform hover:shadow-md dark:hover:shadow-dark-400/20 active:scale-95' : ''}
    ${className}
  `;

  // Loading skeleton
  if (loading) {
    return (
      <div className={`${cardClasses} animate-pulse`} {...ariaProps}>
        <div className="flex space-x-3">
          {Icon && <div className="rounded-full bg-gray-200 dark:bg-dark-300 h-8 w-8"></div>}
          <div className="flex-1 space-y-3">
            <div className="h-4 bg-gray-200 dark:bg-dark-300 rounded w-3/4"></div>
            <div className="h-8 bg-gray-200 dark:bg-dark-300 rounded w-1/2"></div>
            <div className="h-3 bg-gray-200 dark:bg-dark-300 rounded w-5/6"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div 
      className={cardClasses}
      {...interactiveProps}
      {...ariaProps}
    >
      <div className="flex items-start justify-between">
        {/* Title with optional icon */}
        <div className="flex items-center">
          {Icon && (
            <div className="mr-3">
              <Icon size={size === 'large' ? 24 : 20} className="text-blue-500 dark:text-blue-400" aria-hidden="true" />
            </div>
          )}
          {title && <h3 className={`${titleSizeClasses} font-medium ${Icon ? 'mb-2' : 'mb-3'}`}>{title}</h3>}
        </div>

        {/* Trend indicator (if provided) */}
        {trendDisplay}
      </div>

      {/* Main value display (if provided) */}
      {value && (
        <div className="mt-2 mb-3">
          <div className="flex items-baseline">
            <span className={`${size === 'large' ? 'text-3xl' : size === 'compact' ? 'text-xl' : 'text-2xl'} font-bold dark:text-white`}>
              {value}
            </span>
            {unit && <span className="ml-1 text-gray-500 dark:text-gray-400 text-sm">{unit}</span>}
          </div>
        </div>
      )}

      {/* Main content */}
      <div className="mt-1">
        {children}
      </div>

      {/* Optional footer */}
      {/* Optional footer */}
      {footer && (
        <div className="mt-4 pt-3 border-t border-gray-100 dark:border-dark-300 text-sm text-gray-500 dark:text-gray-400">
        </div>
      )}
    </div>
  );
};

export default StatCard;
