import React, { useState, useEffect, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
// Lucide icons
import { 
  Activity,
  AlertTriangle,
  Award,
  BarChart3, 
  Calendar, 
  ChevronDown,
  ChevronUp,
  Clipboard, 
  PlusCircle, 
  RefreshCw, 
  Settings,
  TrendingUp, 
  User
} from 'lucide-react';

// Dashboard components
import MilestonesCard from './MilestonesCard';
import PerformanceTrends from './PerformanceTrends';
import QuickEntry from './QuickEntry';
import RecentGames from './RecentGames';
import SlumpDetection from './SlumpDetection';

// Shared components
import Header from '../shared/Header';
import StatCard from '../shared/StatCard';
import WeatherCard from '../shared/WeatherCard';

// Hooks and utilities
import { usePlayer } from '../../context/PlayerContext';
import { formatInnings } from '../../utils/formatters';

// Icon constants for consistent usage throughout the component
const ICONS = {
  DASHBOARD: Activity,
  TRENDS: TrendingUp,
  BATTING: Clipboard,
  PITCHING: TrendingUp,
  GAMES: Calendar,
  PROFILE: User,
  STATS: BarChart3,
  QUICK_ENTRY: PlusCircle,
  REFRESH: RefreshCw,
  EXPAND: ChevronDown,
  COLLAPSE: ChevronUp,
  ACHIEVEMENTS: Award,
  SETTINGS: Settings,
  ALERT: AlertTriangle
};

// Layout constants
const SPACING = {
  SECTION: 'mb-6',
  CARD: 'mb-4',
  GRID: 'gap-3',
  TITLE: 'mb-2',
  CONTENT: 'mb-3',
};

// Animation constants
const TRANSITIONS = {
  DEFAULT: 'transition-all duration-300 ease-in-out',
  FAST: 'transition-all duration-150 ease-in-out',
  SLOW: 'transition-all duration-500 ease-in-out',
  SECTION: 'transition-all duration-300 ease-in-out will-change-auto',
};

// Error boundary component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Dashboard component error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="p-4 border border-red-300 bg-red-50 text-red-700 rounded-lg">
          <h3 className="font-bold mb-2">Something went wrong</h3>
          <p>{this.state.error?.message || "An error occurred in this component"}</p>
          <button 
            className="mt-2 px-3 py-1 bg-red-600 text-white rounded-md" 
            onClick={() => this.setState({ hasError: false, error: null })}
          >
            Try again
          </button>
        </div>
      );
    }
    return this.props.children;
  }
}

// Custom hook for pull-to-refresh functionality
const usePullToRefresh = (onRefresh) => {
  useEffect(() => {
    let touchStart = 0;
    let touchEnd = 0;
    let isMounted = true; // Flag to prevent memory leaks
    const threshold = 150; // Minimum distance to trigger refresh
    const maxScroll = 5; // Maximum scroll position to allow pull

    const handleTouchStart = (e) => {
      touchStart = e.targetTouches[0].clientY;
    };

    const handleTouchMove = (e) => {
      touchEnd = e.targetTouches[0].clientY;
    };

    const handleTouchEnd = () => {
      if (isMounted && window.scrollY <= maxScroll && touchStart && (touchEnd - touchStart > threshold)) {
        onRefresh();
      }
      touchStart = 0;
      touchEnd = 0;
    };
    
    document.addEventListener('touchstart', handleTouchStart, false);
    document.addEventListener('touchmove', handleTouchMove, false);
    document.addEventListener('touchend', handleTouchEnd, false);

    return () => {
      isMounted = false; // Prevent memory leaks
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [onRefresh]);
};
// Memoized subcomponents for performance
const MemoizedPerformanceTrends = memo(PerformanceTrends);
const MemoizedRecentGames = memo(RecentGames);
const MemoizedMilestonesCard = memo(MilestonesCard);
const MemoizedSlumpDetection = memo(SlumpDetection);

// Section component for collapsible sections
const Section = memo(({ title, children, defaultExpanded = true, icon: Icon }) => {
  const [expanded, setExpanded] = useState(defaultExpanded);
  
  return (
    <div className={SPACING.SECTION}>
      <div 
        className="flex justify-between items-center mb-3 cursor-pointer"
        onClick={() => setExpanded(!expanded)}
      >
        <div className="flex items-center">
          {Icon && <Icon size={20} className="mr-2 text-blue-600" />}
          <h3 className="text-lg font-medium">{title}</h3>
        </div>
        <button 
          className="text-gray-500 p-1 rounded-full hover:bg-gray-100 active:bg-gray-200"
          aria-label={expanded ? "Collapse section" : "Expand section"}
        >
          {expanded ? <ICONS.COLLAPSE size={20} /> : <ICONS.EXPAND size={20} />}
        </button>
      </div>
      
      <div 
        className={`${TRANSITIONS.SECTION} overflow-hidden ${expanded ? 'max-h-[5000px] opacity-100' : 'max-h-0 opacity-0'}`}
        style={{
          transform: expanded ? 'translateY(0)' : 'translateY(-10px)',
          visibility: expanded ? 'visible' : 'hidden',
          transitionDelay: expanded ? '0ms' : '150ms'
        }}
      >
        {children}
      </div>
    </div>
  );
});

// PropTypes for Section component
Section.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  defaultExpanded: PropTypes.bool,
  icon: PropTypes.elementType
};

// The Section component has been moved above to fix the PropTypes reference
const Dashboard = () => {
  const { playerData, showQuickEntry, setShowQuickEntry, isLoading, fetchPlayerData } = usePlayer();
  const [refreshing, setRefreshing] = useState(false);
  
  // Handle refresh action
  const handleRefresh = useCallback(() => {
    setRefreshing(true);
    // Simulate a network request with timeout
    setTimeout(() => {
      fetchPlayerData();
      setRefreshing(false);
    }, 800);
  }, [fetchPlayerData]);
  
  // Setup pull-to-refresh
  usePullToRefresh(handleRefresh);
  
  // Find the next upcoming game from player's schedule
  const findNextUpcomingGame = () => {
    // Create arrays even if playerData properties are undefined
    const recentGames = playerData?.recentGames || [];
    const scheduledGames = playerData?.scheduledGames || [];
    
    // If both arrays are empty, return null
    if (recentGames.length === 0 && scheduledGames.length === 0) {
      return null;
    }
    
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set to beginning of day for date comparison
    
    // Create a combined array of all games
    const allGames = [...recentGames, ...scheduledGames];
    
    // Filter upcoming games (where date is in the future)
    // and sort by date (closest first)
    const upcomingGames = allGames
      .filter(game => {
        if (!game.date) return false;
        
        const gameDate = new Date(game.date);
        return gameDate >= today && (game.status === 'upcoming' || !game.status);
      })
      .sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateA - dateB;
      });
    
    return upcomingGames.length > 0 ? upcomingGames[0] : null;
  };
  
  const nextGame = findNextUpcomingGame();
  
  // Calculate trends (in a real app, these would come from actual data analysis)
  const calculateTrend = (current, previous) => {
    if (!previous) return null;
    return Math.round(((current - previous) / previous) * 100);
  };
  
  // Stats cards data
  const battingCards = [
    { 
      id: 'avg',
      title: 'AVG', 
      value: playerData?.battingStats?.average?.toFixed(3).replace(/^0+/, '') || '.000',
      icon: ICONS.STATS,
      trend: calculateTrend(playerData?.battingStats?.average, playerData?.battingStats?.previousAverage),
      trendLabel: "vs last 10 games"
    },
    { 
      id: 'obp',
      title: 'OBP', 
      value: playerData?.battingStats?.obp?.toFixed(3).replace(/^0+/, '') || '.000',
      icon: ICONS.STATS,
      trend: calculateTrend(playerData?.battingStats?.obp, playerData?.battingStats?.previousObp),
      trendLabel: "vs last 10 games"
    },
    { 
      id: 'slg',
      title: 'SLG', 
      value: playerData?.battingStats?.slg?.toFixed(3).replace(/^0+/, '') || '.000',
      icon: ICONS.STATS,
      trend: calculateTrend(playerData?.battingStats?.slg, playerData?.battingStats?.previousSlg),
      trendLabel: "vs last 10 games"
    },
    { 
      id: 'ops',
      title: 'OPS', 
      value: playerData?.battingStats?.ops?.toFixed(3).replace(/^0+/, '') || '.000',
      icon: ICONS.STATS,
      trend: calculateTrend(playerData?.battingStats?.ops, playerData?.battingStats?.previousOps),
      trendLabel: "vs last 10 games"
    }
  ];
  
  const pitchingCards = [
    { 
      id: 'era',
      title: 'ERA', 
      value: playerData?.pitchingStats?.era?.toFixed(2) || '0.00',
      icon: ICONS.STATS,
      trend: calculateTrend(playerData?.pitchingStats?.era, playerData?.pitchingStats?.previousEra),
      // For ERA, a negative trend is good
      trendDirection: 'inverted'
    },
    { 
      id: 'whip',
      title: 'WHIP', 
      value: playerData?.pitchingStats?.whip?.toFixed(2) || '0.00',
      icon: ICONS.STATS,
      trend: calculateTrend(playerData?.pitchingStats?.whip, playerData?.pitchingStats?.previousWhip),
      // For WHIP, a negative trend is good
      trendDirection: 'inverted'
    },
    { 
      id: 'wl',
      title: 'W-L', 
      value: `${playerData?.pitchingStats?.wins || 0}-${playerData?.pitchingStats?.losses || 0}`,
      icon: ICONS.STATS
    },
    { 
      id: 'ip',
      title: 'IP', 
      value: formatInnings(playerData?.pitchingStats?.inningsPitched) || '0.0',
      icon: ICONS.STATS
    }
  ];
  return (
    <>
      {/* Header with refresh button */}
      <Header 
        title="Dashboard" 
        subtitle="Track your baseball performance" 
        actions={[
          { 
            icon: ICONS.REFRESH, 
            onClick: handleRefresh, 
            label: 'Refresh data',
            className: refreshing ? 'animate-spin' : ''
          },
          { 
            icon: ICONS.SETTINGS, 
            onClick: () => {/* Handle settings */}, 
            label: 'Settings' 
          }
        ]}
      />
      
      <main className="p-4 pb-20">
        {/* Pull to refresh indicator */}
        {refreshing && (
          <div className="text-center text-sm text-gray-500 -mt-2 mb-4 flex items-center justify-center">
            <ICONS.REFRESH size={14} className="animate-spin mr-2" /> Refreshing...
          </div>
        )}
      
        {/* Quick Entry Modal */}
        {showQuickEntry && (
          <div className="mb-4">
            <ErrorBoundary>
              <QuickEntry onClose={() => setShowQuickEntry(false)} />
            </ErrorBoundary>
          </div>
        )}
        
        {/* Quick Entry Button - Fixed Action Button */}
        <button 
          onClick={() => setShowQuickEntry(true)}
          className="fixed right-4 bottom-20 z-10 bg-blue-600 text-white p-3 rounded-full shadow-lg hover:bg-blue-700 active:bg-blue-800 transition-all transform hover:scale-105 active:scale-95"
          aria-label="Quick Entry"
        >
          <ICONS.QUICK_ENTRY size={24} />
        </button>
        <Section title="Player Summary" icon={ICONS.PROFILE} defaultExpanded={true}>
          <ErrorBoundary>
            <StatCard
              loading={isLoading}
              className="mb-4"
              bgColor="bg-gradient-to-r from-blue-500 to-blue-600"
              gradient="text-white"
            >
              <div className="flex justify-between items-center mb-4">
                <div>
                  <h3 className="font-bold text-lg">{playerData?.name || 'Player Name'}</h3>
                  <p className="text-gray-100">Position: {playerData?.position || 'Unknown'}</p>
                  <p className="text-gray-100">Leagues: {playerData?.leagues?.join(', ') || 'None'}</p>
                </div>
                <div className="rounded-full h-16 w-16 flex items-center justify-center bg-white/20 text-white text-2xl font-bold">
                  {playerData?.number || '#'}
                </div>
              </div>
            </StatCard>
            
            {/* Batting Stats Grid */}
            <h4 className="text-md font-medium mb-2 flex items-center">
              <ICONS.BATTING size={16} className="mr-1" /> Batting Statistics
            </h4>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-3 mb-4">
              {battingCards.map((card) => (
                <StatCard
                  key={`batting-${card.id}`}
                  title={card.title}
                  value={card.value}
                  icon={card.icon}
                  size="compact"
                  loading={isLoading}
                  trend={card.trend}
                  trendLabel={card.trendLabel}
                />
              ))}
            </div>
            
            {/* Pitching Stats Grid */}
            <h4 className="text-md font-medium mb-2 flex items-center">
              <ICONS.PITCHING size={16} className="mr-1" /> Pitching Statistics
            </h4>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-3">
              {pitchingCards.map((card) => (
                <StatCard
                  key={`pitching-${card.id}`}
                  title={card.title}
                  value={card.value}
                  icon={card.icon}
                  size="compact"
                  loading={isLoading}
                  trend={card.trend}
                  trendLabel={card.trendLabel}
                  trendDirection={card.trendDirection}
                />
              ))}
            </div>
          </ErrorBoundary>
        </Section>
        
        {/* Upcoming Game with Weather */}
        <Section title="Upcoming Game" icon={ICONS.GAMES} defaultExpanded={true}>
          <ErrorBoundary>
            <StatCard className="mb-0">
              {nextGame ? (
                <>
                  <div className="flex justify-between mb-2">
                    <div className="font-bold">vs {nextGame.opponent}</div>
                    <div className="text-xs bg-green-100 text-green-800 px-2 py-1 rounded">
                      {nextGame.isHome ? 'HOME' : 'AWAY'}
                    </div>
                  </div>
                  <div className="text-sm text-gray-700 mb-3">
                    {new Date(nextGame.date).toLocaleDateString('en-US', { 
                      weekday: 'long', 
                      month: 'short', 
                      day: 'numeric' 
                    })} • {nextGame.time} • {nextGame.location}
                  </div>
                  
                  <WeatherCard 
                    location={nextGame.location}
                    date={nextGame.date}
                    gameTime={nextGame.time}
                    loading={isLoading}
                  />
                </>
              ) : (
                <div className="text-center py-6">
                  <div className="text-gray-400 mb-2">
                    <Calendar size={24} className="mx-auto mb-2" />
                    No upcoming games scheduled
                  </div>
                  <p className="text-sm text-gray-500">
                    Add games in the Schedule tab to see them here
                  </p>
                </div>
              )}
            </StatCard>
          </ErrorBoundary>
        </Section>
        {/* Milestones */}
        <Section title="Milestones & Achievements" icon={ICONS.ACHIEVEMENTS} defaultExpanded={true}>
          <ErrorBoundary>
            <MemoizedMilestonesCard loading={isLoading} />
          </ErrorBoundary>
        </Section>
        
        {/* Performance Trends */}
        <Section title="Performance Trends" icon={ICONS.TRENDS} defaultExpanded={true}>
          <ErrorBoundary>
            <MemoizedPerformanceTrends 
              loading={isLoading} 
              playerData={playerData} 
            />
          </ErrorBoundary>
        </Section>
        
        {/* Performance Analysis */}
        <Section title="Performance Analysis" icon={ICONS.ALERT} defaultExpanded={true}>
          <ErrorBoundary>
            <MemoizedSlumpDetection 
              playerData={playerData}
              loading={isLoading}
            />
          </ErrorBoundary>
        </Section>
        
        {/* Recent Games */}
        <Section title="Recent Games" icon={ICONS.GAMES} defaultExpanded={true}>
          <ErrorBoundary>
            <MemoizedRecentGames 
              loading={isLoading} 
              recentGames={playerData?.recentGames || []} 
            />
          </ErrorBoundary>
        </Section>
      </main>
    </>
  );
};
// PropTypes for Dashboard component
Dashboard.propTypes = {
  playerData: PropTypes.object,
  isLoading: PropTypes.bool,
  showQuickEntry: PropTypes.bool,
  setShowQuickEntry: PropTypes.func,
  fetchPlayerData: PropTypes.func
};

export default Dashboard;
