import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Calendar, Clock, MapPin, Plus, ChevronDown, ChevronUp } from 'lucide-react';
import { usePlayer } from '../../context/PlayerContext';
import GameScheduleItem from './GameScheduleItem';
import AddGameScheduleModal from './AddGameScheduleModal';
import MatchupAnalysis from './MatchupAnalysis';
import PreGamePrep from './PreGamePrep';
import StatCard from '../shared/StatCard';
import WeatherCard from '../shared/WeatherCard';
import Header from '../shared/Header';
const SchedulePage = () => {
  const { playerData, addScheduledGame, removeScheduledGame, updateScheduledGameData, fetchScheduledGames, isLoading, error } = usePlayer();
  const [showAddModal, setShowAddModal] = useState(false);
  const [filter, setFilter] = useState('upcoming'); // 'upcoming', 'past', 'all'
  const [showMatchupAnalysis, setShowMatchupAnalysis] = useState(false);
  const [showPreGamePrep, setShowPreGamePrep] = useState(false);
  const [feedback, setFeedback] = useState({ message: '', type: '' });
  
  // Helper function to get UTC date string (yyyy-mm-dd) to avoid timezone issues
  const getUTCDateString = (date) => {
    return `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1).toString().padStart(2, '0')}-${date.getUTCDate().toString().padStart(2, '0')}`;
  };
  
  // Add debug logs
  console.log('Player Data:', playerData);
  console.log('Scheduled Games:', playerData.scheduledGames);
  
  // Handle adding a new scheduled game
  // Effect to automatically update game status based on current date
  useEffect(() => {
    const updateGameStatuses = async () => {
      console.log('Running updateGameStatuses, scheduledGames:', playerData.scheduledGames);
      if (!playerData.scheduledGames || playerData.scheduledGames.length === 0) return;
      
      // Get current date in UTC
      const today = new Date();
      
      const todayUTC = getUTCDateString(today);
      const todayUTCMidnight = new Date(`${todayUTC}T00:00:00.000Z`);
      
      console.log(`Current date (UTC): ${todayUTCMidnight.toISOString()}`);
      
      // Find games that need to be updated from upcoming to past
      const gamesNeedingUpdate = playerData.scheduledGames.filter(game => {
        const gameDate = new Date(game.date);
        const gameDateUTC = getUTCDateString(gameDate);
        const gameDateUTCMidnight = new Date(`${gameDateUTC}T00:00:00.000Z`);
        
        const isPastGame = gameDateUTCMidnight < todayUTCMidnight;
        const needsUpdate = isPastGame && game.status === 'upcoming';
        
        console.log(`Game vs ${game.opponent} on ${game.date}: `, {
          gameUTC: gameDateUTCMidnight.toISOString(),
          isPastGame,
          currentStatus: game.status,
          needsStatusUpdate: needsUpdate
        });
        
        return needsUpdate;
      });
      
      console.log(`Found ${gamesNeedingUpdate.length} games that need status update to 'past'`);
      
      // Update each game that has passed
      for (const game of gamesNeedingUpdate) {
        try {
          console.log(`Updating game vs ${game.opponent} on ${game.date} from 'upcoming' to 'past'`);
          await updateScheduledGameData(game.id, { ...game, status: 'past' });
        } catch (err) {
          console.error('Error updating game status:', err);
        }
      }
      
      if (gamesNeedingUpdate.length > 0) {
        // Refresh the games list after updates
        fetchScheduledGames();
      }
    };
    
    updateGameStatuses();
  }, [playerData.scheduledGames, updateScheduledGameData, fetchScheduledGames]);

  // Fetch scheduled games when component mounts
  useEffect(() => {
    // Fetch scheduled games when component mounts
    fetchScheduledGames();
  }, [fetchScheduledGames]); // Add proper dependency
  const handleAddGame = async (newGame) => {
    try {
      // Show loading feedback
      setFeedback({ message: 'Adding game...', type: 'loading' });
      
      // Determine if game is in the past or upcoming based on date using UTC
      const gameDate = new Date(newGame.date);
      const today = new Date();
      
      const gameDateUTC = getUTCDateString(gameDate);
      const todayUTC = getUTCDateString(today);
      
      // Create UTC dates with time set to midnight
      const gameDateUTCMidnight = new Date(`${gameDateUTC}T00:00:00.000Z`);
      const todayUTCMidnight = new Date(`${todayUTC}T00:00:00.000Z`);
      
      // Set game status based on UTC date comparison
      const gameStatus = gameDateUTCMidnight < todayUTCMidnight ? 'past' : 'upcoming';
      
      console.log('Adding new game:', {
        ...newGame,
        status: gameStatus,
        date: newGame.date,
        isInPast: gameDateUTCMidnight < todayUTCMidnight,
        gameDateUTC,
        todayUTC
      });
      
      // Save the game with the correct status
      await addScheduledGame({
        ...newGame,
        status: gameStatus,
        date: newGame.date // ensure date is in correct format
      });
      
      setShowAddModal(false);
      setFeedback({ message: 'Game added successfully!', type: 'success' });
      
      // Clear success message after 3 seconds
      setTimeout(() => setFeedback({ message: '', type: '' }), 3000);
    } catch (err) {
      console.error('Error adding game:', err);
      setFeedback({ message: 'Failed to add game. Please try again.', type: 'error' });
    }
  };
  
  // Handle deleting a scheduled game
  const handleDeleteGame = async (gameId) => {
    try {
      setFeedback({ message: 'Deleting game...', type: 'loading' });
      
      await removeScheduledGame(gameId);
      
      setFeedback({ message: 'Game deleted successfully!', type: 'success' });
      
      // Clear success message after 3 seconds
      setTimeout(() => setFeedback({ message: '', type: '' }), 3000);
    } catch (err) {
      console.error('Error deleting game:', err);
      setFeedback({ message: 'Failed to delete game. Please try again.', type: 'error' });
    }
  };
  // Check if a game is in the past or upcoming
  const determineGameStatus = useCallback((game) => {
    // Always respect the explicit status if it's already defined
    // This ensures we don't recalculate status for games with known state
    if (game.status) {
      console.log(`Game using explicit status: ${game.status} for game vs ${game.opponent} on ${game.date}`);
      return game.status;
    }
    
    try {
      // Parse the game date with detailed logging
      console.log(`Original game date string: "${game.date}" (type: ${typeof game.date})`);
      
      // Create fresh date objects
      const gameDate = new Date(game.date);
      const today = new Date();
      
      const gameDateUTC = getUTCDateString(gameDate);
      const todayUTC = getUTCDateString(today);
      
      // Create UTC dates with time set to midnight
      const gameDateUTCMidnight = new Date(`${gameDateUTC}T00:00:00.000Z`);
      const todayUTCMidnight = new Date(`${todayUTC}T00:00:00.000Z`);
      
      // Get timestamp values for accurate UTC comparison
      const gameDateTimestamp = gameDateUTCMidnight.getTime();
      const todayTimestamp = todayUTCMidnight.getTime();
      
      // Determine if the game is in the past
      const isGameInPast = gameDateTimestamp < todayTimestamp;
      
      // Detailed logging including UTC conversions
      // Detailed logging including UTC conversions
      console.log('Game date comparison (UTC normalized):', { 
        originalGameDate: game.date,
        gameDateObj: gameDate.toString(),
        todayObj: today.toString(),
        gameDateUTC,
        todayUTC,
        gameDateUTCMidnight: gameDateUTCMidnight.toISOString(),
        todayUTCMidnight: todayUTCMidnight.toISOString(),
        gameDateTimestamp,
        todayTimestamp,
        isGameInPast,
        calculatedStatus: isGameInPast ? 'past' : 'upcoming'
      });
      // Calculate new status
      const newStatus = isGameInPast ? 'past' : 'upcoming';
      
      // Log status calculation result
      console.log(`Game "${game.opponent}" on ${game.date} status determined as: ${newStatus}`);
      
      // Return status based on comparison
      return newStatus;
    } catch (error) {
      console.error('Error determining game status for date: ', game.date, error);
      // Default to upcoming if there's an error
      return 'upcoming';
    }
  }, []); // getUTCDateString is stable so it's not needed as a dependency
  const filteredGames = useMemo(() => {
    if (!playerData.scheduledGames) return [];
    
    return playerData.scheduledGames.filter(game => {
      const status = determineGameStatus(game);
      console.log('Game status:', { game, status });
      if (filter === 'all') return true;
      return status === filter;
    });
  }, [playerData.scheduledGames, filter, determineGameStatus]);
  
  console.log('Filtered Games:', filteredGames);
  
  // Find the next upcoming game
  const nextGame = playerData.scheduledGames ? playerData.scheduledGames.find(game => {
    const status = determineGameStatus(game);
    return status === 'upcoming';
  }) : null;
  
  // Add loading state handling
  if (isLoading && !playerData.scheduledGames) {
    return (
      <div>
        <Header
          title="Game Schedule"
          subtitle="Track your upcoming games"
        />
        <div className="p-4">
          <div className="text-center py-6 text-gray-500">Loading games...</div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Header
        title="Game Schedule"
        subtitle="Track your upcoming games"
        actions={[
          {
            icon: Plus,
            onClick: () => setShowAddModal(true),
            label: 'Add Game'
          }
        ]}
      />
      
      <div className="p-4">
        <div className="mb-4">
        <div className="flex space-x-2 overflow-x-auto pb-2">
          <button 
            className={`${filter === 'upcoming' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700'} px-3 py-1 rounded-full text-sm whitespace-nowrap`}
            onClick={() => setFilter('upcoming')}
          >
            Upcoming
          </button>
          <button 
            className={`${filter === 'past' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700'} px-3 py-1 rounded-full text-sm whitespace-nowrap`}
            onClick={() => setFilter('past')}
          >
            Past
          </button>
          <button 
            className={`${filter === 'all' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700'} px-3 py-1 rounded-full text-sm whitespace-nowrap`}
            onClick={() => setFilter('all')}
          >
            All Games
          </button>
        </div>
      </div>
      
      {/* Next Game Summary (only shown if upcoming games exist) */}
      {filter !== 'past' && nextGame && (
        <div className="mb-6">
          <div className="flex justify-between items-center mb-2">
            <h3 className="text-lg font-medium">Next Game</h3>
            <div className="flex space-x-2">
              <button 
                onClick={() => setShowPreGamePrep(!showPreGamePrep)}
                className="text-sm text-blue-600 flex items-center"
              >
                {showPreGamePrep ? 'Hide Prep' : 'Game Prep'}
                {showPreGamePrep ? <ChevronUp size={16} className="ml-1" /> : <ChevronDown size={16} className="ml-1" />}
              </button>
              <button 
                onClick={() => setShowMatchupAnalysis(!showMatchupAnalysis)}
                className="text-sm text-blue-600 flex items-center"
              >
                {showMatchupAnalysis ? 'Hide Analysis' : 'Analysis'}
                {showMatchupAnalysis ? <ChevronUp size={16} className="ml-1" /> : <ChevronDown size={16} className="ml-1" />}
              </button>
            </div>
          </div>
          
          <StatCard>
            <div className="flex justify-between mb-2">
              <div className="font-bold text-lg">vs {nextGame.opponent}</div>
              <div className={`px-2 py-1 rounded text-xs ${nextGame.isHome ? 'bg-green-100 text-green-800' : 'bg-blue-100 text-blue-800'}`}>
                {nextGame.isHome ? 'HOME' : 'AWAY'}
              </div>
            </div>
            
            <div className="space-y-2 mb-3">
              <div className="flex items-center text-gray-600">
                <Calendar size={16} className="mr-2" />
                <span>
                  {new Date(nextGame.date).toLocaleDateString('en-US', { weekday: 'long', month: 'short', day: 'numeric' })}
                </span>
              </div>
              <div className="flex items-center text-gray-600">
                <Clock size={16} className="mr-2" />
                <span>{nextGame.time}</span>
              </div>
              <div className="flex items-center text-gray-600">
                <MapPin size={16} className="mr-2" />
                <span>{nextGame.location}</span>
              </div>
            </div>
            
            {/* Weather Preview */}
            <WeatherCard 
              location={nextGame.location}
              date={nextGame.date}
              gameTime={nextGame.time}
            />
            
            {/* Action buttons for the next game */}
            <div className="flex justify-between mt-4 pt-2 border-t border-gray-100">
              <button className="text-blue-600 text-sm">Add to Calendar</button>
              <button className="text-blue-600 text-sm">Get Directions</button>
            </div>
          </StatCard>
          
          {/* Pre-Game Preparation */}
          {showPreGamePrep && (
            <PreGamePrep 
              gameDate={nextGame.date}
              opponent={nextGame.opponent}
              gameTime={nextGame.time}
            />
          )}
          
          {/* Matchup Analysis */}
          {showMatchupAnalysis && (
            <MatchupAnalysis 
              opponent={nextGame.opponent} 
              gameDate={nextGame.date}
            />
          )}
        </div>
      )}
      
      {/* Schedule List */}
      <div className="space-y-3">
        {filteredGames.length > 0 ? (
          filteredGames.map(game => (
            <GameScheduleItem 
              key={game.id} 
              game={game} 
              onDelete={handleDeleteGame}
            />
          ))
        ) : (
          <div className="text-center py-6 text-gray-500">
            No {filter} games scheduled
          </div>
        )}
      </div>
      
      {showAddModal && <AddGameScheduleModal onClose={() => setShowAddModal(false)} onAdd={handleAddGame} leagues={playerData.leagues || []} />}
      
      {/* Loading and Error Messages */}
      {isLoading && !feedback.message && (
        <div className="fixed bottom-4 right-4 bg-blue-100 text-blue-800 p-3 rounded shadow">
          <div className="flex items-center">
            <svg className="animate-spin -ml-1 mr-2 h-5 w-5 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Loading...
          </div>
        </div>
      )}
      
      {feedback.message && (
        <div className={`fixed bottom-4 right-4 p-3 rounded shadow ${
          feedback.type === 'error' ? 'bg-red-100 text-red-800' : 
          feedback.type === 'success' ? 'bg-green-100 text-green-800' : 
          'bg-blue-100 text-blue-800'}`}>
          {feedback.type === 'loading' && (
            <div className="flex items-center">
              <svg className="animate-spin -ml-1 mr-2 h-5 w-5 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </div>
          )}
          {feedback.message}
        </div>
      )}
      
      {error && (
        <div className="fixed bottom-4 right-4 bg-red-100 text-red-800 p-3 rounded shadow">
          {error}
        </div>
      )}
      </div>
    </div>
  );
};

export default SchedulePage;
