import React, { useState } from 'react';
import { Award, Share2, X, Target, PenTool, CheckCircle, Clock, Search } from 'lucide-react';
import StatCard from '../shared/StatCard';
import { usePlayer } from '../../context/PlayerContext';

const MilestonesCard = () => {
  const { playerData } = usePlayer();
  const [showShareModal, setShowShareModal] = useState(false);
  const [showAllModal, setShowAllModal] = useState(false);
  const [selectedMilestone, setSelectedMilestone] = useState(null);
  const [filterStatus, setFilterStatus] = useState('all'); // 'all', 'achieved', 'in-progress'
  const [filterType, setFilterType] = useState('all'); // 'all', 'batting', 'pitching'
  const [filterCategory, setFilterCategory] = useState('all'); // 'all', 'game', 'season', 'career', 'special'
  const [sortBy, setSortBy] = useState('date'); // 'date', 'progress', 'alphabetical'
  const [searchQuery, setSearchQuery] = useState('');
  
  // Calculate milestones based on player data
  const calculateMilestones = () => {
    const milestones = [];
    const currentDate = new Date();
    const formattedDate = `${currentDate.toLocaleString('default', { month: 'short' })} ${currentDate.getDate()}, ${currentDate.getFullYear()}`;
    
    // ----- BATTING MILESTONES -----
    
    // Calculate singles (hits - (doubles + triples + home runs))
    const singles = playerData.battingStats.hits - 
                   (playerData.battingStats.doubles + 
                    playerData.battingStats.triples + 
                    playerData.battingStats.homeRuns);
    
    // Calculate batting average if at bats > 0
    const battingAvg = playerData.battingStats.atBats > 0 
                      ? playerData.battingStats.hits / playerData.battingStats.atBats 
                      : 0;
    
    // Calculate on-base percentage
    const onBasePercentage = playerData.battingStats.atBats > 0 
                          ? (playerData.battingStats.hits + playerData.battingStats.walks) / 
                            (playerData.battingStats.atBats + playerData.battingStats.walks) 
                          : 0;
    
    // Calculate slugging percentage
    const sluggingPercentage = playerData.battingStats.atBats > 0 
                            ? (singles + (2 * playerData.battingStats.doubles) + 
                               (3 * playerData.battingStats.triples) + 
                               (4 * playerData.battingStats.homeRuns)) / 
                               playerData.battingStats.atBats 
                            : 0;
    
    // Calculate OPS (On-base Plus Slugging)
    const ops = onBasePercentage + sluggingPercentage;
    
    // Completed batting milestones
    // Game milestones
    if (playerData.battingStats.hits >= 30) {
      milestones.push({
        title: '30+ Hits',
        description: `You've reached ${playerData.battingStats.hits} total hits!`,
        date: 'Apr 2, 2025',
        type: 'batting',
        category: 'game',
        achieved: true,
      });
    }
    
    if (playerData.battingStats.rbi >= 20) {
      milestones.push({
        title: '20+ RBI',
        description: `You've driven in ${playerData.battingStats.rbi} runs!`,
        date: 'Mar 30, 2025',
        type: 'batting',
        category: 'game',
        achieved: true,
      });
    }
    
    if (singles >= 20) {
      milestones.push({
        title: '20+ Singles',
        description: `You've hit ${singles} singles!`,
        date: 'Apr 7, 2025',
        type: 'batting',
        category: 'game',
        achieved: true,
      });
    }
    
    if (playerData.battingStats.doubles >= 10) {
      milestones.push({
        title: '10+ Doubles',
        description: `You've hit ${playerData.battingStats.doubles} doubles!`,
        date: 'Apr 5, 2025',
        type: 'batting',
        category: 'game',
        achieved: true,
      });
    }
    
    if (playerData.battingStats.walks >= 15) {
      milestones.push({
        title: '15+ Walks',
        description: `Great plate discipline with ${playerData.battingStats.walks} walks!`,
        date: 'Apr 8, 2025',
        type: 'batting',
        category: 'game',
        achieved: true,
      });
    }
    
    if (battingAvg >= 0.300 && playerData.battingStats.atBats >= 30) {
      milestones.push({
        title: '.300+ Batting Avg',
        description: `Batting ${(battingAvg).toFixed(3)} with ${playerData.battingStats.atBats}+ at bats!`,
        date: 'Apr 10, 2025',
        type: 'batting',
        category: 'season',
        achieved: true,
      });
    }
    
    // Add some intermediate milestones for batting
    if (playerData.battingStats.hits >= 40 && playerData.battingStats.hits < 50) {
      milestones.push({
        title: '40+ Hits',
        description: `You've reached ${playerData.battingStats.hits} total hits!`,
        date: formattedDate,
        type: 'batting',
        category: 'game',
        achieved: true,
      });
    }
    
    if (playerData.battingStats.rbi >= 35 && playerData.battingStats.rbi < 50) {
      milestones.push({
        title: '35+ RBI',
        description: `You've driven in ${playerData.battingStats.rbi} runs!`,
        date: formattedDate,
        type: 'batting',
        category: 'game',
        achieved: true,
      });
    }
    
    if (playerData.battingStats.doubles >= 15 && playerData.battingStats.doubles < 20) {
      milestones.push({
        title: '15+ Doubles',
        description: `You've hit ${playerData.battingStats.doubles} doubles!`,
        date: formattedDate,
        type: 'batting',
        category: 'game',
        achieved: true,
      });
    }
    
    // Home run milestones
    if (playerData.battingStats.homeRuns >= 5) {
      milestones.push({
        title: '5+ Home Runs',
        description: `You've hit ${playerData.battingStats.homeRuns} home runs!`,
        date: formattedDate,
        type: 'batting',
        category: 'game',
        achieved: true,
      });
    }
    
    // Triple milestones
    if (playerData.battingStats.triples >= 3) {
      milestones.push({
        title: '3+ Triples',
        description: `You've hit ${playerData.battingStats.triples} triples!`,
        date: formattedDate,
        type: 'batting',
        category: 'game',
        achieved: true,
      });
    }
    
    // Combined stat achievements
    if (ops >= 0.850 && playerData.battingStats.atBats >= 30) {
      milestones.push({
        title: '.850+ OPS',
        description: `Your OPS (On-base Plus Slugging) is ${(ops).toFixed(3)}!`,
        date: formattedDate,
        type: 'batting',
        category: 'season',
        achieved: true,
      });
    }
    
    // Power hitter achievement
    if (sluggingPercentage >= 0.500 && playerData.battingStats.atBats >= 30) {
      milestones.push({
        title: '.500+ Slugging',
        description: `Impressive .${(sluggingPercentage * 1000).toFixed(0)} slugging percentage!`,
        date: formattedDate,
        type: 'batting',
        category: 'season',
        achieved: true,
      });
    }
    
    // Special achievements
    if (playerData.battingStats.hits > 0 && 
        playerData.battingStats.doubles > 0 && 
        playerData.battingStats.triples > 0 && 
        playerData.battingStats.homeRuns > 0) {
      milestones.push({
        title: 'Hit Cycle Builder',
        description: 'You have hit singles, doubles, triples, and home runs this season!',
        date: formattedDate,
        type: 'batting',
        category: 'special',
        achieved: true,
      });
    }
    
    // Power Hitter special achievement
    if (playerData.battingStats.homeRuns >= 8) {
      milestones.push({
        title: 'Power Hitter',
        description: `Impressive power with ${playerData.battingStats.homeRuns} home runs!`,
        date: formattedDate,
        type: 'batting',
        category: 'special',
        achieved: true,
      });
    }
    
    // Contact Master special achievement
    if (battingAvg >= 0.350 && playerData.battingStats.atBats >= 40) {
      milestones.push({
        title: 'Contact Master',
        description: `Elite batting average of ${(battingAvg).toFixed(3)} with ${playerData.battingStats.atBats}+ at bats!`,
        date: formattedDate,
        type: 'batting',
        category: 'special',
        achieved: true,
      });
    }
    
    // On-base Machine achievement
    if (onBasePercentage >= 0.400 && playerData.battingStats.atBats >= 30) {
      milestones.push({
        title: 'On-base Machine',
        description: `Outstanding ${(onBasePercentage).toFixed(3)} on-base percentage!`,
        date: formattedDate,
        type: 'batting',
        category: 'special',
        achieved: true,
      });
    }
    
    // Upcoming batting milestones
    const hitsToNextMilestone = 50 - playerData.battingStats.hits;
    if (hitsToNextMilestone > 0) {
      milestones.push({
        title: '50 Hit Club',
        description: `Need ${hitsToNextMilestone} more hits to reach 50`,
        type: 'batting',
        category: 'game',
        achieved: false,
        progress: (playerData.battingStats.hits / 50) * 100
      });
    }
    
    const rbiToNextMilestone = 50 - playerData.battingStats.rbi;
    if (rbiToNextMilestone > 0) {
      milestones.push({
        title: '50 RBI Club',
        description: `Need ${rbiToNextMilestone} more RBIs to reach 50`,
        type: 'batting',
        category: 'game',
        achieved: false,
        progress: (playerData.battingStats.rbi / 50) * 100
      });
    }
    
    const doublesToNextMilestone = 20 - playerData.battingStats.doubles;
    if (doublesToNextMilestone > 0) {
      milestones.push({
        title: '20 Doubles Club',
        description: `Need ${doublesToNextMilestone} more doubles to reach 20`,
        type: 'batting',
        category: 'game',
        achieved: false,
        progress: (playerData.battingStats.doubles / 20) * 100
      });
    }
    
    const walksToNextMilestone = 25 - playerData.battingStats.walks;
    if (walksToNextMilestone > 0) {
      milestones.push({
        title: '25 Walks Club',
        description: `Need ${walksToNextMilestone} more walks to reach 25`,
        type: 'batting',
        category: 'game',
        achieved: false,
        progress: (playerData.battingStats.walks / 25) * 100
      });
    }
    
    // Batting average milestone (need 50+ at bats)
    if (playerData.battingStats.atBats >= 30) {
      if (battingAvg < 0.300) {
        const pointsNeeded = ((0.300 * playerData.battingStats.atBats) - playerData.battingStats.hits);
        const hitsNeeded = Math.ceil(pointsNeeded);
        milestones.push({
          title: '.300 Batting Average',
          description: `Need ${hitsNeeded} more hits to reach .300 average`,
          type: 'batting',
          category: 'season',
          achieved: false,
          progress: (battingAvg / 0.300) * 100
        });
      }
    } else {
      milestones.push({
        title: '.300 Batting Average',
        description: 'Need 30+ at bats to qualify',
        type: 'batting',
        category: 'season',
        achieved: false,
        progress: (playerData.battingStats.atBats / 30) * 100
      });
    }
    
    // ----- PITCHING MILESTONES -----
    
    // Completed pitching milestones
    if (playerData.pitchingStats.strikeouts >= 25) {
      milestones.push({
        title: '25+ Strikeouts',
        description: `You've struck out ${playerData.pitchingStats.strikeouts} batters!`,
        date: 'Apr 5, 2025',
        type: 'pitching',
        category: 'game',
        achieved: true,
      });
    }
    
    if (playerData.pitchingStats.inningsPitched >= 15) {
      milestones.push({
        title: '15+ Innings Pitched',
        description: `You've pitched ${playerData.pitchingStats.inningsPitched} innings!`,
        date: 'Apr 8, 2025',
        type: 'pitching',
        category: 'game',
        achieved: true,
      });
    }
    
    if (playerData.pitchingStats.games >= 5) {
      milestones.push({
        title: '5+ Games Pitched',
        description: `You've pitched in ${playerData.pitchingStats.games} games!`,
        date: 'Apr 9, 2025',
        type: 'pitching',
        category: 'game',
        achieved: true,
      });
    }
    
    // ERA milestone (lower is better, need enough innings to qualify)
    if (playerData.pitchingStats.inningsPitched >= 10 && playerData.pitchingStats.era <= 3.5) {
      milestones.push({
        title: 'Sub-3.50 ERA',
        description: `Outstanding ${playerData.pitchingStats.era.toFixed(2)} ERA in ${playerData.pitchingStats.inningsPitched}+ innings!`,
        date: 'Apr 10, 2025',
        type: 'pitching',
        category: 'season',
        achieved: true,
      });
    }
    
    // Strikeout Artist special achievement
    if (playerData.pitchingStats.strikeouts >= 30) {
      milestones.push({
        title: 'Strikeout Artist',
        description: `Dominant pitching with ${playerData.pitchingStats.strikeouts} strikeouts!`,
        date: formattedDate,
        type: 'pitching',
        category: 'special',
        achieved: true,
      });
    }
    
    // Perfect Inning special achievement
    // This is a hypothetical milestone - in a real app, you would track this based on game events
    if (playerData.pitchingStats.perfectInnings && playerData.pitchingStats.perfectInnings > 0) {
      milestones.push({
        title: 'Perfect Inning',
        description: 'Struck out all three batters in a single inning!',
        date: formattedDate,
        type: 'pitching',
        category: 'special',
        achieved: true,
      });
    }
    
    // Workhorse special achievement
    if (playerData.pitchingStats.completedGames && playerData.pitchingStats.completedGames > 0) {
      milestones.push({
        title: 'Workhorse',
        description: `Completed ${playerData.pitchingStats.completedGames} full games!`,
        date: formattedDate,
        type: 'pitching',
        category: 'special',
        achieved: true,
      });
    }
    // Upcoming pitching milestones
    const strikeoutsToNextMilestone = 50 - playerData.pitchingStats.strikeouts;
    if (strikeoutsToNextMilestone > 0) {
      milestones.push({
        title: '50 Strikeout Club',
        description: `Need ${strikeoutsToNextMilestone} more strikeouts to reach 50`,
        type: 'pitching',
        category: 'game',
        achieved: false,
        progress: (playerData.pitchingStats.strikeouts / 50) * 100
      });
    }
    const inningsPitchedToNextMilestone = 25 - playerData.pitchingStats.inningsPitched;
    if (inningsPitchedToNextMilestone > 0) {
      milestones.push({
        title: '25 Innings Pitched',
        description: `Need ${inningsPitchedToNextMilestone.toFixed(1)} more innings to reach 25`,
        type: 'pitching',
        category: 'game',
        achieved: false,
        progress: (playerData.pitchingStats.inningsPitched / 25) * 100
      });
    }
    
    const gamesPitchedToNextMilestone = 10 - playerData.pitchingStats.games;
    if (gamesPitchedToNextMilestone > 0) {
      milestones.push({
        title: '10 Games Pitched',
        description: `Need ${gamesPitchedToNextMilestone} more games to reach 10`,
        type: 'pitching',
        category: 'game',
        achieved: false,
        progress: (playerData.pitchingStats.games / 10) * 100
      });
    }
    
    // ERA milestone (if innings qualification met)
    if (playerData.pitchingStats.inningsPitched >= 10) {
      if (playerData.pitchingStats.era > 3.5) {
        // ERA is an inverted metric (lower is better)
        // We'll use a 6.00 ERA as the "worst" baseline for calculation
        const eraProgress = Math.max(0, (6 - playerData.pitchingStats.era) / (6 - 3.5)) * 100;
        milestones.push({
          title: 'Sub-3.50 ERA',
          description: `Current ERA: ${playerData.pitchingStats.era.toFixed(2)}. Need to improve by ${(playerData.pitchingStats.era - 3.5).toFixed(2)} to reach target.`,
          type: 'pitching',
          category: 'season',
          achieved: false,
          progress: eraProgress
        });
      }
    }
    
    return milestones;
  };
  const handleShare = (milestone) => {
    setSelectedMilestone(milestone);
    setShowShareModal(true);
  };
  
  // Calculate milestones
  const milestones = calculateMilestones();
  
  // Helper function to filter and sort milestones
  function getFilteredMilestones() {
    let filtered = milestones;
    
    // Filter by status
    if (filterStatus === 'achieved') {
      filtered = filtered.filter(m => m.achieved);
    } else if (filterStatus === 'in-progress') {
      filtered = filtered.filter(m => !m.achieved);
    }
    
    // Filter by type
    if (filterType !== 'all') {
      filtered = filtered.filter(m => m.type === filterType);
    }
    
    // Filter by category
    if (filterCategory !== 'all') {
      filtered = filtered.filter(m => m.category === filterCategory);
    }
    
    // Filter by search
    if (searchQuery.trim()) {
      const query = searchQuery.toLowerCase();
      filtered = filtered.filter(m => 
        m.title.toLowerCase().includes(query) || 
        m.description.toLowerCase().includes(query)
      );
    }
    
    // Sort
    if (sortBy === 'progress') {
      filtered = [...filtered].sort((a, b) => {
        // Sort by progress percentage (achieved always at 100%)
        const progressA = a.achieved ? 100 : (a.progress || 0);
        const progressB = b.achieved ? 100 : (b.progress || 0);
        
        // Sort descending (highest progress first)
        return progressB - progressA;
      });
    } else if (sortBy === 'date') {
      filtered = [...filtered].sort((a, b) => {
        // Put achieved milestones first
        if (a.achieved && !b.achieved) return -1;
        if (!a.achieved && b.achieved) return 1;
        
        // If both achieved, sort by date (newest first)
        if (a.achieved && b.achieved) {
          // Safety check in case date is missing
          if (!a.date) return 1;
          if (!b.date) return -1;
          
          // Convert dates to timestamps and compare
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateB - dateA;
        }
        
        // If both in progress, sort by progress
        const progressA = a.progress || 0;
        const progressB = b.progress || 0;
        return progressB - progressA;
      });
    } else if (sortBy === 'alphabetical') {
      filtered = [...filtered].sort((a, b) => {
        // Sort by title alphabetically
        const titleA = (a.title || '').toLowerCase();
        const titleB = (b.title || '').toLowerCase();
        return titleA.localeCompare(titleB);
      });
    }
    
    return filtered;
  }
  
  return (
    <>
      <StatCard className="mb-6">
        <div className="flex justify-between items-center mb-3">
          <h3 className="font-medium flex items-center">
            <Award size={18} className="text-yellow-500 mr-2" />
            Milestones & Achievements
          </h3>
          <button 
            onClick={() => setShowAllModal(true)} 
            className="text-blue-600 dark:text-darkText-link text-sm hover:underline"
          >
            View All
          </button>
        </div>
        
        <div className="space-y-4">
          {milestones.filter(m => m.achieved).slice(0, 2).map((milestone, index) => (
            <div key={index} className="bg-blue-50 p-3 rounded-lg">
              <div className="flex justify-between items-start">
                <div>
                  <div className="font-bold text-blue-800">{milestone.title}</div>
                  <div className="text-sm text-blue-600">{milestone.description}</div>
                  <div className="flex items-center gap-2 mt-1">
                    <div className="text-xs text-gray-500">Achieved on {milestone.date}</div>
                    <span className="text-xs px-1.5 py-0.5 rounded bg-gray-100 text-gray-600 capitalize">{milestone.category}</span>
                  </div>
                </div>
                <button 
                  onClick={() => handleShare(milestone)}
                  className="text-blue-600 p-1 hover:bg-blue-100 rounded"
                >
                  <Share2 size={16} />
                </button>
              </div>
            </div>
          ))}
          
          {milestones.filter(m => !m.achieved).slice(0, 2).map((milestone, index) => (
            <div key={index} className="border border-gray-200 p-3 rounded-lg">
              <div className="flex justify-between mb-2">
                <div className="font-bold flex items-center gap-1">
                  {milestone.title}
                  <span className="text-[10px] px-1 py-0.5 rounded bg-gray-100 text-gray-600 capitalize">{milestone.category}</span>
                </div>
                <div className="text-xs bg-yellow-100 text-yellow-800 px-2 py-0.5 rounded">In Progress</div>
              </div>
              <div className="text-sm text-gray-600 mb-2">{milestone.description}</div>
              <div className="w-full bg-gray-200 rounded-full h-2">
                <div 
                  className="bg-blue-500 h-2 rounded-full" 
                  style={{ width: `${milestone.progress}%` }}
                ></div>
              </div>
            </div>
          ))}
        </div>
      </StatCard>
      
      {/* Share Milestone Modal */}
      {showShareModal && selectedMilestone && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-10">
          <div className="bg-white rounded-lg p-4 w-11/12 max-w-md">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-bold">Share Achievement</h3>
              <button onClick={() => setShowShareModal(false)} className="text-gray-500">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            
            <div className="bg-gray-100 p-5 rounded-lg mb-4">
              <div className="flex justify-center mb-3">
                <div className="h-16 w-16 rounded-full bg-blue-500 flex items-center justify-center">
                  <Award size={32} className="text-white" />
                </div>
              </div>
              <div className="flex items-center justify-center gap-2 mb-1">
                <h4 className="text-center font-bold text-lg">{selectedMilestone.title}</h4>
                <span className="text-xs px-2 py-0.5 rounded bg-blue-100 text-blue-800 capitalize">{selectedMilestone.category}</span>
              </div>
              <p className="text-center text-gray-700">{selectedMilestone.description}</p>
              <p className="text-center text-sm text-gray-500 mt-2">Achieved by {playerData.name}</p>
            </div>
            
            <div className="flex flex-col mb-4">
              <p className="text-sm text-gray-600 mb-2">Share your achievement with:</p>
              <div className="grid grid-cols-4 gap-3">
                <button className="flex flex-col items-center">
                  <div className="h-10 w-10 rounded-full bg-blue-600 flex items-center justify-center mb-1">
                    <span className="text-white text-lg font-bold">f</span>
                  </div>
                  <span className="text-xs">Facebook</span>
                </button>
                <button className="flex flex-col items-center">
                  <div className="h-10 w-10 rounded-full bg-sky-500 flex items-center justify-center mb-1">
                    <span className="text-white text-lg font-bold">X</span>
                  </div>
                  <span className="text-xs">Twitter</span>
                </button>
                <button className="flex flex-col items-center">
                  <div className="h-10 w-10 rounded-full bg-green-500 flex items-center justify-center mb-1">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                    </svg>
                  </div>
                  <span className="text-xs">WhatsApp</span>
                </button>
                <button className="flex flex-col items-center">
                  <div className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center mb-1">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                    </svg>
                  </div>
                  <span className="text-xs">More</span>
                </button>
              </div>
            </div>
            
            <div className="flex justify-end pt-2">
              <button 
                onClick={() => setShowShareModal(false)}
                className="bg-blue-600 text-white py-2 px-4 rounded"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      )}
      
      {/* View All Milestones Modal */}
      {/* View All Milestones Modal */}
      {showAllModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-start justify-center z-10 overflow-y-auto">
          <div className="min-h-screen w-full py-8 px-4">
            <div className="bg-white dark:bg-dark-200 rounded-lg w-full max-w-3xl mx-auto flex flex-col shadow-xl">
            <div className="flex justify-between items-center p-4 border-b border-gray-200 dark:border-dark-400">
              <h3 className="text-xl font-bold dark:text-darkText-primary flex items-center">
                <Award size={20} className="text-yellow-500 mr-2" />
                Milestones & Achievements
              </h3>
              <button 
                onClick={() => setShowAllModal(false)} 
                className="text-gray-500 dark:text-darkText-secondary hover:bg-gray-100 dark:hover:bg-dark-300 p-2 rounded-full"
              >
                <X size={20} />
              </button>
            </div>
            
            {/* Search and Filters */}
            <div className="p-4 bg-gray-50 dark:bg-dark-300 border-b border-gray-200 dark:border-dark-400">
              <div className="flex flex-col md:flex-row gap-3">
                {/* Search */}
                <div className="relative flex-grow">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Search size={16} className="text-gray-400 dark:text-darkText-tertiary" />
                  </div>
                  <input
                    type="text"
                    placeholder="Search milestones..."
                    className="pl-10 w-full p-2 border border-gray-300 dark:border-dark-400 rounded-md text-sm dark:bg-dark-400 dark:text-darkText-primary"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
                
                {/* Filters */}
                <div className="flex gap-2">
                  <select 
                    value={filterStatus}
                    onChange={(e) => setFilterStatus(e.target.value)}
                    className="p-2 border border-gray-300 dark:border-dark-400 rounded-md text-sm bg-white dark:bg-dark-400 dark:text-darkText-primary"
                  >
                    <option value="all">All Status</option>
                    <option value="achieved">Achieved</option>
                    <option value="in-progress">In Progress</option>
                  </select>
                  
                  <select 
                    value={filterType}
                    onChange={(e) => setFilterType(e.target.value)}
                    className="p-2 border border-gray-300 dark:border-dark-400 rounded-md text-sm bg-white dark:bg-dark-400 dark:text-darkText-primary"
                  >
                    <option value="all">All Types</option>
                    <option value="batting">Batting</option>
                    <option value="pitching">Pitching</option>
                  </select>
                  
                  <select 
                    value={filterCategory}
                    onChange={(e) => setFilterCategory(e.target.value)}
                    className="p-2 border border-gray-300 dark:border-dark-400 rounded-md text-sm bg-white dark:bg-dark-400 dark:text-darkText-primary"
                  >
                    <option value="all">All Categories</option>
                    <option value="game">Game</option>
                    <option value="season">Season</option>
                    <option value="career">Career</option>
                    <option value="special">Special</option>
                  </select>
                  
                  <select 
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                    className="p-2 border border-gray-300 dark:border-dark-400 rounded-md text-sm bg-white dark:bg-dark-400 dark:text-darkText-primary"
                  >
                    <option value="date">Sort by Date</option>
                    <option value="progress">Sort by Progress</option>
                    <option value="alphabetical">Sort Alphabetically</option>
                  </select>
                </div>
              </div>
            </div>
            
            {/* Modal Content - Milestone List */}
            <div className="flex-1 overflow-y-auto">
              <div className="p-4 space-y-4">
                {/* Achieved Milestones Section */}
                {getFilteredMilestones().filter(m => m.achieved).length > 0 && (
                  <div>
                    <h4 className="font-semibold text-lg mb-3 dark:text-darkText-primary flex items-center">
                      <CheckCircle size={18} className="mr-2 text-green-500" />
                      Achieved
                    </h4>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      {getFilteredMilestones().filter(m => m.achieved).map((milestone, index) => (
                        <div key={index} className="bg-blue-50 dark:bg-blue-900/20 p-4 rounded-lg border border-blue-100 dark:border-blue-800/30">
                          <div className="flex justify-between items-start">
                            <div className="flex-grow">
                              <div className="font-bold text-blue-800 dark:text-blue-300 flex items-center">
                                {milestone.type === 'batting' ? 
                                  <Target size={16} className="mr-2" /> : 
                                  <PenTool size={16} className="mr-2" />
                                }
                                {milestone.title}
                              </div>
                              <div className="text-sm text-blue-600 dark:text-blue-400">{milestone.description}</div>
                              <div className="flex items-center gap-2 mt-1">
                                <div className="text-xs text-gray-500 dark:text-darkText-tertiary">Achieved on {milestone.date}</div>
                                <span className="text-xs px-1.5 py-0.5 rounded bg-blue-50 dark:bg-blue-900/30 text-blue-700 dark:text-blue-400 capitalize">{milestone.category}</span>
                              </div>
                            </div>
                            <button 
                              onClick={() => handleShare(milestone)}
                              className="text-blue-600 dark:text-blue-400 p-1 hover:bg-blue-100 dark:hover:bg-blue-800/30 rounded"
                            >
                              <Share2 size={16} />
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                
                {/* In Progress Milestones Section */}
                {getFilteredMilestones().filter(m => !m.achieved).length > 0 && (
                  <div className="mt-6">
                    <h4 className="font-semibold text-lg mb-3 dark:text-darkText-primary flex items-center">
                      <Clock size={18} className="mr-2 text-yellow-500" />
                      In Progress
                    </h4>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      {getFilteredMilestones().filter(m => !m.achieved).map((milestone, index) => (
                        <div key={index} className="border border-gray-200 dark:border-dark-400 p-4 rounded-lg dark:bg-dark-300">
                          <div className="flex justify-between mb-2">
                            <div className="font-bold dark:text-darkText-primary flex items-center">
                              {milestone.type === 'batting' ? 
                                <Target size={16} className="mr-2 text-blue-500" /> : 
                                <PenTool size={16} className="mr-2 text-purple-500" />
                              }
                              {milestone.title}
                              <span className="ml-1 text-xs px-1.5 py-0.5 rounded bg-gray-100 dark:bg-dark-400 text-gray-600 dark:text-gray-300 capitalize">{milestone.category}</span>
                            </div>
                            <div className="text-xs bg-yellow-100 dark:bg-yellow-900/20 text-yellow-800 dark:text-yellow-300 px-2 py-0.5 rounded">
                              In Progress
                            </div>
                          </div>
                          <div className="text-sm text-gray-600 dark:text-darkText-secondary mb-2">{milestone.description}</div>
                          <div className="w-full bg-gray-200 dark:bg-dark-400 rounded-full h-2 overflow-hidden">
                            <div 
                              className="bg-blue-500 dark:bg-blue-600 h-2 rounded-full" 
                              style={{ width: `${milestone.progress}%` }}
                            ></div>
                          </div>
                          <div className="text-xs text-right mt-1 text-gray-500 dark:text-darkText-tertiary">
                            {milestone.progress.toFixed(0)}% complete
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                
                {/* No Results */}
                {getFilteredMilestones().length === 0 && (
                  <div className="text-center py-8">
                    <div className="text-gray-400 dark:text-darkText-tertiary">No milestones found with the current filters</div>
                    <button 
                      onClick={() => {
                        setFilterStatus('all');
                        setFilterType('all');
                        setFilterCategory('all');
                        setSearchQuery('');
                      }}
                      className="mt-3 bg-blue-50 hover:bg-blue-100 text-blue-600 dark:bg-blue-900/20 dark:hover:bg-blue-900/30 dark:text-blue-400 px-4 py-2 rounded-md text-sm font-medium"
                    >
                      Clear Filters
                    </button>
                  </div>
                )}
              </div>
            </div>
            
            {/* Modal Footer */}
            <div className="border-t border-gray-200 dark:border-dark-400 p-4 flex justify-end">
              <button
                onClick={() => setShowAllModal(false)}
                className="bg-blue-600 dark:bg-blue-700 hover:bg-blue-700 dark:hover:bg-blue-600 text-white py-2 px-4 rounded transition-colors"
              >
                Close
              </button>
            </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MilestonesCard;
