// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Function to validate Firebase config
const validateFirebaseConfig = (config) => {
  const requiredFields = [
    'apiKey', 
    'authDomain', 
    'projectId', 
    'storageBucket', 
    'messagingSenderId', 
    'appId'
  ];
  
  const missingFields = requiredFields.filter(field => 
    !config[field] || config[field].includes('your_') || config[field] === 'undefined'
  );
  
  if (missingFields.length > 0) {
    throw new Error(
      `Firebase configuration error: Missing or invalid ${missingFields.join(', ')}. ` +
      `Check your .env file and make sure you've added the correct Firebase configuration values.`
    );
  }
  
  return true;
};

// Firebase configuration from environment variables
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

let app, db, auth;

try {
  // Validate config before initializing
  validateFirebaseConfig(firebaseConfig);
  
  // Initialize Firebase
  app = initializeApp(firebaseConfig);
  
  // Initialize Firestore service
  db = getFirestore(app);
  
  // Initialize Firebase Authentication
  auth = getAuth(app);
  
  console.log('Firebase successfully initialized');
} catch (error) {
  console.error('Firebase initialization error:', error.message);
  
  // In development, show a more visible error about Firebase config
  if (process.env.NODE_ENV === 'development') {
    console.error(`
      --------------------------------------------------------
      🔥 Firebase Error: ${error.message}
      
      Make sure to:
      1. Create a .env file in the project root if it doesn't exist
      2. Add your Firebase config values to the .env file
      3. Restart your development server
      --------------------------------------------------------
    `);
  }
}

export { db, auth };

