import React, { createContext, useState, useContext, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { 
  getPlayers, 
  getGames, 
  createGame, 
  updatePlayer,
  getPlayer,
  getScheduledGames,
  createScheduledGame,
  updateScheduledGame,
  deleteScheduledGame,
  deleteGame
} from '../firebase/firestore';

// Create the context
const PlayerContext = createContext();

// Default empty player data structure
const emptyPlayerData = {
  name: "",
  number: "",
  position: "",
  leagues: [],
  recentGames: [],
  scheduledGames: [],
  battingStats: {
    average: 0,
    obp: 0,
    slg: 0,
    ops: 0,
    atBats: 0,
    hits: 0,
    doubles: 0,
    triples: 0,
    homeRuns: 0,
    runs: 0,
    rbis: 0,
    walks: 0,
    strikeouts: 0,
    hitByPitch: 0,
    games: 0
  },
  pitchingStats: {
    era: 0,
    whip: 0,
    wins: 0,
    losses: 0,
    inningsPitched: 0,
    strikeouts: 0,
    walks: 0,
    hits: 0,
    earnedRuns: 0
  }
};

// Provider component
export const PlayerProvider = ({ children }) => {
  // Get user from auth context
  const { currentUser } = useAuth();
  
  // State
  const [playerData, setPlayerData] = useState(emptyPlayerData);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedPlayerId, setSelectedPlayerId] = useState(null);
  const [allPlayers, setAllPlayers] = useState([]);
  const [showQuickEntry, setShowQuickEntry] = useState(false);
  
  // Fetch all players for the user
  const fetchAllPlayers = async () => {
    if (!currentUser) {
      setAllPlayers([]);
      return;
    }
    
    try {
      setIsLoading(true);
      const players = await getPlayers(currentUser.uid);
      setAllPlayers(players);
      
      // Select the first player if none is selected and players exist
      if (players.length > 0 && !selectedPlayerId) {
        setSelectedPlayerId(players[0].id);
      }
    } catch (err) {
      console.error('Error fetching players:', err);
      setError('Failed to load players. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  // Calculate player statistics from recent games
  const calculateStats = (games) => {
    if (!games || games.length === 0) return;

    // Initialize stats
    const battingStats = {
      games: games.length,
      atBats: 0,
      hits: 0,
      doubles: 0,
      triples: 0,
      homeRuns: 0,
      runs: 0,
      rbi: 0,
      walks: 0,
      strikeouts: 0,
      hitByPitch: 0,
      average: 0,
      obp: 0,
      slg: 0,
      ops: 0
    };

    // Sum up stats from all games
    games.forEach(game => {
      if (game.battingStats) {
        battingStats.atBats += game.battingStats.atBats || 0;
        battingStats.hits += game.battingStats.hits || 0;
        battingStats.doubles += game.battingStats.doubles || 0;
        battingStats.triples += game.battingStats.triples || 0;
        battingStats.homeRuns += game.battingStats.homeRuns || 0;
        battingStats.runs += game.battingStats.runs || 0;
        battingStats.rbi += game.battingStats.rbi || 0;
        battingStats.walks += game.battingStats.walks || 0;
        battingStats.strikeouts += game.battingStats.strikeouts || 0;
        battingStats.hitByPitch += game.battingStats.hitByPitch || 0;
      }
    });

    // Calculate singles
    const singles = battingStats.hits - battingStats.doubles - battingStats.triples - battingStats.homeRuns;

    // Calculate averages
    if (battingStats.atBats > 0) {
      battingStats.average = battingStats.hits / battingStats.atBats;

      // Calculate OBP: (H + BB + HBP) / (AB + BB + HBP)
      const obpDenominator = battingStats.atBats + battingStats.walks + battingStats.hitByPitch;
      if (obpDenominator > 0) {
        battingStats.obp = (battingStats.hits + battingStats.walks + battingStats.hitByPitch) / obpDenominator;
      }

      // Calculate SLG: (1B + 2*2B + 3*3B + 4*HR) / AB
      battingStats.slg = (singles + 2 * battingStats.doubles + 3 * battingStats.triples + 4 * battingStats.homeRuns) / battingStats.atBats;
    }

    // Calculate OPS (On-base Plus Slugging)
    battingStats.ops = battingStats.obp + battingStats.slg;

    // Update player data with calculated stats
    setPlayerData(prevData => ({
      ...prevData,
      battingStats
    }));
  };
  
  // Fetch player data for the selected player
  const fetchPlayerData = async (playerId = selectedPlayerId) => {
    if (!currentUser || !playerId) {
      return;
    }
    
    try {
      setIsLoading(true);
      setError(null);
      
      // Fetch player data, recent games, and scheduled games in parallel
      const [player, games, scheduledGames] = await Promise.all([
        getPlayer(currentUser.uid, playerId),
        getGames(currentUser.uid, playerId),
        getScheduledGames(currentUser.uid, playerId)
      ]);
      
      // Combine all the data
      const fullPlayerData = {
        ...player,
        recentGames: games || [],
        scheduledGames: scheduledGames || []
      };
      
      setPlayerData(fullPlayerData);
      
      // Calculate and update stats based on game data
      if (games && games.length > 0) {
        calculateStats(games);
      }
    } catch (err) {
      console.error('Error fetching player data:', err);
      setError('Failed to load player data. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };
  
  // Handle player selection
  const selectPlayer = (playerId) => {
    setSelectedPlayerId(playerId);
  };
  
  // Function to add a new game
  const addGame = async (gameData) => {
    if (!currentUser || !selectedPlayerId) {
      setError('No player selected');
      return;
    }
    
    try {
      setIsLoading(true);
      setError(null);
      
      // Create the game in Firestore
      await createGame(currentUser.uid, selectedPlayerId, gameData);
      
      // Refresh player data to include the new game
      await fetchPlayerData();
      
      // After data is refreshed, no need to call calculateStats here as it's called in fetchPlayerData
      
    } catch (err) {
      console.error('Error adding game:', err);
      setError('Failed to add game. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };
  
  // Function to delete an existing game
  const removeGame = async (gameId) => {
    if (!currentUser || !selectedPlayerId) {
      setError('No player selected');
      return;
    }
    
    try {
      setIsLoading(true);
      setError(null);
      
      // Delete the game in Firestore
      await deleteGame(currentUser.uid, selectedPlayerId, gameId);
      
      // Refresh player data to update stats and game list
      await fetchPlayerData();
      
    } catch (err) {
      console.error('Error deleting game:', err);
      setError('Failed to delete game. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };
  
  // Function to update player profile
  const updateProfile = async (profileData) => {
    if (!currentUser || !selectedPlayerId) {
      setError('No player selected');
      return;
    }
    
    try {
      setIsLoading(true);
      setError(null);
      
      // Update the player in Firestore
      await updatePlayer(currentUser.uid, selectedPlayerId, profileData);
      
      // Refresh player data
      await fetchPlayerData();
      
    } catch (err) {
      console.error('Error updating profile:', err);
      setError('Failed to update profile. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };
  
  // Scheduled Games Management Functions
  
  // Fetch scheduled games for the selected player
  const fetchScheduledGames = async (playerId = selectedPlayerId) => {
    if (!currentUser || !playerId) {
      return;
    }
    
    try {
      setIsLoading(true);
      setError(null);
      
      // Get scheduled games for the player
      const games = await getScheduledGames(currentUser.uid, playerId);
      
      // Update player data with scheduled games
      setPlayerData(prevData => ({
        ...prevData,
        scheduledGames: games || []
      }));
      
    } catch (err) {
      console.error('Error fetching scheduled games:', err);
      setError('Failed to load scheduled games. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };
  
  // Add a scheduled game
  const addScheduledGame = async (gameData) => {
    if (!currentUser || !selectedPlayerId) {
      setError('No player selected');
      return;
    }
    
    try {
      setIsLoading(true);
      setError(null);
      
      // Create the scheduled game in Firestore
      await createScheduledGame(currentUser.uid, selectedPlayerId, gameData);
      
      // Refresh scheduled games
      await fetchScheduledGames();
      
    } catch (err) {
      console.error('Error adding scheduled game:', err);
      setError('Failed to add scheduled game. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };
  
  // Delete a scheduled game
  const removeScheduledGame = async (gameId) => {
    if (!currentUser || !selectedPlayerId) {
      setError('No player selected');
      return;
    }
    
    try {
      setIsLoading(true);
      setError(null);
      
      // Delete the scheduled game from Firestore
      await deleteScheduledGame(currentUser.uid, selectedPlayerId, gameId);
      
      // Refresh scheduled games
      await fetchScheduledGames();
      
    } catch (err) {
      console.error('Error deleting scheduled game:', err);
      setError('Failed to delete scheduled game. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };
  
  // Update a scheduled game
  const updateScheduledGameData = async (gameId, gameData) => {
    if (!currentUser || !selectedPlayerId) {
      setError('No player selected');
      return;
    }
    
    try {
      setIsLoading(true);
      setError(null);
      
      // Update the scheduled game in Firestore
      await updateScheduledGame(currentUser.uid, selectedPlayerId, gameId, gameData);
      
      // Refresh scheduled games
      await fetchScheduledGames();
      
    } catch (err) {
      console.error('Error updating scheduled game:', err);
      setError('Failed to update scheduled game. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };
  
  // Reset player state (useful for logout)
  const resetPlayerState = () => {
    setPlayerData(emptyPlayerData);
    setIsLoading(false);
    setError(null);
    setSelectedPlayerId(null);
    setAllPlayers([]);
    setShowQuickEntry(false);
  };
  
  // Effect to fetch players when the user changes
  useEffect(() => {
    if (currentUser) {
      fetchAllPlayers();
    } else {
      resetPlayerState();
    }
  }, [currentUser]);
  
  // Effect to fetch player data when the selected player changes
  useEffect(() => {
    if (selectedPlayerId) {
      fetchPlayerData();
      // fetchScheduledGames() is now included in fetchPlayerData
    }
  }, [selectedPlayerId]);
  
  // Context value
  const value = {
    playerData,
    allPlayers,
    selectedPlayerId,
    isLoading,
    error,
    showQuickEntry,
    setShowQuickEntry,
    addGame,
    removeGame,
    updateProfile,
    fetchPlayerData,
    fetchAllPlayers,
    selectPlayer,
    resetPlayerState,
    // Scheduled games functions
    addScheduledGame,
    removeScheduledGame,
    updateScheduledGameData,
    fetchScheduledGames
  };
  
  return (
    <PlayerContext.Provider value={value}>
      {children}
    </PlayerContext.Provider>
  );
};

// Custom hook to use the player context
export const usePlayer = () => {
  const context = useContext(PlayerContext);
  if (!context) {
    throw new Error('usePlayer must be used within a PlayerProvider');
  }
  return context;
};
