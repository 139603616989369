import React, { useState } from 'react';
import { Calendar, Clock, MapPin, Sun, Cloud, CloudRain, Trash2 } from 'lucide-react';

const GameScheduleItem = ({ game, onDelete }) => {
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const formattedDate = new Date(game.date).toLocaleDateString('en-US', { 
    weekday: 'short', 
    month: 'short', 
    day: 'numeric' 
  });
  
  // This would be determined by a real weather API
  const getWeatherIcon = () => {
    // Mock implementation - in reality, this would use data from a weather API
    const weatherTypes = [Sun, Cloud, CloudRain];
    const randomIndex = Math.floor(Math.random() * weatherTypes.length);
    const WeatherIcon = weatherTypes[randomIndex];
    return <WeatherIcon size={16} className="text-gray-500 dark:text-darkText-tertiary" />;
  };
  
  return (
    <div className="bg-white dark:bg-dark-200 border border-gray-200 dark:border-dark-300 rounded-lg p-3 shadow-sm dark:shadow-dark-100/20">
      {/* Header */}
      <div className="flex justify-between mb-2">
        <div className="font-semibold dark:text-darkText-primary">vs {game.opponent}</div>
        <div className={`px-2 py-0.5 rounded text-xs ${
          game.isHome 
            ? 'bg-green-100 text-green-800 dark:bg-green-900/20 dark:text-green-300' 
            : 'bg-blue-100 text-blue-800 dark:bg-blue-900/20 dark:text-blue-300'
        }`}>
          {game.isHome ? 'HOME' : 'AWAY'}
        </div>
      </div>
      
      {/* Game details */}
      <div className="flex flex-col space-y-1 text-sm">
        {/* Date, time, and weather */}
        <div className="flex items-center">
          <Calendar size={16} className="mr-2 text-gray-500 dark:text-darkText-tertiary" />
          <span className="dark:text-darkText-secondary">{formattedDate}</span>
          <div className="mx-2 dark:text-darkText-tertiary">•</div>
          <Clock size={16} className="mr-1 text-gray-500 dark:text-darkText-tertiary" />
          <span className="dark:text-darkText-secondary">{game.time}</span>
          <div className="mx-2 dark:text-darkText-tertiary">•</div>
          {getWeatherIcon()}
        </div>
        
        {/* Location */}
        <div className="flex items-center">
          <MapPin size={16} className="mr-2 text-gray-500 dark:text-darkText-tertiary" />
          <span className="text-gray-700 dark:text-darkText-secondary">{game.location}</span>
        </div>
        
        {/* League info */}
        {game.league && (
          <div className="text-gray-600 dark:text-darkText-tertiary text-xs mt-1">
            League: <span className="dark:text-darkText-secondary">{game.league}</span>
          </div>
        )}
        
        {/* Notes */}
        {game.notes && (
          <div className="mt-2 p-2 bg-yellow-50 dark:bg-yellow-900/20 text-yellow-800 dark:text-yellow-300 text-xs rounded border border-yellow-100 dark:border-yellow-900/30">
            {game.notes}
          </div>
        )}
        
        {/* Confirmation dialog */}
        {showConfirmDelete && (
          <div className="mt-2 p-2 bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-800/30 rounded">
            <p className="text-red-600 dark:text-red-300 text-xs font-medium mb-2">Are you sure you want to delete this game?</p>
            <div className="flex justify-end space-x-2">
              <button 
                onClick={() => setShowConfirmDelete(false)}
                className="text-xs px-2 py-1 bg-gray-200 dark:bg-dark-400 rounded text-gray-700 dark:text-darkText-primary hover:bg-gray-300 dark:hover:bg-dark-300 transition-colors"
              >
                Cancel
              </button>
              <button 
                onClick={() => {
                  onDelete(game.id);
                  setShowConfirmDelete(false);
                }}
                className="text-xs px-2 py-1 bg-red-500 dark:bg-red-700 hover:bg-red-600 dark:hover:bg-red-600 rounded text-white transition-colors"
              >
                Delete
              </button>
            </div>
          </div>
        )}

        {/* Action buttons */}
        <div className="flex justify-end mt-2 pt-2 border-t border-gray-100 dark:border-dark-300">
          {game.status === 'upcoming' && (
            <>
              <button className="text-blue-600 dark:text-darkText-link hover:text-blue-700 dark:hover:text-darkText-linkHover text-xs transition-colors">Add to Calendar</button>
              <div className="w-px h-3 bg-gray-300 dark:bg-dark-400 mx-2 self-center"></div>
              <button className="text-blue-600 dark:text-darkText-link hover:text-blue-700 dark:hover:text-darkText-linkHover text-xs transition-colors">Get Directions</button>
              <div className="w-px h-3 bg-gray-300 dark:bg-dark-400 mx-2 self-center"></div>
            </>
          )}
          <button 
            onClick={() => setShowConfirmDelete(true)}
            className="text-red-500 dark:text-darkText-danger hover:text-red-600 dark:hover:text-red-400 text-xs flex items-center transition-colors"
          >
            <Trash2 size={12} className="mr-1" />
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default GameScheduleItem;
