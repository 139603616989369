import React, { useState } from 'react';
import BattingStats from './BattingStats';
import PitchingStats from './PitchingStats';
import FieldCharts from './FieldCharts';
import { Info } from 'lucide-react';
import Header from '../shared/Header';
import StatCard from '../shared/StatCard';

const StatsPage = () => {
  const [activeTab, setActiveTab] = useState('batting');

  const ComingSoonMessage = ({ feature }) => (
    <StatCard>
      <div className="p-6 text-center">
        <div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-blue-100 mb-4">
          <Info className="text-blue-500" size={24} />
        </div>
        <h3 className="text-lg font-medium text-gray-900 mb-2">
          {feature} Coming Soon
        </h3>
        <p className="text-gray-500">
          We're working on bringing you detailed {feature.toLowerCase()} in a future update.
        </p>
      </div>
    </StatCard>
  );

  const renderContent = () => {
    switch(activeTab) {
      case 'batting':
        return <BattingStats />;
      case 'pitching':
        return <PitchingStats />;
      case 'situational':
        return <ComingSoonMessage feature="Situational Stats" />;
      case 'advanced':
        return <ComingSoonMessage feature="Advanced Metrics" />;
      case 'fieldCharts':
        return <ComingSoonMessage feature="Field Charts" />;
      default:
        return <BattingStats />;
    }
  };

  return (
    <div>
      <Header
        title="Statistics"
        subtitle="Track and analyze your performance"
      />
      <div className="p-4">
        <div className="mb-4">
          <div className="flex space-x-2 overflow-x-auto pb-2">
            <button 
              className={`${activeTab === 'batting' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700'} px-3 py-1 rounded-full text-sm whitespace-nowrap`}
              onClick={() => setActiveTab('batting')}
            >
              Batting
            </button>
            <button 
              className={`${activeTab === 'pitching' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700'} px-3 py-1 rounded-full text-sm whitespace-nowrap`}
              onClick={() => setActiveTab('pitching')}
            >
              Pitching
            </button>
            <button 
              className={`${activeTab === 'situational' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700'} px-3 py-1 rounded-full text-sm whitespace-nowrap`}
              onClick={() => setActiveTab('situational')}
            >
              Situational
            </button>
            <button 
              className={`${activeTab === 'advanced' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700'} px-3 py-1 rounded-full text-sm whitespace-nowrap`}
              onClick={() => setActiveTab('advanced')}
            >
              Advanced
            </button>
            <button 
              className={`${activeTab === 'fieldCharts' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700'} px-3 py-1 rounded-full text-sm whitespace-nowrap`}
              onClick={() => setActiveTab('fieldCharts')}
            >
              Field Charts
            </button>
          </div>
        </div>
        {renderContent()}
      </div>
    </div>
  );
};

export default StatsPage;
